import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import Select from "react-select";
import Upload from "./upload.png";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../../services/config";

function SupportForm(props) {
  // Fields
  // const loginAccount=JSON.parse(localStorage.getItem("userDetails")).role[0]
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const params = useParams();
  console.log(params.id);
  const [loading, setloading] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState("");
  const [singleSupport, setSingleSupport] = useState({});
  const [email, setemail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [backendUrl, setBackendUrl] = useState("");
  const [campusId, setCampusId] = useState("");
  const [campusData, setCampusData] = useState(user.campuses || []);
  const dispatch = useDispatch();

  let authtoken = localStorage.getItem("userToken");
  useEffect(() => {
    if (params.id == "create") {
      // getAllCampus();
    } else {
      getAllSupports();
    }
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getAllSupports = async () => {
    setloading(true);
    await fetch(`${baseUrl}/supports`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const res = data.payload.find((item) => item.id == params.id);
        console.log(res);
        setSingleSupport(res);
        if (res) {
          setemail(res.email);
          setPhone(res.phone);
          setBackendUrl(res.file);
        }
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log("vehicle", err);
      });
  };

  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    if (params.id == "create") {
      if (campusId == "") {
        toast.error("Campus Is Required");
        return;
      }
    }
    if (email == "") {
      toast.error("Email Address Is Required");
      return;
    }
    if (phone == "") {
      toast.error("Contact Phone Is Required");
      return;
    }
    if (imagesPreview == "" && backendUrl == "") {
      toast.error("Please Select PDF");
      return;
    }
    setloading(true);

    const myForm = new FormData();

    myForm.set("email", email);
    myForm.set("phone", phone);
    if (params.id == "create") {
      myForm.set("campus_id", campusId);
    } else {
      myForm.set("campus_id", params.id);
    }

    if (imagesPreview) {
      myForm.append("file", imagesPreview);
    }

    fetch(`${baseUrl}/supports`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: myForm,
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          setloading(false);
          if (data.metadata) {
            if (data.metadata.success) {
              toast.success("Saved Successfully");
            } else {
              toast.error(data.metadata.message);
            }
          }
        })
      )
      .catch((err) => {
        setloading(false);

        toast.error("SomeThing Went Wronge Please Try Again");
        console.log(err);
      });
    // setTimeout(() => {
    //   setloading(false);
    //   setSubmitting(false);

    // }, 1000);
  };
  // UI Helpers

  return (
    <div
      style={{ marginTop: "10px", paddingTop: 0 }}
      className="card card-custom"
    >
      <Toaster />
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div
          className="card-title align-items-start flex-column"
          style={{ paddingTop: "10px" }}
        >
          <h3 className="card-label font-weight-bolder text-dark">Support</h3>
          {/* <span className="text-muted font-weight-bold font-size-sm mt-1">
            Update your personal informaiton
          </span> */}
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-primary mr-2"
            onClick={saveUser}
          >
            Save
          </button>
          {/* { loginAccount !='Campus'? */}

          <Link to="/support" className="btn btn-secondary">
            Cancel
          </Link>
          {/* :''} */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              {/* <h5 className="font-weight-bold mb-6">Customer Info</h5> */}
            </div>
          </div>

          {params.id == "create" ? (
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Select Campus
              </label>
              <div className="col-lg-9 col-xl-6">
                {/* <div className="input-group input-group-lg input-group-solid"> */}
                <select
                  style={{ height: 40 }}
                  className="form-select"
                  name="year_id"
                  aria-label="Default select example"
                  onChange={(e) => setCampusId(e.target.value)}
                >
                  <option selected value="">
                    {" "}
                    Select Campus
                  </option>
                  {campusData &&
                    campusData.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
                {/* </div> */}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Email Address
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                  type="email"
                  placeholder="Email"
                  className={`form-control form-control-lg form-control-solid`}
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Contact Phone
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-phone"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="+1(123)112-11-11"
                  className={`form-control form-control-lg form-control-solid`}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              {/* <span className="form-text text-muted">
                We'll never share your phone with anyone else.
              </span> */}
            </div>
          </div>

          <div className="col-12">
            <div className="avatar-upload-input text-center">
              <img src={Upload} alt="img" />
              <h2>Upload PDF</h2>
              {/* <p>Its must be a clean photo</p> */}
              <div className="avatar-edit-input">
                <input
                  className="form-control"
                  onChange={(e) => {
                    setImagesPreview(e.target.files[0]);
                  }}
                  type="file"
                  name="avatar"
                  accept=".pdf"
                />
              </div>
            </div>
          </div>

          {imagesPreview ? (
            <div>
              <iframe
                style={{ width: "100%", height: "500px" }}
                src={URL.createObjectURL(imagesPreview)}
              />
            </div>
          ) : backendUrl != "" ? (
            <iframe
              style={{ width: "100%", height: "500px" }}
              src={backendUrl}
            />
          ) : (
            ""
          )}
          {/* <div style={{display:"flex"}}>
              {imagesPreview.map((image, index) => (
                <img height={200} width={200} style={{marginLeft:"10px",borderRadius:"10px"}} key={index} src={image} alt="Product Preview" />
              ))}
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default SupportForm;
