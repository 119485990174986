import React, { useEffect, useState, useRef } from "react";
import { Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { baseUrl } from "../../../../services/config";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import ChallanDetailModal from "./ViewStudentChallanDetail";
import { upperCase } from "lodash";
import ReactToPrint from "react-to-print";
import { Divider } from "@material-ui/core";
export default function ViewStudent({ id }) {
  //states
  const [studentdetails, setstudentdetails] = useState([]);
  const scrollRef = useRef(null);
  const [classname, setclassname] = useState("");
  const [studentchallans, setstudentchallans] = useState([]);
  const [liabliefees, setliabliefees] = useState([]);
  const [selectedchallan, setselectedchallan] = useState({});
  const [viewmodal, setviewmodal] = useState(false);
  const [challanLoading, setchallanLoading] = useState(false);
  const authtoken = localStorage.getItem("userToken");

  const user = JSON.parse(localStorage.getItem("userDetails"));

  const params = useParams();
  const history = useHistory();
  let loaction = useLocation();

  const componentRef = useRef(null);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const onBeforeGetContentResolve = React.useRef(null);
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const pageStyle = `
  @page {
    size: a4 !important;
}
`;

  useEffect(() => {
    getstudentbyid();
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getstudentbyid = async () => {
    await fetch(`${baseUrl}/student/${id == undefined ? params.id : id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          const student = data.payload;
          console.log(student, "stude");

          setstudentdetails(student);
          // getclassbystudentid(student);
          setliabliefees(student.liable_fees);
          // studentdetails.liable_fees
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const getclassbystudentid = async (student) => {
    console.log("function log", student.student_class_id);
    const id = student.student_class_id;
    await fetch(`${baseUrl}/studentclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setclassname(data.payload.name);
          showchallandetails(student);

          // console.log(data.payload.name)
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const showchallandetails = () => {
    setchallanLoading(true);
    const data = {
      student_id: studentdetails.id,
    };
    console.log(data);

    fetch(`${baseUrl}/student/challan_history`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((challans) => {
          setstudentchallans(challans.payload);
          setchallanLoading(false);
          console.log(challans.payload);
          scrollRef.current.scrollIntoView({ behavior: "smooth" });
        })
      )
      .catch((err) => {
        setchallanLoading(false);
        console.log(err);
      });
  };
  const gotoviewpage = (feechallan) => {
    console.log(feechallan);
    setselectedchallan(feechallan);
    setviewmodal(true);
  };

  console.log("this is definaed fee bhai", liabliefees);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const formatdate2 = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  return (
    <div>
      {/* <button
        className="btn btn-link-danger"
        onClick={() => {
          //   console.log(user.role[0]=="Head Office")  Campus
          if (user.role[0] == "Campus") {
            history.push("/campusstudents");
          } else {
            console.log(loaction.state);
            history.push("/" + loaction.state);
          }
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}
          />
        </span>
      </button> */}

      {studentdetails ? (
        <div
          className="d-flex justify-content-end"
          style={{ marginBottom: "-30px" }}
        >
          <ReactToPrint
            pageStyle={pageStyle}
            documentTitle={`Student Print Slip`}
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint={true}
            trigger={() => (
              <button
                onClick={() => console.log("dfsdfsdfd")}
                disabled={printloading ? true : false}
                className="btn btn-primary"
              >
                {printloading ? (
                  <span>Printing..</span>
                ) : (
                  "Print Admission Slip"
                )}
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
      ) : null}

      <div className="card">
        <h2 style={{ margin: 10 }}>Personal Details</h2>
        <div className=" col-md-12 d-flex ">
          <div className=" col-md-6 d-flex ">
            <div>
              {" "}
              <h5 style={{ color: "grey" }}>Name : &ensp;</h5>{" "}
            </div>
            <div>
              <h5
                title={
                  studentdetails.name == undefined
                    ? null
                    : studentdetails.name.length > 20
                    ? studentdetails.name
                    : null
                }
                style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
              >
                {" "}
                {studentdetails.name == undefined
                  ? null
                  : " " + studentdetails.name}
              </h5>
            </div>
          </div>
          <div className=" col-md-6 d-flex ">
            <div>
              {" "}
              <h5 style={{ color: "grey" }}>Father Name : &ensp;</h5>{" "}
            </div>
            <div>
              <h5
                title={
                  studentdetails.father_name == undefined
                    ? null
                    : studentdetails.father_name.length > 15
                    ? studentdetails.father_name
                    : null
                }
                style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
              >
                {" "}
                {studentdetails.father_name == undefined
                  ? null
                  : " " + studentdetails.father_name}
              </h5>
            </div>
          </div>
        </div>
        <hr />

        <div className=" col-md-12 d-flex ">
          <div className=" col-md-6 d-flex ">
            <div>
              {" "}
              <h5 style={{ color: "grey" }}>Date of Birth : &ensp;</h5>{" "}
            </div>
            <div>
              <h5
                title={
                  studentdetails.dob == undefined
                    ? null
                    : studentdetails.dob.length > 15
                    ? studentdetails.dob
                    : null
                }
                style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
              >
                {" "}
                {studentdetails.dob == undefined
                  ? null
                  : " " + studentdetails.dob}
              </h5>
            </div>
          </div>
          <div className=" col-md-6 d-flex ">
            <div>
              {" "}
              <h5 style={{ color: "grey" }}>Admission ID : &ensp;</h5>{" "}
            </div>
            <div>
              <h5
                title={
                  studentdetails.admission_id == undefined
                    ? null
                    : studentdetails.admission_id.length > 15
                    ? studentdetails.admission_id
                    : null
                }
                style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
              >
                {" "}
                {studentdetails.admission_id == undefined
                  ? null
                  : " " + studentdetails.admission_id}
              </h5>
            </div>
          </div>
        </div>

        <hr />

        <div className=" col-md-12 d-flex ">
          <div className=" col-md-6 d-flex ">
            <div>
              {" "}
              <h5 style={{ color: "grey" }}>Gender : &ensp;</h5>{" "}
            </div>
            <div>
              <h5
                title={
                  studentdetails.gender == undefined
                    ? null
                    : studentdetails.gender.length > 15
                    ? studentdetails.gender
                    : null
                }
                style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
              >
                {" "}
                {studentdetails.gender == undefined
                  ? null
                  : studentdetails.gender.length > 15
                  ? " " + studentdetails.gender.substring(0, 12) + "..."
                  : " " + studentdetails.gender}
              </h5>
            </div>
          </div>
          <div className=" col-md-6 d-flex ">
            <div>
              {" "}
              <h5 style={{ color: "grey" }}>Mobile No# : &ensp;</h5>{" "}
            </div>
            <div>
              <h5
                title={
                  studentdetails.mobile_no == undefined
                    ? null
                    : studentdetails.mobile_no.length > 15
                    ? studentdetails.mobile_no
                    : null
                }
                style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
              >
                {" "}
                {studentdetails.mobile_no == undefined
                  ? null
                  : " " + studentdetails.mobile_no}
              </h5>
            </div>
          </div>
        </div>

        <hr />
        <div className=" col-md-12 d-flex ">
          <div className=" col-md-6 d-flex ">
            <div>
              {" "}
              <h5 style={{ color: "grey" }}>Class : &ensp;</h5>{" "}
            </div>
            <div>
              <h5
                title={
                  studentdetails.student_class_name == undefined
                    ? null
                    : studentdetails.student_class_name.length > 15
                    ? studentdetails.student_class_name
                    : null
                }
                style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
              >
                {" "}
                {studentdetails.student_class_name == undefined
                  ? null
                  : " " + studentdetails.student_class_name}
              </h5>
            </div>
          </div>
          <div className=" col-md-6 d-flex ">
            <div>
              {" "}
              <h5 style={{ color: "grey" }}>Section: &ensp;</h5>{" "}
            </div>
            <div>
              <h5
                title={
                  studentdetails?.global_section?.name == undefined
                    ? null
                    : studentdetails?.global_section?.name.length > 15
                    ? studentdetails?.global_section?.name
                    : null
                }
                style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
              >
                {" "}
                {studentdetails?.global_section?.name == undefined
                  ? null
                  : " " + studentdetails?.global_section?.name}
              </h5>
            </div>
          </div>
        </div>

        <hr />

        <div className=" col-md-12 d-flex ">
          <div className=" col-md-6 d-flex ">
            <div>
              {" "}
              <h5 style={{ color: "grey" }}>Joining date : &ensp;</h5>{" "}
            </div>
            <div>
              <h5
                title={
                  studentdetails?.Joining_date == undefined
                    ? null
                    : studentdetails?.Joining_date.length > 15
                    ? studentdetails?.Joining_date
                    : null
                }
                style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
              >
                {" "}
                {studentdetails?.Joining_date == undefined
                  ? null
                  : " " + studentdetails?.Joining_date}
              </h5>
            </div>
          </div>
          <div className=" col-md-6 d-flex ">
            <div>
              {" "}
              <h5 style={{ color: "grey" }}>Father CNIC# : &ensp;</h5>{" "}
            </div>
            <div>
              <h5
                title={
                  studentdetails.father_cnic == undefined
                    ? null
                    : studentdetails.father_cnic.length > 15
                    ? studentdetails.father_cnic
                    : null
                }
                style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
              >
                {" "}
                {studentdetails.father_cnic == undefined
                  ? null
                  : " " + studentdetails.father_cnic}
              </h5>
            </div>
          </div>
        </div>
        <hr />
        <div className="row mx-3">
          <div className="col-md-12">
            <h5 style={{ color: "grey" }}>
              <b>Address:</b> &ensp;
            </h5>{" "}
          </div>
          <div className="col-md-12">
            {/* <h5>{studentdetails.name}</h5> */}
            <h5
              title={
                studentdetails.address == undefined
                  ? null
                  : studentdetails.address
              }
              style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
            >
              {" "}
              {studentdetails.address == undefined
                ? null
                : studentdetails.address}
            </h5>
          </div>
        </div>
      </div>

      <div className="card">
        <div>
          <h2 className="m-5">Defined Fee</h2>
        </div>
        <Table responsive>
          <thead>
            <tr id="tbl">
              <th>ID</th>
              <th>Fees Name</th>
              <th>Total Amount</th>
              <th>Payable Amount</th>
              <th>Concession</th>

              {/* <th>Admit Card</th>
                            <th>Edit</th>
                            <th>Delete</th>
                            <th>View</th> */}
            </tr>
          </thead>

          <tbody>
            {liabliefees.map((item) => (
              <tr>
                <td style={{ textAlign: "start" }}>{item.id}</td>
                <td style={{ textAlign: "start" }}>{item.fee_type.name}</td>
                <td style={{ textAlign: "start" }}>
                  {parseInt(item.amount) + parseInt(item.concession_amount)}
                </td>
                <td style={{ textAlign: "start" }}>{item.amount}</td>
                <td style={{ textAlign: "start" }}>{item.concession_amount}</td>
              </tr>
            ))}
          </tbody>
          {/* <tfoot>
                        {
                            studentchallans.map(item => (
                                <tr>
                                    <td>{i}</td>
                                    <td>22200</td>
                                </tr>
                            ))
                        }

                    </tfoot> */}
        </Table>
      </div>

      <div>
        <button onClick={showchallandetails} className="btn btn-primary m-3">
          Get Challan History
        </button>
      </div>
      {challanLoading && <Spinner animation="border" />}
      {studentchallans.length > 0 ? (
        <div ref={scrollRef} className="card">
          <div>
            <h2 className="m-5">Fee Details</h2>
          </div>
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th>Sr.No</th>
                <th>Challan No</th>
                <th>Payable</th>
                <th>Paid</th>
                <th>Issue Date</th>
                <th>Due Date</th>
                <th>View</th>
              </tr>
            </thead>

            <tbody>
              {studentchallans.map((item, i) => (
                <tr>
                  <td style={{ textAlign: "start" }}>{i + 1}</td>
                  <td style={{ textAlign: "start" }}>
                    {item?.fee_challan?.challan_no}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {item?.fee_challan?.payable}
                  </td>

                  <td style={{ textAlign: "start" }}>
                    {item?.fee_challan?.paid}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {item?.fee_challan?.issue_date}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {item?.fee_challan?.due_date}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => gotoviewpage(item)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Devices/Display2.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>

            {/* <tfoot>
                        {
                            studentchallans.map(item => (
                                <tr>
                                    <td>{i}</td>
                                    <td>22200</td>
                                </tr>
                            ))
                        }

                    </tfoot> */}
          </Table>
        </div>
      ) : null}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        {studentdetails ? (
          <>
            <div className="p-3" style={{ display: "flex" }}>
              <img style={{ height: 100, width: 110 }} src={icon} />

              <div className="col-md-6 ml-4" style={{ paddingTop: "10px" }}>
                <h1>{unitName}</h1>
                <h4>
                  ADMISSION SLIP FOR THE ACADEMIC SESSION{" "}
                  {studentdetails?.session?.year}
                </h4>
                <h4>Printing Date:- {formatdate2()}</h4>
              </div>
            </div>
            <hr style={{ border: "2px solid prrimary" }} />

            <div
              className="d-flex justify-content-between p-5"
              style={{ border: "2px solid #121291" }}
            >
              <div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Admission No:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "152px", fontSize: "16px" }}>
                    {studentdetails?.admission_id}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Student Name:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "145px", fontSize: "16px" }}>
                    {studentdetails?.name}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Father Name:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "157px", fontSize: "16px" }}>
                    {studentdetails?.father_name}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Contact No.:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "166px", fontSize: "16px" }}>
                    {studentdetails?.mobile_no}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Class Name:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "166px", fontSize: "16px" }}>
                    {studentdetails?.student_class_name}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Section Name:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "148px", fontSize: "16px" }}>
                    {studentdetails?.global_section_name}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Campus Name:-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "137px", fontSize: "16px" }}>
                    {studentdetails?.campus?.name}
                  </h6>
                </div>
                <div className="d-flex">
                  <h5 style={{ fontSize: "18px" }}>Admission Date :-</h5>{" "}
                  &nbsp;&nbsp;{" "}
                  <h6 style={{ marginLeft: "129px", fontSize: "16px" }}>
                    {studentdetails?.Joining_date}
                  </h6>
                </div>
              </div>
              <div style={{ marginTop: "65px", marginRight: "50px" }}>
                <span className="">
                  <p
                    style={{
                      border: "1px solid #121291",
                      fontSize: "20px",
                      fontFamily: "cursive",
                    }}
                    className="p-5"
                  >
                    CONGRATULATIONS
                  </p>
                </span>
              </div>
            </div>
          </>
        ) : null}
      </div>

      {viewmodal ? (
        <ChallanDetailModal
          show={viewmodal}
          onHide={setviewmodal}
          challandtail={selectedchallan}
        />
      ) : null}
    </div>
  );
}
