import React, { useEffect, useState, useRef } from "react";
import { Divider } from "antd";
import { Hisection_compareen, LinearProgress, Paper } from "@material-ui/core";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { DatePicker, Space } from "antd";
import { Table } from "react-bootstrap";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;
const pageStyle = `
  @page {
    size: A4 !important;
}
`;
const StudentAllInfo = () => {
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [reportData, setreportData] = useState([]);
  const [titleData, settitleData] = useState("0");
  const [visData, setvisData] = useState("0");
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [campus_id, setcampuse_id] = useState("");
  const [report_type, setreport_type] = useState("active_student");
  const [reportClassData, setreportClassData] = useState([]);
  var [logomodel, setlogomodel] = useState("");
  var section_compare = "0";
  var class_initial = "0";
  var class_compare = "0";
  var campus_Initial = "0";
  const [sessioning, setallsession] = useState([]);
  const [dates, setDates] = useState([1]);
  const [session_id, setSession_id] = useState([0]);
  const [yearId, setYearId] = useState("");

  const [dates1, setDates1] = useState([]);
  const [tuitionFee, setTuitionFee] = useState([]);
  const [hostelFee, sethostelFee] = useState([]);
  var [logomodel1, setlogomodel1] = useState("");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();

  var i = 0;
  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };
  let authtoken = localStorage.getItem("userToken");
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    getallsessions();
    // getAllCampus();
    getallsessions();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCampusChange = (e) => {
    setcampuse_id(e.target.value);
    setreport_type("campus_student");
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let campuses = [];
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getallsessions = async () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsession(data.payload);
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            //  setcurrentsession(item.id.toString());
            setYearId(item.id);
            setSession_id(item.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const misection_compareleFunction = (id) => {
    setDates(id);
    var sel = document.getElementById("cic");
    var text = sel.options[sel.selectedIndex].text;
    setDates1(text);
  };

  const handleShow = () => {
    setreportData([]);
    var temp_hostelFee = 0;
    var temp_tuitionFee = 0;
    setTuitionFee(temp_tuitionFee);
    sethostelFee(temp_hostelFee);
    // console.log(dates);
    setLoading(true);
    var campus_value = document.getElementById("campus_value").value;
    //var cix = document.getElementById("cic").value;

    if (campus_value > 0) {
      console.log("condition ids working" + campus_value);
      setreport_type("campus_student");
    }
    const data = {
      campus_id: campus_id,
      report_type: report_type,
      session_id: yearId,
    };
    // fetch(`${baseUrl}/stnrpt?id=${cix}&cmps=${cmps}`, {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${authtoken}`,
    //   },
    // })

    fetch(`${baseUrl}/show-campus-fee-infomation`, {
      method: "POST",
      headers: {
        Asection_Initialept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);
        data.payload.map((campus) => {
          campus.liable.map((campusnext) => {
            if (campusnext.fees_type_id == "4") {
              temp_tuitionFee =
                temp_tuitionFee +
                parseFloat(campusnext.amount == null ? 0 : campusnext.amount);
            }
            if (campusnext.fees_type_id == "7") {
              temp_hostelFee =
                temp_hostelFee +
                parseFloat(campusnext.amount == null ? 0 : campusnext.amount);
              // console.log(hf);
            }
          });
          setTuitionFee(temp_tuitionFee);
          sethostelFee(temp_hostelFee);
        });
        setreportData(data.payload);
        // console.log(data);
        //   if (data.metadata) {

        //    if (data.metadata.susection_Initialess) {

        //console.log(data.payload);
        //  setreportData(data);

        //  }
        // }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const divStyle = {
    display: `${logomodel1}`,
  };
  function handleView() {
    console.log(logomodel);
    if (logomodel == false) {
      setlogomodel1("none");
    }
    if (logomodel == true) {
      setlogomodel1("");
    }
    // isFineEnable ? 1 : 0
    //  setlogomodel("");
    console.log(logomodel1);
  }
  function handleNone(event) {
    setlogomodel("none");
    console.log(logomodel);
  }

  const printingDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  return (
    <>
      <Paper
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          pasection_compareing: "15px",
        }}
      >
        {loading ? <LinearProgress /> : null}

        <div className="row mt-3" style={{ padding: "20px" }}>
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              className="form-select"
              value={yearId}
              onChange={(e) => setYearId(e.target.value)}
              style={{ height: 40 }}
            >
              {sessioning.map((campusdata) => (
                <option value={campusdata.id} key={campusdata.id}>
                  {campusdata.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Type</b>
            </label>
            <div>
              <select
                id="cic"
                className="form-select"
                onChange={(e) => setreport_type(e.target.value)}
                style={{ height: 40 }}
              >
                <option value="active_student">Active Students</option>
                <option value="all_Student">All Students</option>
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              className="form-select"
              id="campus_value"
              onChange={onCampusChange}
              style={{ height: 40 }}
            >
              <option value="0">All Campuses</option>
              {campuses.map((campusdata) => (
                <option value={campusdata.id} key={campusdata.id}>
                  {campusdata.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-1 d-flex" style={{ marginTop: "30px" }}>
            <button onClick={handleShow} className="btn btn-primary mr-4">
              Show
            </button>
            {reportData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`salary slip Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                pageStyle={pageStyle}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-susection_Initialess button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>

        {reportData?.length > 0 ? (
          <div>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                pasection_compareing: "15px",
              }}
            >
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Sr No</th>
                    <th style={{ textAlign: "center" }}>Admin ID</th>
                    <th style={{ textAlign: "center" }}>Student Name</th>
                    <th style={{ textAlign: "center" }}>Father Name</th>

                    <th style={{ textAlign: "center" }}>Gender</th>
                    <th style={{ textAlign: "center" }}>Mobile No</th>
                    <th
                      style={{
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      Date Of Birth
                    </th>
                    <th
                      style={{
                        width: "100px",
                        textAlign: "center",
                      }}
                    >
                      Monthly / Hostel Fee
                    </th>
                    <th style={{ textAlign: "center" }}>Father CNIC</th>
                  </tr>
                </thead>

                {reportData.map((campus) => {
                  return (
                    <tbody>
                      {(() => {
                        var campus_compare = campus.cam;

                        if (campus_Initial !== campus_compare) {
                          campus_Initial = campus.cam;
                          return (
                            <tr style={{ Align: "left" }}>
                              <td
                                colSpan={9}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <b>{campus.cam}</b>
                              </td>
                            </tr>
                          );
                        }
                      })()}

                      {/* {(() => {
                          var class_compare = campus.cls;

                          if (class_initial !== class_compare) {
                            class_initial = campus.cls;
                            return (
                              <tr style={{ Align: "left" }}>
                                <td
                                  colSpan={9}
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <b>{campus.cls}</b>
                                </td>
                              </tr>
                            );
                          }
                        })()} */}

                      {(() => {
                        var section_Initial = campus.sec;

                        if (section_Initial !== section_compare) {
                          section_compare = campus.sec;
                          return (
                            <tr style={{ Align: "left" }}>
                              <td></td>
                              <td></td>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <b>
                                  Class:&nbsp;&nbsp;&nbsp;&nbsp; {campus.cls}
                                </b>
                              </td>
                              <td
                                colSpan={6}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <b>
                                  Section:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {campus.sec}
                                </b>
                              </td>
                            </tr>
                          );
                        }
                      })()}

                      <tr>
                        <td>{(i = i + 1)}</td>
                        <td>{campus.admin}</td>
                        <td>{campus.name}</td>
                        <td>{campus.fn}</td>
                        <td>{campus.gen}</td>
                        <td>{campus.mob}</td>

                        <td>{campus.dob}</td>

                        <td style={{ width: "75px" }}>
                          {(() => {
                            var section_Initial = campus.liable;

                            if (section_Initial !== null) {
                              return campus.liable.map((campusnext) => {
                                return <div>{campusnext.amount}</div>;
                              });
                            } else {
                              return <div>0</div>;
                            }
                          })()}
                        </td>
                        <td>{campus.cn}</td>
                      </tr>
                    </tbody>
                  );
                })}

                <tr style={{ Align: "left" }}>
                  <td
                    colSpan={10}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Tuition Fee= <b>{tuitionFee}</b> Hostel Fee=
                    <b>{hostelFee}</b> Total Fee=
                    <b>{tuitionFee + hostelFee}</b>
                  </td>
                </tr>
              </Table>
            </div>
          </div>
        ) : null}

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hisection_compareen" }}
          className="tablePrint"
        >
          <div
            className="mainContainer"
            style={{
              margin: "30px",

              height: "1530px",
            }}
          >
            <div className="logocontainer" style={{ height: "100px" }}>
              <div className="d-flex" style={divStyle}>
                <div className="">
                  <img
                    style={{
                      height: 100,
                      width: 100,
                      pasection_compareingLeft: "20px",
                    }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div
                  style={{
                    pasection_compareingTop: "10px",
                    marginLeft: "20px",
                  }}
                >
                  <h1>{unitName}</h1>
                  <h3>Student Fee Information Report</h3>
                  <h3> Printing Date:- {printingDate()}</h3>
                </div>
              </div>
            </div>
            <div style={{ fontSize: "12px", marginTop: "20px" }}>
              {(() => {
                i = 0;
              })()}
              <Table
                responsive
                style={{ whiteSpace: "normal", padding: "1px" }}
              >
                <thead
                  style={{
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "16px",
                  }}
                >
                  <tr
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "16px",
                    }}
                  >
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Sr No
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Admin ID
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Father Name
                    </th>

                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Gender
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Mobile No
                    </th>
                    <th
                      style={{
                        width: "100px",
                        textAlign: "center",
                        fontSize: "16px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      Monthly / Hostel
                    </th>
                  </tr>
                </thead>

                {reportData.map((campus) => {
                  return (
                    <tbody style={{ whiteSpace: "normal", padding: "1px" }}>
                      {(() => {
                        var campus_compare = campus.cam;

                        if (campus_Initial !== campus_compare) {
                          campus_Initial = campus.cam;
                          return (
                            <tr
                              style={{
                                Align: "left",
                                fontSize: "16px",
                                whiteSpace: "normal",
                                padding: "1px",
                              }}
                            >
                              <td
                                colSpan={9}
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                              >
                                <b>{campus.cam}</b>
                              </td>
                            </tr>
                          );
                        }
                      })()}

                      {/* {(() => {
                        var class_compare = campus.cls;

                        if (class_initial !== class_compare) {
                          class_initial = campus.cls;
                          return (
                            <tr style={{ Align: "left" }}>
                              <td
                                colSpan={9}
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                <b>{campus.cls}</b>
                              </td>
                            </tr>
                          );
                        }
                      })()} */}

                      {(() => {
                        var section_Initial = campus.sec;

                        if (section_Initial !== section_compare) {
                          section_compare = campus.sec;
                          return (
                            <tr
                              style={{
                                Align: "left",
                                fontSize: "16px",
                                whiteSpace: "normal",
                                padding: "1px",
                              }}
                            >
                              <td></td>
                              <td></td>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                              >
                                <b>
                                  Class:&nbsp;&nbsp;&nbsp;&nbsp; {campus.cls}
                                </b>
                              </td>
                              <td
                                colSpan={6}
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                  whiteSpace: "normal",
                                  padding: "1px",
                                }}
                              >
                                <b>
                                  Section:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {campus.sec}
                                </b>
                              </td>
                            </tr>
                          );
                        }
                      })()}

                      <tr
                        style={{
                          fontSize: "16px",
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        <td
                          style={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                        >
                          {(i = i + 1)}
                        </td>
                        <td
                          style={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                        >
                          {campus.admin}
                        </td>
                        <td
                          style={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                        >
                          {/* {campus.name} */}
                          {campus.name.length > 20
                            ? campus.name.substring(0, 20) + "..."
                            : campus.name}
                        </td>
                        <td
                          style={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                        >
                          {/* {campus.fn} */}
                          {campus.fn.length > 20
                            ? campus.fn.substring(0, 20) + "..."
                            : campus.fn}
                        </td>
                        <td
                          style={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                        >
                          {campus.gen}
                        </td>
                        <td
                          style={{
                            fontSize: "16px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                        >
                          {campus.mob}
                        </td>

                        <td
                          style={{
                            width: "60px",
                            fontSize: "16px",
                            whiteSpace: "normal",
                            padding: "1px",
                          }}
                        >
                          {(() => {
                            var section_Initial = campus.liable;

                            if (section_Initial !== null) {
                              return campus.liable.map((campusnext) => {
                                return <div>{campusnext.amount}</div>;
                              });
                            } else {
                              return <div>0</div>;
                            }
                          })()}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}

                <tr style={{ Align: "left" }}>
                  <td
                    colSpan={10}
                    style={{
                      textAlign: "left",
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                    }}
                  >
                    Tuition Fee= <b>{tuitionFee}</b> Hostel Fee=
                    <b>{hostelFee}</b> Total Fee=<b>{tuitionFee + hostelFee}</b>
                  </td>
                </tr>
              </Table>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default StudentAllInfo;
