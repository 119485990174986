import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Button, Spinner, Alert } from "react-bootstrap";
// import './style.css';
import { useHistory } from "react-router-dom";
// import AddStudent from './Form/AddStudent';
import { useAlert } from "react-alert";
import SVG from "react-inlinesvg";
// import Pagination from '../../../components/Pagination/Pagination';
import DefineAdditionalFee from "../DefineFeeOnAddStudent";
import ReactPaginate from "react-paginate";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { baseUrl } from "../../../../services/config";
import StudentCard from "../../../../components/StudentCard/StudentCard";
import DefineMonthlyFee from "../defineMonthlyFee";
import EditStudent from "../../ShowAllStudents/EditStudent/EditStudent";
import usePagination from "../../../../components/usePagination/usePagination";
import ViewStudent from "../ViewStudent/ViewStudent";

const OnlyRegistered = (props) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector((state) => state.user.user);
  const [editModal, seteditModal] = useState(false);
  const [selectedEditStudent, setselectedEditStudent] = useState({});
  const [pendingstatus, setpendingstatus] = useState(false);
  const [pendingstatusid, setpendingstatusid] = useState("");
  const [classes, setClasses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [viewSelectedId, setViewSelectedId] = useState("");
  const [pendingstatusload, setpendingstatusload] = useState(false);
  const [additionalfeemodal, setadditionalfeemodal] = useState(false);
  // const [students, setstudents] = useState([])
  const [selectedcampus, setselectedcampus] = useState("");
  const [allconcessions, setallconcessions] = useState([]);
  const [defaultCon, setDefaultCon] = useState({});
  const [allhostel, setallHostel] = useState([]);
  const [defaulthol, setDefaulthol] = useState({});
  const [allVehical, setallVehical] = useState([]);
  const [defaultVel, setDefaultVel] = useState({});
  const students = useSelector((state) => state.students.AllStudents);
  const [feetypeamount, setfeetypeamount] = useState("");
  const [admiloading, setadmiloading] = useState(false);
  const [due_date, setdue_date] = useState("");
  const [issue_date, setissue_date] = useState("");
  const [isalldirectenablefields, setisalldirectenablefields] = useState(false);
  const [feegeneratemodal, setfeegeneratemodal] = useState(false);
  const [modal, setmodal] = useState(false);
  const [admitmodal, setadmitmodal] = useState(false);
  const [selectedStudent, setselectedStudent] = useState({});
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [campusesStudents, setcampusesStudents] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [addmissionobject, setaddmissionobject] = useState({});
  const [stuloading, setstuloading] = useState(false);
  const [deletedstudentid, setdeletedstudentid] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [feegenerateloading, setfeegenerateloading] = useState(false);
  const [currentcampus, setcurrentcampus] = useState("");
  const [sectionData, setSectionData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [duedate, setduedate] = useState("");
  const [feemonth, setfeemonth] = useState("");

  //pagination states
  // const [loading, setloading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);
  const [statuschangeloading, setstatuschangeloading] = useState(false);

  const [currentObject, setcurrentObject] = useState({});
  const [defineMonthlyfeeModal, setdefineMonthlyfeeModal] = useState(false);

  let authtoken = localStorage.getItem("userToken");
  const [educationType, setEducationType] = useState(1);

  //admission states
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  const [addmissionmodal, setaddmissionmodal] = useState(false);
  // console.log(user);

  // console.log(students)
  useEffect(() => {
    // console.log(authtoken,"baaaaaaaaa")
    // getAllStudents()
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      // getAllStudents('', campusidget);
    }
    // getAllCampus();
    getConcessionTypes();
    getHostelID();
    getVehicleID();
  }, []);

  const deletestudent = (student) => {
    setdeletedstudentid(student.id);
    setdeleteModal(true);
  };

  const getAllStudents = async (e, value) => {
    console.log("pakistan");
    // setstuloading(true);
    let id = "";
    // if (e.target) {

    //   id = e.target.value
    //   setcurrentcampus(e.target.value)
    // } else {
    //   id = e;
    // }

    // let id;

    if (e.target) {
      setcurrentcampus(e.target.value);
      onChampusChange(e.target.value);
      id = e.target.value;
    } else {
      id = value;
    }
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });
    setpreviousselectedcampus(id);

    setcurrentcampus(id);
    setselectedcampus(id);
    console.log(id, "id ");
    // await fetch(`${baseUrl}/campus/campus-students/${id}`, {
    //   method: 'GET',
    //   headers: {
    //     Authorization: `Bearer ${authtoken}`,
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data);
    //     let temparr = data.payload
    //     data.payload.map((item, index) => {
    //       item["SrNo"] = index + 1
    //       temparr[index] = item
    //     })

    //     setcampusesStudents(temparr);

    //     setstuloading(false);

    //   })
    //   .catch((err) => {
    //     setstuloading(false);
    //     console.log(err);
    //   });
  };

  const DeleteCampus = () => {
    fetch(`${baseUrl}/student/${deletedstudentid}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.metadata.success) {
            alert.success("Student Deleted successfully");
            setdeleteModal(false);
            getAllStudents("", currentcampus);
          }
          if (!data.metadata.success) {
            alert.success("Student Deleted successfully");
            setdeleteModal(false);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setdeleteModal(false);
      });
    // console.log(id)
    // fetch(`${baseUrl}/campus/${currentCampusId}`, {
    //   method: 'DELETE',
    //   headers: {
    //     // 'Accept': 'application/json',
    //     // 'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${authtoken}`
    //   },
    // }).then(res => res.json()
    //   .then(data => {
    //     if (data.metadata.success) {
    //       alert.success('Campus Deleted Successfully')
    //       GetAllCampuses()
    //       setdeleteModal(false)
    //     }
    //     if (!data.metadata.success) {
    //       alert.success('Something went wrong & campus not deleted')
    //       setdeleteModal(false)
    //     }
    //   })

    // ).catch(err => {
    //   console.log(err)
    //   setdeleteModal(false)
    // })
  };

  const openAdmitCard = (student) => {
    setselectedStudent(student);
    setadmitmodal(true);
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const gotoviewpage = (student) => {
    const id = student.id;
    setViewSelectedId(id);
    setselectedStudent(student);
    setShowModal(true);
  };

  const openaddmissionmodal = (student) => {
    console.log(student);
    setaddmissionmodal(true);
    setaddmissionobject(student);
  };

  const deletPreviousChalan = (challanid, obj) => {
    fetch(`${baseUrl}/fee-destroy/${challanid}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      method: "DELETE",
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          if (data.metadata.success) {
            console.log(data, "deleted challan data");
            setstatuschangeloading(false);
            setaddmissionmodal(false);
            changeStatusToPending(obj);
            setadditionalfeemodal(true);
            setselectedStudent(obj);
            // getRespectivefee()
            // setfeegeneratemodal(true)
          } else {
            alert.show("Error Occured while Creating Challan");
          }
        });
      })
      .catch((err) => {
        alert.show("Error Occured while Creating Challan");
        console.log(err);
      });
  };

  const changeStatusToPending = (obj) => {
    const data = {
      student_id: obj.id,
      status: 3,
    };

    fetch(`${baseUrl}/studentstatus_change`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GenerateChalan = (obj) => {
    console.log(`${baseUrl}/twostep_admission/${obj.id}`);
    console.log("twostep_admission");
    fetch(`${baseUrl}/twostep_admission/${obj.id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // if (data.metadata.success) {
        if (data.payload) {
          if (data.payload.paid_amount == null) {
            console.log(data, "delete challan data");
            deletPreviousChalan(data.payload.challan_id, obj);
          } else {
            console.log(data, "generate new challan ");
            setadditionalfeemodal(true);
            setaddmissionmodal(false);
            changeStatusToPending(obj);
            setstatuschangeloading(false);
            setselectedStudent(obj);

            //  getRespectivefee(data.payload.paid_amount)
            // setfeegeneratemodal(true)
          }
        }
        // }
      })
      .catch((err) => {
        console.log(err);
        alert.show("can not generate challan");
      });
  };

  const submitFee = () => {
    setstatuschangeloading(true);
    console.log(addmissionobject.id, "addmissionobject");
    let data1 = {
      fee_month: feemonth + "-1",
      due_date: duedate,
    };

    console.log(data);

    fetch(`${baseUrl}/admission_after_registration/${addmissionobject.id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data1),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            getAllStudents("", currentcampus);
            alert.show("Successed");
          } else {
            alert.show("Error Occured");
          }
        } else {
          alert.show("Error Occured");
        }
        setaddmissionmodal(false);
        console.log(data);
        setstatuschangeloading(false);
      })
      .catch((err) => {
        console.log(err);
        setaddmissionmodal(false);
        setstatuschangeloading(false);
        alert.show("Error Occured");
      });
    // setduedate,feemonth

    // GenerateChalan(addmissionobject)
    return;

    const data = {
      student_id: addmissionobject.id,
      status: 2,
    };

    fetch(`${baseUrl}/studentstatus_change`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setaddmissionobject({ ...data.payload });
            alert.show("Student Admission success");
            GenerateChalan(data.payload);
            setstatuschangeloading(false);
            setaddmissionmodal(false);
          } else {
            alert.show("Error Occured");
          }
        } else {
          alert.show("Error Occured");
        }
      })
      .catch((err) => {
        console.log(err);
        setaddmissionmodal(false);
        alert.show("Error Occured");
      });
  };

  const defineMonthlyFeeFunc = (staff) => {
    setcurrentObject(staff);
    setdefineMonthlyfeeModal(true);
  };

  const getRespectivefee = (amount) => {
    let data = {};

    // data = {
    //   campus_id: addmissionobject.campus_id,
    //   student_class_id: addmissionobject.student_class_id,
    //   fee_type_id: 3
    // }
    data = {
      campus_id: selectedStudent.campus_id,
      student_class_id: selectedStudent.student_class_id,
      fee_type_id: 3,
    };

    console.log(data, "get respective fee data");

    fetch(`${baseUrl}/feestructure_amount`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.payload) {
          if (amount == undefined) {
            setfeetypeamount(data.payload.amount);
          } else {
            let actualamount = parseInt(data.payload.amount) - parseInt(amount);
            console.log(actualamount);
            setfeetypeamount(actualamount);
          }
        } else {
          alert.show("Can not get Get Respective Fee");
        }
      })
      .catch((err) => {
        console.log(err);
        alert.show("Can not get Get Respective Fee");
      });
  };

  const genrateFee = () => {
    // setadmiloading(true)

    if (due_date == "") {
      setisalldirectenablefields(true);
      return;
    }
    if (issue_date == "") {
      setisalldirectenablefields(true);
      return;
    }
    setisalldirectenablefields(false);
    if (due_date.length != 0) {
      if (issue_date.length != 0) {
        let fees_type_id = [];
        let amount = [];
        amount[0] = feetypeamount;
        fees_type_id[0] = 3;
        const data = {
          monthly_fee_status: 0,
          additional_fee_status: 1,
          fees_type_id,
          amount,
          fee_month: [],
          due_date,
          issue_date,
        };
        console.log(data);
        setfeegenerateloading(true);
        fetch(`${baseUrl}/custom_fees_generate/${addmissionobject.id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authtoken}`,
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            res.json().then((data) => {
              setfeegenerateloading(false);
              // setadmiloading(false)
              console.log(data);
              setdue_date("");
              setissue_date("");
              if (data.metadata.success) {
                alert.show("Fee generated success");
                history.push("/showallstudents");
              }
            });
          })
          .catch((err) => {
            setadmiloading(false);
            setdue_date("");
            setissue_date("");

            //  setdirectfeemodal(false)
            alert.show(err);
          });
      } else {
        //    setregloading(false)
        alert.show("Please enter due date");
      }
    } else {
      // setregloading(false)
      alert.show("Please enter due date");
    }
  };

  const getClasswiseStudent = (e) => {
    const id = e.target.value;
    setSelectedClass(id);
    onClassChange(id);
    let data = {
      campus_id: selectedcampus,
      student_class_id: id,
      global_section_id: "",
      year_id: "",
    };
    //  fetch(`${baseUrl}/all_registered_or_admitted`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${authtoken}`,
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data);
    //     let temparr = data.payload
    //     data.payload.map((item, index) => {
    //       item["SrNo"] = index + 1
    //       temparr[index] = item
    //     })

    //     setcampusesStudents(temparr);

    //     setstuloading(false);

    //   })
    //   .catch((err) => {
    //     setstuloading(false);
    //     console.log(err);
    //   });
  };

  const openEditDialog = (student) => {
    setDefaultCon({});
    setDefaulthol({});
    setDefaultVel({});
    console.log(student);
    setselectedEditStudent(student);
    if (student.concession_id) {
      const defaultValue = allconcessions.find(
        (item) => item.id == student.concession_id
      );
      setDefaultCon(defaultValue);
    }
    if (student.hostel_id) {
      const defaultValue = allhostel.find(
        (item) => item.id == student.hostel_id
      );
      setDefaulthol(defaultValue);
    }
    if (student.vehicle_id) {
      const defaultValue = allVehical.find(
        (item) => item.id == student.vehicle_id
      );
      setDefaultVel(defaultValue);
    }
    seteditModal(true);
  };

  //@_pagination
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;

  const dataSearch = campusesStudents.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user?.name?.toLowerCase().includes(text) ||
          user?.admission_id?.toLowerCase().includes(text)
      : true;
  });

  const currentPosts = dataSearch.slice(indexOfFirstPost, indexOfLastPost);

  const studentStatusChange = (studentid) => {
    console.log("ider a gya ha");
    if (!pendingstatus) {
      return;
    }
    setpendingstatusload(true);
    const data = {
      student_id: studentid,
      status: 2,
    };

    fetch(`${baseUrl}/studentstatus_change`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setpendingstatus(false);
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setpendingstatusload(false);
            getAllStudents("", currentcampus);
            alert.success("Student Admitted Successfully");
          } else {
            alert.success("Error Occured");
            setpendingstatus(false);
            setpendingstatusload(false);
          }
        } else {
          alert.success("Error Occured");
          setpendingstatus(false);
          setpendingstatusload(false);
        }
      })
      .catch((err) => {
        alert.success("Error Occured");
        setpendingstatus(false);
        setpendingstatusload(false);
        console.log(err);
      });
  };

  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(dataSearch.length / PER_PAGE);
  const _DATA = usePagination(dataSearch, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const [regid, setregid] = useState("");

  const searchbyRegId = (e) => {
    e.preventDefault();

    if (regid == "") {
      alert.error("Please enter registration Id");
    } else {
      const data = {
        search_keyword: regid.toLocaleUpperCase(),
      };

      fetch(`${baseUrl}/search_by_reg_id_name_adm_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("Match not found");
            }
            setcampusesStudents(data.payload);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const onChampusChange = (id) => {
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.payload) {
          setClasses(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = (id) => {
    fetch(`${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSectionData(data.payload);
        // getChallans()
      })
      .catch((err) => {
        console.log("", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedClass ? "/" + selectedClass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setSectionData(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSectionChange = (id) => {
    setSectionId(id);
    //     setstuloading(true);
    //   let data = {
    //     campus_id: selectedcampus,
    //     student_class_id: selectedClass,
    //     section_id: id,
    //     status:1,
    //     year_id: "",
    //   };
    //   fetch(`${baseUrl}/all_registered_or_admitted`, {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${authtoken}`,
    //     },
    //     body: JSON.stringify(data),
    //   })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data);
    //     let temparr = data.payload
    //     data.payload.map((item, index) => {
    //       item["SrNo"] = index + 1
    //       temparr[index] = item
    //     })

    //     setcampusesStudents(temparr);

    //     setstuloading(false);
    //     // dispatch({
    //     //   type: 'GET_ALL_STUDENT',
    //     //   payload: data.payload
    //     // })
    //   })
    //   .catch((err) => {
    //     setstuloading(false);
    //     console.log(err);
    //   });
  };

  const show = () => {
    setstuloading(true);

    let data = {
      campus_id: selectedcampus,
      student_class_id: selectedClass,
      section_id: sectionId,
      education_type: educationType,
      status: [1],
      year_id: "",
    };
    fetch(`${baseUrl}/all_registered_or_admitted`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let temparr = data.payload;
        data.payload.map((item, index) => {
          item["SrNo"] = index + 1;
          temparr[index] = item;
        });

        setcampusesStudents(temparr);

        setstuloading(false);
        // dispatch({
        //   type: 'GET_ALL_STUDENT',
        //   payload: data.payload
        // })
      })
      .catch((err) => {
        setstuloading(false);
        console.log(err);
      });
  };

  const handlemodelclose = () => {
    setmodal(false);
  };
  const getConcessionTypes = () => {
    fetch(`${baseUrl}/concession`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setallconcessions(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
      });
  };
  const getHostelID = async () => {
    await fetch(`${baseUrl}/hostel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallHostel(data.payload);
        console.log(data.payload, "This is hostel");
      })
      .catch((err) => {
        console.log("hostel", err);
      });
  };
  const getVehicleID = async () => {
    await fetch(`${baseUrl}/vehicle`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallVehical(data.payload);
        console.log(data.payload, "this is vehical");
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {/* <div className='row'>
          <div className='col-md-6'>
            <button onClick={() => {setmodal(true)
               alert.removeAll()
            }} style={{ backgroundColor: '#121291', color: 'white', height: 50 }}>
              Add Student
            </button>
          </div>


        </div> */}
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              // value={campuses.length == 0 ? '' : previousselectedcampus}
              onChange={(e) => getAllStudents(e)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>

              {campuses.map((campus) => (
                <option key={campus.id} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>
            {/* <label>Campus Wise Selection</label> */}
            <select
              className="form-select"
              name="campus_id"
              // value={campuses.length == 0 ? "" : previousselectedcampus}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => getClasswiseStudent(e)}
            >
              <option selected value="">
                {" "}
                Select Class
              </option>
              {classes &&
                classes.map((sclass, index) => (
                  <>
                    <option key={index} value={sclass.id}>
                      {sclass.name}
                    </option>
                  </>
                ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Section</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              aria-label="Default select example"
              onChange={(e) => onSectionChange(e.target.value)}
            >
              <option selected value="">
                {" "}
                Select Section
              </option>

              {sectionData &&
                sectionData.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>
          <div className="col-md-2 mt-4">
            <button onClick={show} type="button" className="btn btn-primary">
              Show
            </button>
          </div>
        </div>

        <form onSubmit={searchbyRegId}>
          <div className="row mt-5">
            <div className="col-md-6">
              <input
                className="form-control "
                placeholder="Enter Name or  Registration Id"
                onChange={(e) => setregid(e.target.value)}
              />
            </div>

            <div className="col-md-6">
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </div>
          </div>
        </form>

        <div>
          <Table responsive>
            <thead>
              <tr id="tbl">
                <th>Sr. no</th>
                {/* <th>ID</th> */}
                <th>Student Name</th>
                <th>Father Name</th>
                <th>Adm.ID</th>
                <th>Reg.ID</th>
                <th>Admit Card</th>
                {/* <th>Define fee</th> */}
                <th>Edit</th>
                <th>View</th>
                <th>Admission</th>
                {/* <th>Delete</th> */}
              </tr>
            </thead>

            <tbody>
              {_DATA.currentData().map((student, i) => {
                return (
                  <tr key={student.id} className="addstudentList">
                    <td>{student.SrNo}</td>
                    {/* <td>{student.id}</td> */}

                    <td
                      title={student?.name.length > 15 ? student?.name : null}
                      style={{ textAlign: "start", cursor: "pointer" }}
                    >
                      {/* {student.name.length > 15 ? student.name.substring(0, 12) + '...' : student.name} */}
                      {student.name}
                    </td>
                    <td
                      title={
                        student?.father_name.length > 15
                          ? student?.father_name
                          : null
                      }
                      style={{ textAlign: "start", cursor: "pointer" }}
                    >
                      {student?.father_name}
                    </td>

                    {/* <td>{student.name}</td> */}
                    {/* <td>{student.father_name}</td> */}
                    <td>{student.admission_id}</td>
                    <td>{student.registration_id}</td>
                    {/* <td>{student.shift}</td> */}
                    <td>
                      <a
                        style={{ cursor: "pointer", marginLeft: " 0px" }}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm "
                        onClick={() => openAdmitCard(student)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Flag.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>

                    {/* <td>
                      <a
                        style={{ cursor: 'pointer' }}
                        className='btn btn-icon btn-light btn-hover-primary btn-sm '
                        onClick={() => defineMonthlyFeeFunc(student)}
                      >
                        <span
                          className={
                            student?.liable_fees?.length == 0
                              ? // "btn btn-icon btn-light btn-hover-danger btn-sm svg-icon-danger"
                              'svg-icon svg-icon-md svg-icon-danger'
                              : 'svg-icon svg-icon-md svg-icon-primary'
                          }
                        >
                          <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')} />
                        </span>
                      </a>
                    </td> */}

                    <td>
                      <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm "
                        onClick={() => openEditDialog(student)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>

                    <td>
                      <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm "
                        onClick={() => gotoviewpage(student)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Devices/Display2.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                    <td>
                      {student.status == 2 ? (
                        <b>Done</b>
                      ) : student.status == 3 ? (
                        <button
                          onClick={() => {
                            // setpendingstatus(true)
                            // setpendingstatusid(student.id)
                          }}
                          className="btn btn-success button_blue"
                        >
                          Pending
                        </button>
                      ) : (
                        <button
                          onClick={() => openaddmissionmodal(student)}
                          className="btn btn-success button_blue"
                        >
                          Admission
                        </button>
                      )}
                    </td>
                    {/* <td>
                      <a
                        // className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm "
                        onClick={() => deletestudent(student)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="row justify-content-center">
            {stuloading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
        </div>

        {/* <AddStudent onClose={handlemodelclose} show={modal}/> */}

        <Modal show={pendingstatus}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to change student status
            </Modal.Title>
          </Modal.Header>
          {pendingstatusload ? (
            <div>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}></div>
              <button
                // onClick={studentStatusChange(pendingstatusid)}
                onClick={() => {
                  studentStatusChange(pendingstatusid);
                }}
                className="btn button_blue"
              >
                Done
              </button>
              <button
                onClick={() => setpendingstatus(false)}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          )}
        </Modal>

        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete Student
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "center" }}></div>
            <button onClick={DeleteCampus} className="btn button_blue">
              Delete
            </button>
            <button
              onClick={() => setdeleteModal(false)}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={defineMonthlyfeeModal} size="xl">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Define Fee - {currentObject.name} {currentObject.admission_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DefineMonthlyFee
              studentdetail={currentObject}
              show={() => setdefineMonthlyfeeModal()}
            />
          </Modal.Body>
        </Modal>
        <Modal onHide={() => setShowModal(false)} size="xl" show={showModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              {selectedStudent.name} {selectedStudent.admission_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewStudent id={viewSelectedId} />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger btn_width"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={admitmodal}
        >
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Admit Student Card
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <StudentCard onClose={setadmitmodal} student={selectedStudent} />
          </Modal.Body>
        </Modal>

        <Modal show={addmissionmodal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Addmission Modal
            </Modal.Title>
          </Modal.Header>

          {statuschangeloading ? (
            <b style={{ color: "green", padding: "15px" }}>
              Changing Student Status Please Wait{" "}
              <span className="spinner-borderd"></span>
            </b>
          ) : (
            <>
              <Modal.Body>
                <div className="row justify-content-between text-left">
                  {/* <div className="generate text-center">Addmission</div> */}
                  <div className="form-group col-sm-12 flex-column d-flex">
                    <label>
                      <b>Student Name</b>
                    </label>
                    <input
                      style={{ backgroundColor: "#F3F6F9" }}
                      disabled
                      defaultValue={addmissionobject.name}
                      type="text"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group col-sm-12 flex-column d-flex">
                    <label>
                      <b>Father Name</b>
                    </label>
                    <input
                      disabled
                      defaultValue={addmissionobject.father_name}
                      style={{ backgroundColor: "#F3F6F9" }}
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-sm-6 flex-column d-flex pt-3">
                    <label className="form-control-label">
                      <b>Due Date</b>
                    </label>
                    <input
                      onChange={(e) => {
                        setduedate(e.target.value);
                      }}
                      style={{}}
                      type="date"
                      max="9999-11-30"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-sm-6 flex-column d-flex pt-3">
                    <label className="form-control-label">
                      <b>Fee Month</b>
                    </label>
                    <input
                      onChange={(e) => {
                        setfeemonth(e.target.value);
                      }}
                      style={{}}
                      type="month"
                      max="9999-11"
                      className="form-control"
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={submitFee}
                  className="btn btn-success button_blue"
                >
                  Admission
                </button>
                <button
                  onClick={() => setaddmissionmodal(false)}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </Modal.Footer>
            </>
          )}
        </Modal>

        <Modal show={feegeneratemodal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Fee Generate
            </Modal.Title>
          </Modal.Header>

          {feegenerateloading ? (
            <b style={{ color: "green", padding: "15px" }}>
              Generating Student Challan Please Wait{" "}
              <span className="spinner-borderd"></span>
            </b>
          ) : (
            <>
              <Modal.Body>
                <div className="row d-flex m-10">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                    {/* form start from here */}
                    {/* <div className="generate text-center">Generate Addmission Fee</div> */}
                    {/* <h3 className="generate">Generate Student Fee</h3> */}
                    <form className="form-card">
                      <div className="row">
                        <div className="form-group col-sm-12">
                          <div className="row">
                            <div className="col-sm-6">
                              <label>
                                <b>
                                  Student Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </b>
                              </label>
                              <input
                                style={{ backgroundColor: "#F3F6F9" }}
                                disabled
                                defaultValue={addmissionobject.name}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            {/* <div className="col-sm-6">

                                <label>
                                  <b>Registration ID <span style={{ color: "red" }}>*</span></b>
                                </label>
                                <input style={{ backgroundColor: '#F3F6F9' }} disabled defaultValue={addmissionobject.registration_id} type="text" className="form-control" />
                              </div> */}
                          </div>

                          <div className="row mt-5">
                            <div className="col-sm-12">
                              <label>
                                <b>
                                  Father Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </b>
                              </label>
                              <input
                                disabled
                                defaultValue={addmissionobject.father_name}
                                style={{ backgroundColor: "#F3F6F9" }}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-sm-6 flex-column d-flex pt-3">
                          <label className="form-control-label">
                            <b>
                              Select Fee type{" "}
                              <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <select
                            className="form-select"
                            style={{ backgroundColor: "#F3F6F9" }}
                            aria-label="Default select example"
                          >
                            <option selected>ADMISSION FEE</option>
                          </select>
                        </div>
                        <div className="form-group col-sm-6 flex-column d-flex pt-3">
                          <label className="form-control-label">
                            <b>
                              Fee Amount <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <input disabled value={feetypeamount} />
                        </div>
                      </div>

                      <div>
                        <h5>Fee Detail</h5>
                      </div>
                      <div className="row justify-content-between text-left">
                        <div className="form-group col-sm-6 flex-column d-flex">
                          <label className="form-control-label">
                            <b>
                              Due Date <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <input
                            format="DD/MM/YYYY"
                            max="9999-11-30"
                            onChange={(e) => {
                              setdue_date(e.target.value);
                            }}
                            type="date"
                            className="form-control  mb-2"
                          />

                          <label className="form-control-label">
                            <b>
                              Issue Date <span style={{ color: "red" }}>*</span>
                            </b>
                          </label>
                          <input
                            format="DD/MM/YYYY"
                            max="9999-11-30"
                            onChange={(e) => {
                              setissue_date(e.target.value);
                            }}
                            type="date"
                            className="form-control  mb-2"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  {isalldirectenablefields ? (
                    <>
                      <Alert
                        variant="danger"
                        onClose={() => setisalldirectenablefields(false)}
                        dismissible
                      >
                        <Alert.Heading>
                          Oh snap! You got an error!
                        </Alert.Heading>
                        <p>Please Enter Date</p>
                      </Alert>
                    </>
                  ) : null}
                </div>
              </Modal.Body>
              <Modal.Footer>
                {admiloading ? (
                  <div style={{ padding: "15px" }}>
                    <span className="ml-3 spinner spinner-white"></span>{" "}
                  </div>
                ) : (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      onClick={genrateFee}
                      style={{ width: "150px", height: "40px" }}
                      className="btn btn-success button_blue"
                    >
                      Generate
                    </button>

                    <button
                      onClick={() => setfeegeneratemodal(false)}
                      style={{
                        width: "150px",
                        height: "40px",
                        marginLeft: "15px",
                      }}
                      className="btn btn-danger btn_width"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </Modal.Footer>
            </>
          )}
        </Modal>
        <Modal size="xl" show={editModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              {console.log("current edit student", selectedEditStudent)}
              Reg Id: {selectedEditStudent.registration_id} &nbsp;&nbsp;{" "}
              {selectedEditStudent.name} &nbsp;&nbsp; Adm Id
              {selectedEditStudent.admission_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditStudent
              defConce={defaultCon}
              defaulthol={defaulthol}
              defaultVel={defaultVel}
              reloader={() => onSectionChange()}
              show={() => seteditModal()}
              reload={() => getAllStudents()}
              onUpdateStudent={getAllStudents}
              studentDetail={selectedEditStudent}
            />
          </Modal.Body>
        </Modal>
        {additionalfeemodal ? (
          <DefineAdditionalFee
            studentdetail={selectedStudent}
            show={additionalfeemodal}
            // show={true}
            proceed={"feegenerate"}
            onHide={setadditionalfeemodal}
            openfeemodal={setfeegeneratemodal}
          />
        ) : null}

        {/* <Pagination paginate={paginate} postsPerPage={postPerPage} totalPosts={dataSearch.length} /> */}
        <Pagination
          color="primary"
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Paper>
    </div>
  );
};

export default OnlyRegistered;
