import React, { useEffect, useState } from "react";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import DefineAdditionalFee from "../DefineFeeOnAddStudent";
import ReactSelect from "react-select";
import { baseUrl } from "../../../../services/config";
import { Link, useHistory } from "react-router-dom";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../../_metronic/_partials/controls";
import { useAlert } from "react-alert";
import CustomSelect from "./CustomSelect";
import { empty } from "object-path";
import { id } from "date-fns/locale";
import MaskedInput from "react-text-mask";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import PrintChallan from "./PrintChallan";
import { DatePicker, Space } from "antd";
import { Toaster, toast } from "react-hot-toast";
import ReciveFeeModal from "./ReciveFeeModal";

const { RangePicker } = DatePicker;
const cnicNumberMask = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
];

function AddStudent({ onClose, show }) {
  let today = new Date();
  today.setDate(today.getDate() + 2);
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  let tod = yyyy + "-" + mm + "-" + dd;

  let today1 = new Date();
  today1.setDate(today1.getDate());
  let dd1 = String(today1.getDate()).padStart(2, "0");
  let mm1 = String(today1.getMonth() + 1).padStart(2, "0");
  let yyyy1 = today1.getFullYear();
  let currentData = yyyy1 + "-" + mm1 + "-" + dd1;

  const getCurrentMonthYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1
    return `${year}-${month}`;
  };

  //new changes in student register form
  const [concessionId, setConcessionId] = useState(0);
  const [vehicalId, setVehicleId] = useState(0);
  const [hostelId, setHostelId] = useState(0);
  const user = useSelector((state) => state.user.user);
  const [empEnable, setempEnable] = useState(false);
  const [empList, setempList] = useState([]);

  const [studentfoefeegenerate, setstudentfoefeegenerate] = useState({});
  const [studentChallan, setStudentChallan] = useState({});
  const [defineadditionalfeeshow, setdefineadditionalfeeshow] = useState(false);
  const [allsessions, setallsessions] = useState([]);
  const [past6monthsChallans, setpast6monthsChallans] = useState([]);
  const [currentsession, setcurrentsession] = useState("");
  const [allowedconcession, setallowedconcession] = useState({});
  const [phonecountrycod, setphonecountrycod] = useState("");
  const [isalldirectenablefields, setisalldirectenablefields] = useState(false);
  const [allenablefields, setallenablefields] = useState("");
  const [
    isalladmissionfeeEnablefields,
    setisalladmissionfeeEnablefields,
  ] = useState(false);
  const [studentmodal, setstudentmodal] = useState(true);
  const [selectedStudent, setselectedStudent] = useState([]);
  const [selectedStudentId, setselectedStudentId] = useState(0);
  const [mobcountrycod, setmobcountrycod] = useState("");
  const history = useHistory();
  const alert = useAlert();
  const [isformsubmitted, setisformsubmitted] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [classIDs, setClassIDs] = useState([]);
  const [hostelIDs, setHostelIDs] = useState([]);
  const [vehicleIDs, setVehicleIDs] = useState([]);
  const [concession, setconcession] = useState([]);
  const [regisenable, setregisenable] = useState(true);
  const [courses, setcourses] = useState([]);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedcampus, setselectedcampus] = useState();
  const [directadmission, setdirectadmission] = useState("");
  const [directfeemodal, setdirectfeemodal] = useState(false);
  const [directstudentobject, setdirectstudentobject] = useState({});
  const [directfeeEnable, setdirectfeeEnable] = useState(false);
  const [admissionfeeEnable, setadmissionfeeEnable] = useState(false);
  const [due_date, setdue_date] = useState("");
  const [issue_date, setissue_date] = useState("");
  const [regloading, setregloading] = useState(false);
  const [admiloading, setadmiloading] = useState(false);
  let [feetypeamount, setfeetypeamount] = useState("");
  const [studentAddMessage, setstudentAddMessage] = useState(false);
  const [feechallansDetailList, setfeechallansDetailList] = useState([]);
  const [totalAmount, settotalAmount] = useState(0);
  const [paidchallans, setpaidchallans] = useState([]);
  let [gender, setgender] = useState("");
  let [isgender, setisgender] = useState(false);

  let [selectedcourse, setselectedcourse] = useState("");
  let [isselectedcourse, setisselectedcourse] = useState(false);

  let [anyconcesstion, setanyconcesstion] = useState("");

  let [anyvehicle, setanyvehicle] = useState("");

  let [anyhostel, setanyhostel] = useState("");

  let [anycampus, setanycampus] = useState("");
  let [isanycampus, setisanycampus] = useState(false);

  // const [loading, setloading] = useState(false)
  let [monthlyFeeInputsvalue, setmonthlyFeeInputsvalue] = useState([]);

  const [selectedclass, setselectedclass] = useState("");
  const [selectedsection, setselectedsection] = useState("");
  let [allsections, setallsections] = useState([]);
  let [isselectedclass, setisselectedclass] = useState(false);

  let [shiftselect, setshiftselect] = useState("");
  let [isshiftselect, setisshiftselect] = useState(false);
  let [printModel, setPrintModel] = useState(false);
  const [monthlyFeeConBtn, setmonthlyFeeConBtn] = useState(true);
  // console.log(user)

  let authtoken = localStorage.getItem("userToken");

  const [errorsLis, setErrorsLis] = useState("");
  let [errorcreate, seterrorcreate] = useState("Please fill all fields");
  let [isallfields, setisallfields] = useState(false);
  const [isallowedconcesstion, setisallowedconcesstion] = useState(true);
  const [inServiceEmpList, setinServiceEmpList] = useState([]);
  const [selectedsession, setselectedsession] = useState("");
  const [selectedEmpId, setselectedEmpId] = useState("");
  const [vehicalFeeAmount, setVehicalFeeAmount] = useState("");
  const [hostelFeeAmount, setHostelFeeAmount] = useState(0);
  const [concessionAmount, setConcessionAmount] = useState(0);
  const [monthlyFeeAmount, setmonthlyFeeAmount] = useState(0);
  const [MonAfterConAmount, setMonAfterConAmount] = useState(0);
  const [holAfterConAmount, setholAfterConAmount] = useState(0);
  const [holAdmAfterConAmount, setholAdmAfterConAmount] = useState(0);
  const [orignalHostelAdm, setHostelOrignalAdm] = useState(0);
  const [velAfterConAmount, setvelAfterConAmount] = useState(0);
  const [admissionFeeAmount, setadmissionFeeAmount] = useState(0);
  const [admAfterConAmount, setadmAfterConAmount] = useState(0);
  const [proAfterConAmount, setproAfterConAmount] = useState(0);
  const [proFee, setProFee] = useState(0);
  const [annualAfterConAmount, setannualAfterConAmount] = useState(0);
  const [annFee, setAnnFee] = useState(0);
  const [registrationFeeAmount, setregistrationFeeAmount] = useState(0);
  const [regestrationFee, setregestrationFee] = useState(0);
  const [feemontherror, setfeemontherror] = useState(false);
  const [selectedConcType, setselectedConcType] = useState("");
  const [selectedEductionType, setselectedEductionType] = useState(1);
  const [reciveFeeModal, setReciveFeeModal] = useState(false);
  const dispatch = useDispatch();
  const [indirectaddmissionmodal, setindirectaddmissionmodal] = useState(false);

  const [admissionFeeOnStart, setAdmissionFeeOnStart] = useState([]);
  const [receivableAmounts, setReceivableAmounts] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  const [receivableFee, setReceivableFee] = useState(0);
  const [receivableAmountError, setReceivableAmountError] = useState(false);

  useEffect(() => {
    // getClassID()
    getVehicleID();
    getHostelID();
    getconcession();
    getcourses();
    // getAllCampus();
    getEmpList();
    getAllSessions();
  }, []);

  const getEmpList = () => {
    console.log("geeting.....");
    fetch(`${baseUrl}/employees_all_campus`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({
        job_status_id: "1",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("emp list", data);
        if (data.metadata.success) {
          setinServiceEmpList(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "session data");
        if (data.metadata) {
          if (data.metadata.success) {
            setallsessions(data.payload);

            data.payload.map((session, index) => {
              if (session.active_academic_year == "1") {
                setselectedsession(session.id);
              }
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getClassID = async () => {

  // }

  const getHostelID = async () => {
    await fetch(`${baseUrl}/hostel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHostelIDs(data.payload);
      })
      .catch((err) => {
        console.log("hostel", err);
      });
  };

  const getVehicleID = async () => {
    await fetch(`${baseUrl}/vehicle`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setVehicleIDs(data.payload);
          } else {
            // alert.show("Error Occured While Fetching Courses ")
          }
        } else {
          // alert.show("Error Occured While Fetching Courses ")
        }
      })
      .catch((err) => {
        console.log("vehicle", err);
        // alert.show("Error Occured While Fetching Courses ")
      });
  };

  const getconcession = async () => {
    await fetch(`${baseUrl}/concession`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("consession", data.payload);
        setconcession(data.payload);
      })
      .catch((err) => {
        console.log("concession", err);
      });
  };

  const getcourses = async () => {
    await fetch(`${baseUrl}/courses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        // console.log(res,"res")
        res.json()
      )
      .then((data) => {
        console.log(data);
        setcourses(data.payload);
      })
      .catch((err) => {
        console.log("courses", err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          setallcampuses(data.payload);
        })
      )

      .catch((err) => {
        console.log("campus", err);
      });
  };

  const handleAmountChange = (index, event) => {
    const { value } = event.target;

    // Convert value to number using parseFloat or parseInt
    const parsedValue = parseFloat(value); // or parseInt(value, 10) for integer

    // Create a new array with updated value
    const updatedReceivableAmounts = [...receivableAmounts];
    updatedReceivableAmounts[index] = {
      ...updatedReceivableAmounts[index],
      amount_after_concession: parsedValue,
    };

    let actualamount = updatedReceivableAmounts[index].amount;

    if (actualamount < parsedValue) {
      setReceivableAmountError(true);
      toast.error("Receiving Amount is Greater than Net Amount");
    } else {
      setReceivableAmountError(false);
    }

    let initialReceiveableFee = 0;
    updatedReceivableAmounts.map((item) => {
      initialReceiveableFee += parseInt(item.amount_after_concession);
    });
    setReceivableFee(initialReceiveableFee);

    // Update the state
    setReceivableAmounts(updatedReceivableAmounts);
    console.log(updatedReceivableAmounts, "Recviable Amount");
    console.log(initialReceiveableFee, "Net Fee Collect");
  };

  const getAdmissionFeeOnStart = (
    campusid,
    classid,
    selectedsession,
    anyconcesstion,
    anyhostel,
    anyvehicle,
    isfeemonth
  ) => {
    const data = {
      campus_id: campusid,
      student_class_id: classid,
      year_id: selectedsession,
      concession_id: anyconcesstion == "" ? "" : anyconcesstion,
      hostel_id: anyhostel == "" ? null : anyhostel,
      vehicle_id: anyvehicle == "" ? null : anyvehicle,
      is_direct_admission: isfeemonth,
    };

    console.log(data, "Fee On Start Data");

    fetch(`${baseUrl}/admissionFeesOnStart`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "Fee On Start");
        setAdmissionFeeOnStart(data.payload);

        if (data.payload && data.payload.length > 0) {
          const initialAmounts = data.payload.map((feeOnStart) => ({
            fee_type_id: feeOnStart.fee_type_id,
            amount: feeOnStart.net_amount,
            amount_after_concession: feeOnStart.amount,
          }));

          let initialNetFee = 0;
          data.payload.map((item) => {
            initialNetFee += parseInt(item.net_amount);
          });

          let initialTotalFee = 0;
          data.payload.map((item) => {
            initialTotalFee += parseInt(item.amount);
          });

          // Update the state variable with the filtered values

          console.log("total Fee Collect", initialTotalFee);

          setTotalFee(initialNetFee);
          setReceivableFee(initialTotalFee);
          setReceivableAmounts(initialAmounts);
        } else {
          setTotalFee(0);
          setReceivableFee(0);
        }
      })
      .catch((err) => {
        console.log("campuses", err);
      });
  };

  const getclasssections = (campusid, classid, educationType, type) => {
    if (type == "class") {
      if (classid == selectedclass || campusid == "" || classid == "") {
        return;
      }
    } else {
      if (educationType == selectedEductionType || campusid == "") {
        return;
      }
    }
    console.log("pakistan");
    setselectedclass(classid);
    setselectedEductionType(educationType);

    fetch(
      `${baseUrl}/class_section/${campusid}/${educationType}${
        classid ? "/" + classid : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("sections", data.payload);
        setallsections(data.payload);
      })
      .catch((err) => {
        console.log("campuses", err);
      });
  };

  const [loading, setLoading] = useState(false);
  console.log("this is year id", selectedsession);
  const initialValues = {
    name: "",
    father_name: "",
    dob: "",
    religion: "Islam",
    gender: "",
    course_id: "",
    campus_id: "",
    class_id: "",
    educationType: 1,
    section_id: "",
    // "concession_id": "",
    // "hostel_id": "",
    mobile_no: "",
    concesstion_remarks: "",
    address: "",
    remarks: "",
    previous_school: "",
    class_left: "",
    leaving_date: "",
    // "vehicle_id": "",
    shift: "1st",
    cnic_no: "",
    // "student_class_id": "",
    // "medium": "",
    // "course_id": "",
    father_cnic: "",
    phone: "",
    fee_months: yyyy + "-" + mm,
    isfeemonth: false,
    no_fee_admission: false,
    fee_due_date: tod,
    fee_issue_date: currentData,
    single_fee_month: getCurrentMonthYear(),
    year_id: selectedsession,
  };

  const selectBoxerrors = () => {
    if (gender == "") {
      setisgender(true);
      seterrorcreate("Please Enter Gender");
      setisallfields(true);
    } else if (selectedcourse == "") {
      setisgender(false);
      setisselectedcourse(true);
      seterrorcreate("Please Select  Course");
      setisallfields(true);
    } else if (anycampus == "") {
      setisgender(false);
      setisanycampus(true);
      seterrorcreate("Please Select  campus");
      setisallfields(true);
    } else if (selectedclass == "") {
      setisgender(false);
      setisselectedclass(true);
      seterrorcreate("Please Select  Class");
      setisallfields(true);
    } else if (shiftselect == "") {
      setisselectedclass(false);
      setisshiftselect(true);
      seterrorcreate("Please Select  Shift");
      setisallfields(true);
    } else {
      setisshiftselect(false);
      setisallfields(false);
    }
  };
  function selectBoxerrorsc() {
    if (gender == "") {
      setisgender(true);
    }
    if (selectedcourse == "") {
      setisselectedcourse(true);
    }
    if (anycampus == "") {
      setisanycampus(true);
    }
    if (selectedclass == "") {
      setisselectedclass(true);
    }
    if (shiftselect == "") {
      setisshiftselect(true);
    } else {
      setisallfields(false);
    }
  }

  const createEroors = (
    errors,
    touched,
    isSubmitting,
    values,
    setValues,
    setSubmitting
  ) => {
    setisallfields(false);

    if (!errors.class_id && touched.class_id) {
      getclasssections(
        values.campus_id,
        values.class_id,
        values.educationType,
        "class"
      );
    }

    if (!errors.educationType && touched.educationType) {
      getclasssections(
        values.campus_id,
        values.class_id,
        values.educationType,
        "type"
      );
    }
    if (!errors.campus_id && touched.campus_id) {
      onchangeCampus(values.campus_id);
    }

    if (errors.name && touched.name) {
      seterrorcreate(errors.name);
      setisallfields(true);
      return;
    }

    if (errors.father_name && touched.father_name) {
      seterrorcreate(errors.father_name);
      setisallfields(true);
      return;
    }
    if (errors.year_id && touched.year_id) {
      seterrorcreate(errors.year_id);
      setisallfields(true);
      return;
    }

    if (errors.religion && touched.religion) {
      seterrorcreate(errors.religion);
      setisallfields(true);
      return;
    }

    if (errors.dob && touched.dob) {
      seterrorcreate(errors.dob);
      setisallfields(true);
      return;
    }

    if (errors.gender && touched.gender) {
      seterrorcreate(errors.gender);
      setisallfields(true);
      return;
    }
    if (errors.course_id && touched.course_id) {
      seterrorcreate(errors.course_id);
      setisallfields(true);
      return;
    }
    if (errors.campus_id && touched.campus_id) {
      seterrorcreate(errors.campus_id);
      setisallfields(true);
      return;
    }

    if (errors.class_id && touched.class_id) {
      seterrorcreate(errors.class_id);
      setisallfields(true);
      return;
    }
    if (errors.educationType && touched.educationType) {
      seterrorcreate(errors.educationType);
      setisallfields(true);
      return;
    }

    if (errors.section_id && touched.section_id) {
      seterrorcreate(errors.section_id);
      setisallfields(true);
      return;
    }

    if (errors.address && touched.address) {
      seterrorcreate(errors.address);
      setisallfields(true);
      return;
    }
    // if (errors.leaving_date && touched.leaving_date) {
    //   seterrorcreate(errors.leaving_date)
    //   setisallfields(true)
    //   return;
    // }
    // if (errors.shift && touched.shift) {
    //   seterrorcreate(errors.shift)
    //   setisallfields(true)
    //   return;
    // }

    // if (errors.remarks && touched.remarks) {
    //   seterrorcreate(errors.remarks)
    //   setisallfields(true)
    //   return;
    // }

    if (errors.previous_school && touched.previous_school) {
      seterrorcreate(errors.previous_school);
      setisallfields(true);
      return;
    }
    // if (errors.class_left && touched.class_left) {
    //   seterrorcreate(errors.class_left)
    //   setisallfields(true)
    //   return;
    // }

    if (errors.cnic_no && touched.cnic_no) {
      seterrorcreate(errors.cnic_no);
      setisallfields(true);
      return;
    }
    if (errors.father_cnic && touched.father_cnic) {
      seterrorcreate(errors.father_cnic);
      setisallfields(true);
      return;
    }
    if (errors.mobile_no && touched.mobile_no) {
      seterrorcreate(errors.mobile_no);
      setisallfields(true);
      return;
    }
    if (errors.concesstion_remarks && touched.concesstion_remarks) {
      seterrorcreate(errors.concesstion_remarks);
      setisallfields(true);
      return;
    }

    if (errors.phone && touched.phone) {
      seterrorcreate(errors.phone);
      setisallfields(true);
      return;
    }
    // if (feemontherror) {
    //   seterrorcreate("Fee Month is required");
    //   setisallfields(true);
    //   return;
    // }
    if (errors.fee_due_date && touched.fee_due_date) {
      seterrorcreate(errors.fee_due_date);
      setisallfields(true);
      return;
    }

    if (errors.fee_issue_date && touched.fee_issue_date) {
      seterrorcreate(errors.fee_issue_date);
      setisallfields(true);
      return;
    }

    if (errors.single_fee_month && touched.single_fee_month) {
      seterrorcreate(errors.single_fee_month);
      setisallfields(true);
      return;
    }

    if (Object.keys(errors).length === 0) {
      if (values.name == "") {
      } else {
        if (isSubmitting) {
          setSubmitting(false);
          console.log(isformsubmitted);
          if (!isformsubmitted) {
            setisformsubmitted(true);
            AddStudentHandler(values, setValues);
            setValues(values);
          }
        }
      }

      //   if (isSubmitting) {
      //     console.log(isformsubmitted)
      //     if (!isformsubmitted) {
      //       setisformsubmitted(true)
      //  //    AddStudentHandler(values)
      //     }
    }
  };

  const StudentRegistrationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),

    father_name: Yup.string().required("Father name is required"),
    // year_id: Yup.string().required("Session is required"),

    dob: Yup.string().notRequired("Date Of Birth required field"),

    religion: Yup.string().required("Religion is required field"),
    gender: Yup.string().required("Gender Is Required"),
    // concession_id: Yup.number()
    //   .notRequired('field must be a number'),

    // hostel_id: Yup.number()
    //   .notRequired('field must be a number'),

    mobile_no: Yup.string()
      .required("Mobile Number required field")
      .matches(
        /^(3)([0-9]{9})$/,
        "Mobile Number  Must start with 3 and must have 13 digits"
      ),

    // address: Yup.string().required("Address Is Required"),

    remarks: Yup.string().notRequired("Remarks required field"),

    previous_school: Yup.string().notRequired("last School is required field"),

    class_left: Yup.string().notRequired("Class Left required field"),

    leaving_date: Yup.string().notRequired("Leaving Date required field"),

    // vehicle_id: Yup.number()
    //   .notRequired('required field'),

    shift: Yup.string().required("Shift is required!"),

    cnic_no: Yup.string()
      .notRequired("Cnic No required field")
      .matches(
        /^[0-9]{5}-[0-9]{7}-[0-9]$/,
        "CNIC No must follow the XXXXX-XXXXXXX-X format!"
      ),

    class_id: Yup.string().required("Class Is Required"),
    educationType: Yup.string().required("Education Type Is Required"),

    // // medium:Yup.string()
    // // .ensure()
    // // .required(" required!"),

    course_id: Yup.string().notRequired("Course Is required"),

    campus_id: Yup.string().required("Campus Is required"),
    section_id: Yup.string().required("Section Is required"),

    father_cnic: Yup.string()
      .required("Father Cnic required field")
      // .notRequired("Father Cnic required field")
      .matches(
        /^[0-9]{5}-[0-9]{7}-[0-9]$/,
        "CNIC No must follow the XXXXX-XXXXXXX-X format!"
      ),

    phone: Yup.string()
      .notRequired("phone required field")
      .matches(
        /^(3)([0-9]{9})$/,
        "landline Number  Must start with 3 and must have 13 digits"
      ),
    isfeemonth: Yup.boolean(),
    // fee_months: Yup.string().when("isfeemonth", {
    //   is: true,
    //   then: Yup.string().required("Fee month required"),
    // }),
    fee_due_date: Yup.string().when("isfeemonth", {
      is: true,
      then: Yup.string().required("Due Date required"),
    }),

    fee_issue_date: Yup.string().when("isfeemonth", {
      is: false,
      then: Yup.string().required("Issue Date required"),
    }),

    single_fee_month: Yup.string().when("isfeemonth", {
      is: false,
      then: Yup.string().required("Fee Month required"),
    }),

    concesstion_remarks:
      anyconcesstion != ""
        ? Yup.string().required("Concession Remarks is required")
        : Yup.string()
            .notRequired()
            .nullable(),
    // otherwise: Yup.string().notRequired()

    // applied_for: Yup.string()
    //   .required('required field'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const onchangeCampus = (id) => {
    if (id == selectedcampus || id == "") {
      return;
    }
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });

    setallsections([]);
    setClassIDs([]);
    setselectedcampus(id);
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setClassIDs(data.payload);
        console.log("classid", data.payload);
      })
      .catch((err) => {
        console.log(err);
        console.log("vehicle", err);
      });
  };

  const AddStudentHandler = async (values, setValues) => {
    console.log(anyconcesstion, "anyConssion");
    if (values.isfeemonth) {
      if (monthlyFeeInputsvalue.length == 0) {
        setfeemontherror(true);
        setisformsubmitted(false);
        return;
      }
      if (MonAfterConAmount != monthlyFeeAmount) {
        if (anyconcesstion == "") {
          toast.error("Concession Field is Required");
          setisformsubmitted(false);
          return;
        }
      }
    }

    setLoading(true);
    console.log(selectedsession);

    setcurrentsession(selectedsession);

    const fdata = {
      name: values.name,
      father_name: values.father_name,
      religion: values.religion,
      gender: values.gender,
      campus_id: values.campus_id,
      education_type: values.educationType,

      address: values.address,
      remarks: values.remarks,
      previous_school: values.previous_school,
      class_left: values.class_left,
      shift: values.shift,
      student_class_id: values.class_id,
      global_section_id: values.section_id,

      course_id: values.course_id,
      status: values.isfeemonth ? 2 : 1,

      is_direct_admission: values.isfeemonth ? 2 : 1,
      no_fee_admission: values.no_fee_admission ? 1 : 0,

      leaving_date: values.leaving_date,
      dob: values.dob,

      cnic_no: values.cnic_no,
      father_cnic: values.father_cnic,

      concession_id: anyconcesstion,
      hostel_id: anyhostel,
      mobile_no: "92" + values.mobile_no,
      phone: "92" + values.phone,

      vehicle_id: anyvehicle,
      admission_id: 123,

      fee_months: values.isfeemonth
        ? monthlyFeeInputsvalue
        : convertToArray(values.single_fee_month + "-01"),
      due_date: values.fee_due_date,
      issue_date: values.isfeemonth ? null : values.fee_issue_date,

      employee_id: selectedEmpId,
      year_id: selectedsession,
      fee_amounts: receivableAmounts,
      concession_remarks:
        anyconcesstion != "" ? values.concesstion_remarks : null,
    };

    console.log(fdata, "kkkkkk");

    await fetch(`${baseUrl}/student`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(fdata),
    })
      .then((res) => res.json())
      .then((data) => {
        setValues(values);
        console.log(data);
        setdirectfeeEnable(false);
        setdirectfeemodal(false);
        setLoading(false);
        if (data.metadata) {
          if (data.metadata.success) {
            setstudentAddMessage(true);
            setstudentmodal(false);
            searchStudent(data.payload.registration_id);

            if (values.isfeemonth) {
              if (data.payload.admission_id != null) {
                searchStudent(data.payload.registration_id);
                alert.show(
                  `Student Added Successfully and admission id is ${data.payload.admission_id}`,
                  { timeout: 0 }
                );
                indirectaddmissionmodal(false);
                setstudentAddMessage(false);
              } else {
                searchStudent(data.payload.registration_id);
                setPrintModel(true);
                alert.show(
                  `Student Added Successfully and registration id is ${data.payload.registration_id}`,
                  { timeout: 0 }
                );
              }
            }

            setregisenable(false);
            setisformsubmitted(false);
            if (!values.isfeemonth) {
              // searchStudent(data.payload.registration_id);
              // setindirectaddmissionmodal(true);
              // getRespectivefee(
              //   data.payload.campus_id,
              //   data.payload.student_class_id,
              //   selectedsession
              // );

              searchStudent(data.payload.registration_id);
              setPrintModel(true);
              alert.show(
                `Student Added Successfully and registration id is ${data.payload.registration_id}`,
                { timeout: 0 }
              );
            }

            setstudentfoefeegenerate(data.payload);
            setdirectstudentobject(data.payload);
            alert.show("Student Added successfully");
            // if (onClose == undefined) {
            //   history.push('/student')
            // } else {

            //   onClose()
            // }
            // getRespectivefee(values.campus_id, values.class_id);

            // if (data.metadata.success) {

            //   if (directadmission == 'on') {
            //     setLoading(false)
            //     setregisenable(false)

            //     setisformsubmitted(false)
            //   } else {

            //     setLoading(false)
            //     setadmissionfeeEnable(true)
            //     setregisenable(false)
            //     setdirectfeeEnable(false)
            //     setisformsubmitted(false)
            //     //history.push('/student')
            //   }

            // }
          } else {
            // alert.show("Something Went Wrong 1");
            setLoading(false);

            if (onClose == undefined) {
              history.push("/student");
            } else {
              onClose();
            }
            setisformsubmitted(false);
          }
        } else {
          // alert.show("Something Went Wrong 2");
          setLoading(false);
          if (onClose == undefined) {
            history.push("/student");
          } else {
            onClose();
          }
          setisformsubmitted(false);
        }
      })
      .catch((err) => {
        // alert.show("Something Went Wrong 3");
        setLoading(false);
        setisformsubmitted(false);
        if (onClose == undefined) {
          history.push("/student");
        } else {
          onClose();
        }
      });
  };

  function convertToArray(singleFeeMonth) {
    if (Array.isArray(singleFeeMonth)) {
      return singleFeeMonth;
    }
    try {
      return JSON.parse(singleFeeMonth);
    } catch (e) {
      return singleFeeMonth.split(",").map((item) => item.trim());
    }
  }

  const directstudentStatusChange = (id) => {
    console.log(id, "id");
    const data = {
      student_id: id,
      status: 2,
    };
    console.log("change status");

    fetch(`${baseUrl}/studentstatus_change`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log(" status changed");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const genrateRegisterFee = () => {
    let res = isdirectaddmissionenablefields();
    if (!res) {
      return;
    }
    setregloading(true);

    if (due_date.length != 0) {
      if (issue_date.length != 0) {
        console.log(due_date, issue_date);
        let fees_type_id = [];
        let amount = [];
        amount[0] = feetypeamount;
        fees_type_id[0] = 2;

        const data = {
          monthly_fee_status: 0,
          additional_fee_status: 1,
          fees_type_id,
          amount,
          fee_months: [],
          due_date,
          issue_date,
        };
        // student_id: directstudentobject.id,
        console.log(data);

        fetch(`${baseUrl}/custom_fees_generate/${directstudentobject.id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authtoken}`,
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            res.json().then((data) => {
              console.log(data);
              setregloading(false);
              setdue_date("");
              setissue_date("");

              if (data.metadata) {
                if (data.metadata.success) {
                  setindirectaddmissionmodal(false);
                  setregloading(false);
                  alert.show("Fee generated successfully");
                  // history.push("/student");
                  setPrintModel(true);
                  onClose();
                } else {
                  setregloading(false);

                  alert.show(data.message);
                }
              } else {
                setregloading(false);

                alert.show(data.message);
              }
            });
          })
          .catch((err) => {
            console.log(err);
            setdue_date("");
            setissue_date("");
            setregloading(false);
            setdirectfeemodal(false);
            alert.show(err);
          });
      } else {
        setregloading(false);

        alert.show("Please enter due date");
      }
    } else {
      setregloading(false);
      alert.show("Please enter due date");
    }
  };

  const Genderoptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  const shiftoption = [
    { value: "1st", label: "1st" },
    { value: "2nd", label: "2nd" },
  ];

  const coursesoptions = [];
  courses.map((item, index) => {
    coursesoptions[index] = { value: item.id, label: item.name };
  });

  const concessionoption = [];
  concessionoption[0] = { value: "", label: "Select ..." };
  concession.map((item, index) => {
    concessionoption[index + 1] = { value: item.id, label: item.title };
  });

  const empOptions = [];
  empOptions[0] = { value: "", label: "Select ..." };
  inServiceEmpList.map((item, index) => {
    empOptions[index + 1] = {
      value: item.id,
      label: `${
        item.full_name.length > 15
          ? item.full_name.substr(0, 10)
          : item.full_name
      }-${item.emp_code}`,
    };
  });

  const vehicleoption = [];
  vehicleoption[0] = { value: "", label: "Select ..." };
  vehicleIDs.map((item, index) => {
    vehicleoption[index + 1] = { value: item.id, label: item.model };
  });

  const hosteloptions = [];
  hosteloptions[0] = { value: "", label: "Select ..." };
  hostelIDs.map((item, index) => {
    hosteloptions[index + 1] = { value: item.id, label: item.name };
  });

  const campusoption = [];
  campuses.map((item, index) => {
    campusoption[index] = { value: item.id, label: item.name };
  });

  const classoptions = [];
  classIDs.map((item, index) => {
    classoptions[index] = { value: item.id, label: item.name };
  });

  const sectionoptions = [];
  allsections.map((item, index) => {
    sectionoptions[index] = { value: item.id, label: item.name };
  });

  function isdirectenablefields() {
    console.log("isdirectenablefields");

    if (due_date == "") {
      setisalldirectenablefields(true);
      setallenablefields("Please add due date");
      return false;
    }
    if (issue_date == "") {
      setisalldirectenablefields(true);
      setallenablefields("Please add issue date");
      return false;
    }
    return true;
  }

  function isdirectaddmissionenablefields() {
    console.log(due_date);
    console.log(issue_date);
    if (due_date == "") {
      console.log(due_date);
      setisalladmissionfeeEnablefields(true);
      setallenablefields("Please add due date");
      return false;
    }
    if (issue_date == "") {
      console.log(issue_date);
      setisalladmissionfeeEnablefields(true);
      setallenablefields("Please add issue date");
      return false;
    }
    return true;
  }

  const onChangeGetEmployeList = (event) => {
    console.log("event", event);
    setselectedEmpId(event.value);
  };

  const handlePrintOff = () => {
    setPrintModel(false);
    history.push("/student");
  };

  /////here we go

  const searchStudent = (id) => {
    const data = {
      admission_id: "",
      registration_id: id,
    };

    fetch(`${baseUrl}/student_chllan_by_admission_id`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("this is whole" + data);
        if (data.metadata.success) {
          console.log("student fee challan", data);
          setselectedStudent(data.payload.students);
          setselectedStudentId(data.payload.students[0]?.id);
        } else {
        }
      })
      .catch((err) => {
        console.log("error in searchstudent", err);
      });
  };

  const handleConcession = (evt) => {
    setanyconcesstion(evt.value);
  };

  const handleChanger = (day, date) => {
    setfeemontherror(false);
    let startDate = date[0] + "-01";
    let endDate = date[1] + "-01";
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    setmonthlyFeeInputsvalue(dates);
    //  console.log(dates)
  };

  return (
    <>
      <Modal
        size="xl"
        show={show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Toaster />
        {studentAddMessage ? (
          <>
            <Alert
              variant="success"
              onClose={() => setstudentAddMessage(false)}
              dismissible
            >
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "2rem",
                }}
              >
                Student Added Successfully and registration id is{" "}
                {studentfoefeegenerate.registration_id}{" "}
              </p>
            </Alert>
          </>
        ) : null}
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            {indirectaddmissionmodal ? <>Define Fee </> : <>Add new Student</>}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {studentmodal ? (
            <div className="">
              <div className="">
                {/* <div className="generate text-center">Enter Student Detail and Submit</div> */}
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="login-form login-signin"
                        style={{ display: "block" }}
                      >
                        {loading ? (
                          <b style={{ color: "green" }}>
                            {" "}
                            Adding Please Wait {` `}{" "}
                            <span className="spinner-border"></span>
                          </b>
                        ) : (
                          <Formik
                            enableReinitialize={false}
                            initialValues={initialValues}
                            validationSchema={StudentRegistrationSchema}
                            onSubmit={(values) => {
                              //  AddStudentHandler(values)
                            }}
                          >
                            {(props) => {
                              const {
                                handleSubmit,
                                errors,
                                touched,
                                values,
                                setSubmitting,
                                isSubmitting,
                                isValid,
                                setValues,
                                handleChange,
                                handleBlur,
                              } = props;

                              setErrorsLis(errors);
                              createEroors(
                                errors,
                                touched,
                                isSubmitting,
                                values,
                                setValues,
                                setSubmitting
                              );

                              return (
                                <>
                                  <Form
                                    onSubmit={handleSubmit}
                                    className="form form-label-right"
                                  >
                                    <div class="switch">
                                      <label
                                        style={{
                                          display: "flex",
                                          flexDirection: "row-reverse",
                                        }}
                                      >
                                        <span style={{ paddingTop: "10px" }}>
                                          {" "}
                                          Direct Admission
                                        </span>

                                        <Field
                                          name="isfeemonth"
                                          component={Input}
                                          type="checkbox"
                                        />
                                        {/* <Field
                                          name="isfeemonth"
                                          component={Input}
                                          render={({ field }) => (
                                            <input
                                              {...field}
                                              type="checkbox"
                                              checked={isDirectAdm}
                                              onChange={(e) =>
                                                setIsDirectAdm(e.target.checked)
                                              }
                                            />
                                          )}
                                        /> */}
                                        <span class="lever"></span>
                                      </label>
                                    </div>

                                    <div className="form-group row">
                                      <div className="col-lg-4">
                                        <label>
                                          Name{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>

                                        <Field
                                          name="name"
                                          component={Input}
                                          placeholder="First Name"
                                          maxLength={120}
                                        />
                                      </div>
                                      <div className="col-lg-4">
                                        <label>
                                          Father Name{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          maxLength={120}
                                          name="father_name"
                                          component={Input}
                                          placeholder="Enter Father Name"
                                        />
                                      </div>
                                      <div className="col-lg-4">
                                        <label>
                                          Select Session{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <select
                                          class="form-select form-control"
                                          name="year_id"
                                          // defaultValue={selectedsession}
                                          value={selectedsession}
                                          onChange={(e) =>
                                            setselectedsession(e.target.value)
                                          }
                                          style={{ backgroundColor: "white" }}
                                          aria-label="Default select example"
                                        >
                                          <option selected value="">
                                            Select Session
                                          </option>
                                          {allsessions.map((item) => (
                                            <option
                                              // selected={
                                              //   item.active_academic_year == "1"
                                              //     ? true
                                              //     : false
                                              // }
                                              value={item.id}
                                            >
                                              {item.year}
                                            </option>
                                          ))}
                                        </select>
                                        {errors.year_id && touched.year_id && (
                                          <div
                                            style={{ color: "#f87d8a" }}
                                            className="input-feedback"
                                          >
                                            {errors.year_id}
                                          </div>
                                        )}
                                      </div>

                                      <div className="form-group row">
                                        <div className="col-lg-3">
                                          <label>
                                            Select Campus{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                          <select
                                            class="form-select form-control"
                                            name="campus_id"
                                            value={values.campus_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ backgroundColor: "white" }}
                                            aria-label="Default select example"
                                          >
                                            <option selected value="">
                                              Select Campus
                                            </option>
                                            {campuses.map((item) => (
                                              <option value={item.id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </select>
                                          {errors.campus_id &&
                                            touched.campus_id && (
                                              <div
                                                style={{ color: "#f87d8a" }}
                                                className="input-feedback"
                                              >
                                                {errors.campus_id}
                                              </div>
                                            )}
                                        </div>
                                        <div className="col-lg-3">
                                          <label>
                                            Select Class{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>

                                          <select
                                            class="form-select form-control"
                                            name="class_id"
                                            value={values.class_id}
                                            onChange={(e) => {
                                              handleChange(e); // Call the handleChange from Formik

                                              getAdmissionFeeOnStart(
                                                values.campus_id,
                                                e.target.value,
                                                selectedsession,
                                                anyconcesstion,
                                                anyhostel,
                                                anyvehicle,
                                                values.isfeemonth
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            style={{ backgroundColor: "white" }}
                                            aria-label="Default select example"
                                          >
                                            <option selected value="">
                                              Select Class
                                            </option>
                                            {classIDs.map((item) => (
                                              <option value={item.id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </select>
                                          {errors.class_id && touched.class_id && (
                                            <div
                                              style={{ color: "#f87d8a" }}
                                              className="input-feedback"
                                            >
                                              {errors.class_id}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-lg-3">
                                          <label>
                                            Select Education Type
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>

                                          <select
                                            class="form-select form-control"
                                            name="educationType"
                                            value={values.educationType}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ backgroundColor: "white" }}
                                            aria-label="Default select example"
                                          >
                                            <option selected value={1}>
                                              Education
                                            </option>
                                            <option value={2}>Hifz</option>
                                          </select>
                                          {errors.educationType &&
                                            touched.educationType && (
                                              <div
                                                style={{ color: "#f87d8a" }}
                                                className="input-feedback"
                                              >
                                                {errors.educationType}
                                              </div>
                                            )}
                                        </div>
                                        <div className="col-lg-3">
                                          <label>
                                            Select Section{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                          <select
                                            class="form-select form-control"
                                            name="section_id"
                                            value={values.section_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ backgroundColor: "white" }}
                                            aria-label="Default select example"
                                          >
                                            <option selected value="">
                                              Select Section
                                            </option>
                                            {allsections.map((item) => (
                                              <option value={item.id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </select>
                                          {errors.section_id &&
                                            touched.section_id && (
                                              <div
                                                style={{ color: "#f87d8a" }}
                                                className="input-feedback"
                                              >
                                                {errors.section_id}
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                      <div className="form-group row">
                                        <div className="col-lg-4">
                                          <label>
                                            Religion{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>

                                          <Field
                                            name="religion"
                                            maxLength={120}
                                            component={Input}
                                            placeholder="Enter Religion"
                                          />
                                        </div>

                                        <div className="col-lg-4">
                                          <label>Date of Birth</label>
                                          <Field
                                            name="dob"
                                            type="date"
                                            max="9999-11-30"
                                            component={Input}
                                          />
                                        </div>

                                        <div className="col-lg-4">
                                          <label>
                                            Gender{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>

                                          <select
                                            class="form-select"
                                            name="gender"
                                            value={values.gender}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{
                                              backgroundColor: "white",
                                              height: 40,
                                            }}
                                            aria-label="Default select example"
                                          >
                                            <option selected value="">
                                              Select Gender
                                            </option>
                                            <option value="Male">Male</option>
                                            <option value="Female">
                                              Female
                                            </option>
                                            <option value="Other">Other</option>
                                          </select>
                                          {errors.gender && touched.gender && (
                                            <div
                                              style={{ color: "#f87d8a" }}
                                              className="input-feedback"
                                            >
                                              {errors.gender}
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="form-group row">
                                        <div className="col-lg-4">
                                          <label>Select Course </label>
                                          <select
                                            class="form-select form-control"
                                            name="course_id"
                                            value={values.course_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ backgroundColor: "white" }}
                                            aria-label="Default select example"
                                          >
                                            <option selected value="">
                                              Select Course
                                            </option>
                                            {courses.map((item) => (
                                              <option value={item.id}>
                                                {item.name}
                                              </option>
                                            ))}
                                          </select>
                                          {errors.course_id &&
                                            touched.course_id && (
                                              <div
                                                style={{ color: "#f87d8a" }}
                                                className="input-feedback"
                                              >
                                                {errors.course_id}
                                              </div>
                                            )}
                                        </div>

                                        <div className="col-lg-4">
                                          <label>Concession </label>

                                          <CustomSelect
                                            required
                                            className="input"
                                            onChange={(evt) => {
                                              handleConcession(evt);
                                              setanyconcesstion(evt.value);

                                              if (evt.value == "2") {
                                                setempEnable(true);
                                              } else {
                                                setempEnable(false);
                                              }

                                              getAdmissionFeeOnStart(
                                                values.campus_id,
                                                values.class_id,
                                                selectedsession,
                                                evt.value,
                                                anyhostel,
                                                anyvehicle,
                                                values.isfeemonth
                                              );
                                            }}
                                            placeholder="Select Concession"
                                            options={concessionoption}
                                          />
                                        </div>
                                        {anyconcesstion != "" && (
                                          <div className="col-lg-4">
                                            <label>
                                              Enter Concession Remarks{" "}
                                            </label>

                                            <Field
                                              name="concesstion_remarks"
                                              component={Input}
                                              placeholder="Concesstion Remarks"
                                              required
                                              type="text"
                                            />
                                          </div>
                                        )}
                                        <div className="col-lg-4">
                                          <label>Select Employee </label>
                                          <div className="input">
                                            <ReactSelect
                                              isDisabled={
                                                empEnable ? false : true
                                              }
                                              className="input"
                                              onChange={(evt) => {
                                                onChangeGetEmployeList(evt);
                                              }}
                                              placeholder="Select Employee"
                                              options={empOptions}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="form-group row">
                                        <div className="col-lg-4">
                                          <label>Hostel </label>
                                          <CustomSelect
                                            className="input"
                                            onChange={(e) => {
                                              setanyhostel(e.value);
                                              getAdmissionFeeOnStart(
                                                values.campus_id,
                                                values.class_id,
                                                selectedsession,
                                                anyconcesstion,
                                                e.value,
                                                anyvehicle,
                                                values.isfeemonth
                                              );
                                            }}
                                            placeholder="Select Hostel"
                                            options={hosteloptions}
                                          />
                                        </div>

                                        <div className="col-lg-4">
                                          <label>Enter Address </label>
                                          <Field
                                            name="address"
                                            component={Input}
                                            placeholder="Enter Your Address"
                                            maxLength={120}
                                            //  label="Address"
                                            // customFeedbackLabel='Enter Your Area Name City Street '
                                          />
                                        </div>

                                        <div className="col-lg-4">
                                          <label>Select Vehicle </label>
                                          <CustomSelect
                                            required
                                            className="input"
                                            onChange={(evt) => {
                                              setanyvehicle(evt.value);
                                              getAdmissionFeeOnStart(
                                                values.campus_id,
                                                values.class_id,
                                                selectedsession,
                                                anyconcesstion,
                                                anyhostel,
                                                evt.value,
                                                values.isfeemonth
                                              );
                                            }}
                                            placeholder="Select Vehicle"
                                            options={vehicleoption}
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group row">
                                        <div className="col-lg-4">
                                          <label>CNIC/B-Form </label>
                                          <Field
                                            name="cnic_no"
                                            render={({ field }) => (
                                              <MaskedInput
                                                {...field}
                                                mask={cnicNumberMask}
                                                id="cnic_no"
                                                placeholder="Enter Your CNIC / B-Form No"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                // className="is-invalid form-control"
                                                className={
                                                  errors.cnic_no &&
                                                  touched.cnic_no
                                                    ? "is-invalid form-control"
                                                    : "form-control"
                                                }
                                              />
                                            )}
                                          />
                                          {/* <Field
                                            render={({ field }) => (
                                              <MaskedInput
                                                {...field}
                                                name="cnic_no"
                                                mask={cnicNumberMask}
                                                id="phone"
                                                placeholder="Enter Your CNIC / B-Form No"
                                                type="text"
                                                // maxLength={15}
                                              
                                                // component={Input}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                              // className={
                                              //   errors.phone && touched.phone
                                              //     ? "text-input error"
                                              //     : "text-input"
                                              // }
                                              />
                                            )}
                                          //  placeholder="XXXXX-XXXXXXX-X"
                                          // label="CNIC/ B-Form"
                                          /> */}
                                        </div>
                                        <div className="col-lg-4">
                                          <label>Shift </label>

                                          <select
                                            class="form-select form-control"
                                            name="shift"
                                            value={values.shift}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{ backgroundColor: "white" }}
                                            aria-label="Default select example"
                                          >
                                            {/* <option selected value="">
                                            Select shift
                                          </option> */}
                                            {shiftoption.map((item) => (
                                              <option value={item.value}>
                                                {item.label}
                                              </option>
                                            ))}
                                          </select>
                                          {errors.shift && touched.shift && (
                                            <div
                                              style={{ color: "#f87d8a" }}
                                              className="input-feedback"
                                            >
                                              {errors.shift}
                                            </div>
                                          )}

                                          {/* <CustomSelect

                                          className='input'
                                          onChange={evt => {

                                            setshiftselect(evt.value)
                                            // if (shiftselect == "") {
                                            //   setisshiftselect(true)
                                            // } else {
                                            //   setisshiftselect(false)

                                            // }


                                          }}
                                          placeholder="Select shift"
                                          options={shiftoption}
                                        />
                                        {isshiftselect ? <div style={{
                                          color: "#F64E60", fontSize: "0.9rem",
                                          fontWeight: "400"
                                        }} className='error'>Shift is Required</div> : null} */}
                                        </div>

                                        <div className="col-lg-4">
                                          <label>Enter Father CNIC </label>
                                          <Field
                                            name="father_cnic"
                                            render={({ field }) => (
                                              <MaskedInput
                                                {...field}
                                                mask={cnicNumberMask}
                                                id="father_cnic"
                                                placeholder="Enter Your Father CNIC"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                // className="is-invalid form-control"
                                                className={
                                                  errors.father_cnic &&
                                                  touched.father_cnic
                                                    ? "is-invalid form-control"
                                                    : "form-control"
                                                }
                                              />
                                            )}
                                          />
                                          {/* <Field
                                            maxLength={15}
                                            name="father_cnic"
                                            // pattern={/^0[1-9]|[1-9]\d$/}
                                            required
                                            component={Input}
                                            type="text"
                                            placeholder="Enter Your Father CNIC"
                                          // label="Father CNIC"
                                          /> */}
                                        </div>
                                      </div>
                                      <div className="form-group row">
                                        <div className="col-lg-4">
                                          <label>Enter Remarks </label>

                                          <Field
                                            name="remarks"
                                            component={Input}
                                            maxLength={120}
                                            placeholder="remarks"
                                          />
                                        </div>
                                        <div className="col-lg-4">
                                          <label>
                                            Enter Your Landline Number{" "}
                                          </label>

                                          <Field
                                            maxLength={20}
                                            required
                                            name="phone"
                                            component={Input}
                                            // value={values.phone}
                                            // value={

                                            //   values.phonecode ?
                                            //     "+92" + values.mobile_no :
                                            //     values.mobile_no
                                            // }

                                            // onClick={() => {
                                            //   values.phonecode = true
                                            //   // if (values.phone == '') {
                                            //   //   // values.phone = '+92'
                                            //   //   setphonecountrycod("+92")

                                            //   //   console.log("pakistan1", values.phone)

                                            //   // }
                                            // }}

                                            type="text"
                                            placeholder="Enter Your Phone/LandLine No"
                                          />
                                        </div>
                                        <div className="col-lg-4">
                                          <label>
                                            Enter Your Mobile Phone Number{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            maxLength={20}
                                            required
                                            name="mobile_no"
                                            type="text"
                                            // onClick={() => {
                                            //   // if (values.mobile_no == '') {
                                            //   //   setmobcountrycod("+92")
                                            //   //   //   values.mobile_no = '+92'
                                            //   //   // console.log("pakistan",values.mobile_no)

                                            //   //  }

                                            // }}
                                            component={Input}
                                            placeholder="Enter Your Smart Phone No"
                                            // label="Mobile No"
                                          />
                                        </div>
                                      </div>

                                      {values.isfeemonth ? (
                                        <>
                                          <div className="generate text-center">
                                            Fee Details
                                          </div>
                                          <br />
                                          <br />

                                          <div className="row">
                                            <div className="col-md-3 text-center">
                                              <label
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Fee Name
                                              </label>
                                            </div>
                                            <div className="col-md-3 text-center">
                                              <label
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Amount
                                              </label>
                                            </div>
                                            <div className="form-group col-md-3 flex-column d-flex text-center">
                                              <label
                                                className="form-control-label"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Concession
                                              </label>
                                            </div>
                                            <div className="form-group col-md-3 flex-column d-flex text-center">
                                              <label
                                                className="form-control-label"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Receiving Amount
                                              </label>
                                            </div>
                                          </div>

                                          {/* All Fee Details with Input Field */}
                                          {admissionFeeOnStart &&
                                            admissionFeeOnStart.map(
                                              (feeOnStart, index) => (
                                                <div
                                                  className="row"
                                                  key={feeOnStart.fee_type_id}
                                                >
                                                  <div className="col-md-3">
                                                    <select
                                                      className="form-select form-control"
                                                      name="fee_type"
                                                      style={{
                                                        backgroundColor:
                                                          "white",
                                                      }}
                                                      aria-label="Default select example"
                                                      value={
                                                        feeOnStart.fee_type_id
                                                      }
                                                      disabled
                                                    >
                                                      <option
                                                        value={
                                                          feeOnStart.fee_type_id
                                                        }
                                                      >
                                                        {feeOnStart.fee_name}
                                                      </option>
                                                    </select>
                                                  </div>
                                                  <div className="col-md-3">
                                                    <input
                                                      className="form-control"
                                                      type="number"
                                                      value={
                                                        feeOnStart.net_amount
                                                      }
                                                      disabled
                                                    />
                                                  </div>
                                                  <div className="form-group col-md-3 flex-column d-flex">
                                                    <span
                                                      style={{
                                                        marginTop: "15px",
                                                      }}
                                                      disabled
                                                    >
                                                      Concession Applied{" "}
                                                      {feeOnStart?.concession_allowed !=
                                                      null
                                                        ? feeOnStart?.concession_allowed
                                                        : 0}
                                                    </span>
                                                  </div>
                                                  <div className="form-group col-md-3 flex-column d-flex">
                                                    <input
                                                      className="mt-3"
                                                      min={1}
                                                      value={
                                                        receivableAmounts[index]
                                                          ?.amount_after_concession ||
                                                        0
                                                      }
                                                      type="number"
                                                      onChange={(e) =>
                                                        handleAmountChange(
                                                          index,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}

                                          {/* Net Fee & Receiving Fee */}
                                          <div className="row">
                                            <div className="col-md-6 d-flex">
                                              <p
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Net Fee:-
                                              </p>
                                              <p
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  marginLeft: "30px",
                                                }}
                                              >
                                                {parseInt(totalFee)}
                                              </p>
                                            </div>

                                            <div className="col-md-6 d-flex">
                                              <p
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Receiving Fee:-
                                              </p>
                                              <p
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  marginLeft: "30px",
                                                }}
                                              >
                                                {parseInt(receivableFee)}
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}

                                      <div className="generate text-center mt-3">
                                        Previous School Detail
                                      </div>
                                      <br />
                                      <br />

                                      <div className="row">
                                        <div className="col-lg-4">
                                          <label>Class Left </label>
                                          <Field
                                            maxLength={120}
                                            name="class_left"
                                            component={Input}
                                            placeholder="Enter Student Class left"
                                            // label="Class Left"
                                          />
                                        </div>
                                        <div className="col-lg-4">
                                          <label>
                                            Previous School{" "}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>

                                          <Field
                                            maxLength={120}
                                            name="previous_school"
                                            component={Input}
                                            placeholder="Enter Student previous School"
                                          />
                                        </div>
                                        <div className="col-lg-4">
                                          <label>Enter leaving Date </label>
                                          <Field
                                            name="leaving_date"
                                            component={Input}
                                            type="date"
                                            max="9999-11-30"
                                            // customFeedbackLabel='Enter Last School Leave Date'
                                          />
                                        </div>
                                      </div>

                                      {values.isfeemonth ? (
                                        <div className="form-group row">
                                          <div className="col-lg-4">
                                            <label>Fee Months</label>

                                            <RangePicker
                                              getPopupContainer={(
                                                triggerNode
                                              ) => {
                                                return triggerNode.parentNode;
                                              }}
                                              style={{ height: "40px" }}
                                              picker="month"
                                              onChange={(day, date) =>
                                                handleChanger(day, date)
                                              }
                                            />
                                            {feemontherror ? (
                                              <label className="text-danger">
                                                Fee Month is required
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                          </div>

                                          <div className="col-lg-4">
                                            <label>Due Date</label>
                                            <Field
                                              name="fee_due_date"
                                              component={Input}
                                              type="date"
                                              max="9999-11-30"
                                              // customFeedbackLabel='Enter Last School Leave Date'
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="form-group row">
                                          <div className="col-lg-4">
                                            <label className="form-control-label">
                                              Due Date
                                            </label>

                                            <Field
                                              name="fee_due_date"
                                              component={Input}
                                              type="date"
                                              max="9999-11-30"
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <label className="form-control-label">
                                              Issue Date{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </label>

                                            <Field
                                              name="fee_issue_date"
                                              component={Input}
                                              type="date"
                                              max="9999-11-30"
                                            />
                                          </div>

                                          <div className="col-lg-4">
                                            <label className="form-control-label">
                                              Fee Month{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </label>

                                            <Field
                                              name="single_fee_month"
                                              component={Input}
                                              type="month"
                                              max="9999-11-30"
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </Form>

                                  {isallfields ? (
                                    <>
                                      <Alert
                                        variant="danger"
                                        onClose={() => setisallfields(false)}
                                        dismissible
                                      >
                                        <Alert.Heading>
                                          Oh snap! You got an error!
                                        </Alert.Heading>
                                        <p>{errorcreate}</p>
                                      </Alert>
                                    </>
                                  ) : null}

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {values.isfeemonth ? (
                                      <div class="mt-4 mr-4">
                                        <Field
                                          type="checkbox"
                                          name="no_fee_admission"
                                          id="no_fee_admission"
                                        />
                                        <label
                                          for="no_fee_admission"
                                          class="form-control-label"
                                        >
                                          <b>Admit Without Fee Receiving</b>
                                        </label>
                                      </div>
                                    ) : null}

                                    <button
                                      className="btn btn-success button_blue"
                                      disabled={receivableAmountError}
                                      onClick={() => handleSubmit()}
                                      type="submit"
                                      value="Submit"
                                    >
                                      {loading ? (
                                        <span className="spinner-border"></span>
                                      ) : (
                                        <>Submit</>
                                      )}
                                    </button>

                                    <button
                                      onClick={() => {
                                        setisanycampus(false);
                                        setisgender(false);
                                        setisselectedcourse(false);
                                        setisanycampus(false);
                                        setisselectedclass(false);
                                        setisshiftselect(false);
                                        setisallfields(false);
                                        // setfee_months('')
                                        if (onClose == undefined) {
                                          history.push("/student");
                                        } else {
                                          onClose();
                                        }

                                        // onClose()
                                      }}
                                      className="btn btn-danger btn_width ml-3"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </>
                              );
                            }}
                          </Formik>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {indirectaddmissionmodal ? (
            <>
              <div className="row d-flex m-10">
                <div></div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                  {/* form start from here */}
                  {/* <div className="generate text-center">Generate Registration Fee</div> */}
                  {/*            
                  <h3 className="generate">Generate Registration Fee</h3> */}
                  <form className="form-card">
                    <div className="row">
                      <div className="form-group col-sm-12">
                        <div className="row">
                          <div className="col-sm-6">
                            <label>
                              <b>
                                Student Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <input
                              style={{ backgroundColor: "#F3F6F9" }}
                              disabled
                              defaultValue={directstudentobject.name}
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="col-sm-6">
                            <label>
                              <b>
                                Registration ID{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <input
                              style={{ backgroundColor: "#F3F6F9" }}
                              disabled
                              defaultValue={directstudentobject.registration_id}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mt-5">
                          <div className="col-sm-12">
                            <label>
                              <b>
                                Father Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <input
                              disabled
                              defaultValue={directstudentobject.father_name}
                              style={{ backgroundColor: "#F3F6F9" }}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div class="form-group col-sm-6 flex-column d-flex">



                               </div> */}
                      <div className="form-group col-sm-6 flex-column d-flex pt-3">
                        <label className="form-control-label">
                          <b>
                            Select Fee type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <select
                          className="form-select form-control"
                          style={{ backgroundColor: "#F3F6F9" }}
                          aria-label="Default select example"
                        >
                          <option selected>REGISTRATION</option>
                        </select>
                      </div>

                      <div className="form-group col-sm-6 flex-column d-flex pt-3">
                        <label className="form-control-label">
                          <b>
                            Amount <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <input
                          disabled
                          defaultValue={feetypeamount}
                          style={{ backgroundColor: "#F3F6F9" }}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div>
                      <h5>Fee Detail </h5>
                    </div>
                    <div className="row justify-content-between text-left">
                      <div className="form-group col-sm-6 flex-column d-flex">
                        <label className="form-control-label">
                          <b>
                            Due Date <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <input
                          format="DD/MM/YYYY"
                          onChange={(e) => setdue_date(e.target.value)}
                          type="date"
                          max="9999-11-30"
                          className="form-control  mb-2"
                        />

                        <label className="form-control-label">
                          <b>
                            Issue Date <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <input
                          format="DD/MM/YYYY"
                          onChange={(e) => setissue_date(e.target.value)}
                          type="date"
                          max="9999-11-30"
                          className="form-control  mb-2"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                {isalladmissionfeeEnablefields ? (
                  <>
                    <Alert
                      variant="danger"
                      onClose={() => setisalladmissionfeeEnablefields(false)}
                      dismissible
                    >
                      <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                      <p>{allenablefields}</p>
                    </Alert>
                  </>
                ) : null}

                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  className=""
                >
                  <button
                    onClick={genrateRegisterFee}
                    className="btn btn-success button_blue "
                  >
                    Generate
                    {/* Generate Register Fee */}
                    {regloading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </button>

                  <button
                    onClick={() => {
                      setdeleteModal(true);
                    }}
                    // onClick={() => {
                    //   if (onClose == undefined) {
                    //     history.push('/student')
                    //   } else {

                    //     onClose()
                    //   }
                    // }
                    // }
                    style={{ marginLeft: "15px" }}
                    className="btn btn-danger btn_width "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          ) : null}
        </Modal.Body>

        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure you want to Cancel this?
            </Modal.Title>
          </Modal.Header>
          {/* 
        <Modal.Body>

          <> Are you sure you want to Cancel this?</>

        </Modal.Body> */}
          <Modal.Footer>
            <button
              onClick={() => {
                setdeleteModal(false);
                if (onClose == undefined) {
                  history.push("/student");
                } else {
                  onClose();
                }
              }}
              className="btn btn-success button_blue"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setdeleteModal(false);
                setdue_date("");
                setissue_date("");
                setindirectaddmissionmodal(false);
                setstudentmodal(true);
              }}
              className="btn btn-danger btn_width"
            >
              Back
            </button>
          </Modal.Footer>
        </Modal>

        {defineadditionalfeeshow ? (
          <DefineAdditionalFee
            studentdetail={studentfoefeegenerate}
            show={defineadditionalfeeshow}
            proceed={"proceed"}
            issue_date={issue_date}
            due_date={due_date}
          />
        ) : null}
      </Modal>

      {printModel ? (
        <PrintChallan
          show={printModel}
          close={handlePrintOff}
          selectedStudent={selectedStudent}
          selectedStudentId={selectedStudentId}
          // paidchallans={paidchallans}
          // feechallansDetailList={feechallansDetailList}
          // totalAmount={totalAmount}
        />
      ) : null}
    </>
  );
}

export default AddStudent;
