import React, { useState, useRef, useEffect } from "react";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import AdmissionSlip from "./AdmissionSlip";
import { useHistory } from "react-router-dom";
import MultiStudentFeeChallan from "../../MultiStudentFeeChallan/MultiStudentFeeChallan";
const PrintChallan = ({
  show,
  close,
  selectedStudent,
  selectedStudentId,
  // paidchallans,
  // feechallansDetailList,
  // totalAmount,
}) => {
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [dateData, setDateData] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [selectedbankcateogry, setselectedbankcateogry] = useState("");
  const [allbanks, setallbanks] = useState([]);
  const [wholeBankCat, setWholeBankCat] = useState([]);
  const [wholeBank, setWholeBank] = useState({});
  const [selectedbank, setselectedbank] = useState("");
  const [receiveLoading, setReceiveLoading] = useState(false);
  let authtoken = localStorage.getItem("userToken");

  const alert = useAlert();
  const history = useHistory();

  const [blankPage, setBlankPage] = useState(false);
  const pageStyle = `
  @page {
    size:letter landscape !important;
  }
  `;

  let [printAdmissionSlip, setPrintAdmissionSlip] = useState(false);
  const [studentdetails, setstudentdetails] = useState([]);

  const getMonthName2 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${d.getDate()}-${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };

  const getIssueDate = (arr) => {
    // let result = Math.max(...arr.map(o => o.challan_no))
    const result = arr?.reduce((p, c) => (p.challan_no > c.challan_no ? p : c));
    // console.log(result);
    return result;
  };

  useEffect(() => {
    getAllBanks();
  }, [selectedStudent]);

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          let anserr = data.payload.filter(
            (item) => item.id == selectedbankcateogry
          );

          if (anserr.length != 0) {
            setallsubaccounts([...anserr[0].bank_accounts]);
          } else {
            setallsubaccounts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const getMonthName3 = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(date);
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
  };

  const getFeeAmount = (challans) => {
    let sum = 0;

    challans.forEach((element) => {
      sum += parseInt(element.amount);
    });

    return sum;
    // const sum = challans.reduce((accumulator, object) => {
    //   return accumulator + object.amount;
    // }, 0);
    // console.log(sum)
  };
  console.log(selectedStudent, "student");

  const handleBankChange = (e) => {
    setselectedbank(e.target.value);
    const result = allsubaccounts.find((item) => item.id == e.target.value);
    console.log(result, "rang lagay");
    setWholeBank(result);
  };

  const submitFeeStudentWise = () => {
    if (selectedbank == "") {
      return alert.error("Please Select The Bank");
    }
    setReceiveLoading(true);
    const data = {
      received_date: dateData,
      bank_account_id: selectedbank,
      student_id: selectedStudentId,
      // re_admission_amount,
      // lateFine,
    };

    console.log(data);

    fetch(`${baseUrl}/student_chllan_by_admission_id_submit_for_slip`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setReceiveLoading(false);
        // console.log(data.payload, "student detial aye k ni");
        if (data.payload.admission_id) {
          console.log(data.payload, "student detial aye k ni");
          setstudentdetails(data.payload);
          alert.show(
            `student unpaid fees are collected and admission id is ${data.payload.admission_id}`,
            { timeout: 0 }
          );
          // close();
          setPrintAdmissionSlip(true);
        } else if (data.payload) {
          alert.show(
            `student unpaid fees are collected and admission id is ${data.payload.admission_id}`,
            { timeout: 0 }
          );
          close();
        } else {
          alert.show(`Something went wrong. Please try again later.`);
        }
      })
      .catch((err) => {
        setReceiveLoading(false);
        console.log(err);
      });
  };

  const handlePrintAdmSlipOff = () => {
    setPrintAdmissionSlip(false);
    history.push("/student");
  };

  const testCheck = () => {
    console.log(selectedStudent, "Hassan Check");
  };
  return (
    <>
      <Modal show={show}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Do you want to print the Challan or Receive Fee
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <label className="form-control-label">
                <b>
                  Select Date{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </b>
              </label>
              <input
                value={dateData}
                className="form-control"
                onChange={(e) => setDateData(e.target.value)}
                style={{}}
                type="date"
              />
            </div>
            <div className="col-md-12 mt-3">
              <label className="form-control-label">
                <b>
                  Select Bank Category{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </b>
              </label>
              <select
                className="form-select form-control"
                name="sub_account_id"
                value={selectedbankcateogry}
                onChange={(e) => {
                  setselectedbankcateogry(e.target.value);
                  let anserr = allbanks.filter(
                    (item) => item.id == e.target.value
                  );
                  setWholeBankCat(anserr);
                  if (anserr.length != 0) {
                    setallsubaccounts([...anserr[0].bank_accounts]);
                  } else {
                    setallsubaccounts([]);
                  }
                }}
                style={{ backgroundColor: "white" }}
                aria-label="Default select example"
              >
                <option acode="" selected value="">
                  Select...
                </option>
                {allbanks.map((item) => (
                  <option value={item.id}>{item.title}</option>
                ))}
              </select>
            </div>

            <div className="col-md-12 mt-3">
              <label className="form-control-label">
                <b>
                  Select Bank <span style={{ color: "red" }}>*</span>
                </b>
              </label>
              <select
                className="form-select form-control"
                name="sub_account_id"
                value={selectedbank}
                onChange={(e) => {
                  handleBankChange(e);
                }}
                style={{ backgroundColor: "white" }}
                aria-label="Default select example"
              >
                <option acode="" selected value="">
                  Select...
                </option>
                {allsubaccounts.map((item) => (
                  <option value={item.id}>
                    {item.bank_name}&nbsp;&nbsp;{`(${item.account_head})`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="mr-4">
            <label className="checkbox checkbox-single">
              <input
                type="checkbox"
                onChange={(e) => setBlankPage(e.target.checked)}
              />
              <span />
              &nbsp;&nbsp;&nbsp;Use Blank Page For Print
            </label>
          </div>
          <ReactToPrint
            documentTitle={`Fee Challan Print`}
            pageStyle={pageStyle}
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint={true}
            trigger={() => (
              <button
                // onClick={() => console.log("dfsdfsdfd")}
                disabled={printloading ? true : false}
                style={{ marginLeft: "10px" }}
                className="btn btn-success button_blue button_blue"
              >
                {printloading ? <span>Printing..</span> : "Print"}
              </button>
            )}
            content={() => componentRef.current}
          />

          {receiveLoading ? (
            <Spinner class="text-center" variant="info" animation="border" />
          ) : (
            <button onClick={submitFeeStudentWise} className="btn btn-primary">
              Receive Fee
            </button>
          )}
          {/* <button onClick={() => testCheck()}>Test</button> */}
          <button
            onClick={() => {
              close();
            }}
            className="btn btn-danger btn_width"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        <MultiStudentFeeChallan
          allstudentList={selectedStudent}
          blankPage={blankPage}
        />
      </div>

      {printAdmissionSlip ? (
        <AdmissionSlip
          show={printAdmissionSlip}
          close={handlePrintAdmSlipOff}
          studentdetails={studentdetails}
        />
      ) : null}
    </>
  );
};

export default PrintChallan;
