import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

const MonthlyPaySheetExport = ({ reportData }) => {
  const tableRef = useRef(null);

  return (
    <>
      <DownloadTableExcel
        filename="Monthly Pay Sheet"
        sheet="MonthlyPaySheet"
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary mx-2"> Export </button>
      </DownloadTableExcel>

      <Table ref={tableRef} style={{ display: "none" }}>
        <thead>
          <tr id="tbl">
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th
              colSpan={5}
              className="text-center text-primary"
              style={{ borderBottom: "2px solid purple", textAlign: "center" }}
            >
              DEDUCTIONS
            </th>
            <th className="text-center"></th>
          </tr>
          <tr id="tbl">
            <th className="text-center">Sr.No</th>
            <th className="text-center">Code</th>
            <th className="text-center">Name</th>
            <th className="text-center">Salary Days</th>
            <th className="text-center">Pay Scale</th>
            <th className="text-center">Basic Pay</th>
            <th className="text-center">Voucher</th>
            <th className="text-center">Increment Total</th>
            <th className="text-center">Income Tax</th>
            <th className="text-center">Eobi</th>
            <th className="text-center">Eobi Payment</th>
            <th className="text-center">UGS Allowance</th>
            <th className="text-center">Additional Allowance</th>
            <th className="text-center">Extra Period Allowance</th>
            <th className="text-center">Extra Coaching</th>
            <th className="text-center">2nd Shift Allowance</th>
            <th className="text-center">HOD Allowance</th>
            <th className="text-center">College Allowance</th>
            <th className="text-center">Hostel Allowance</th>
            <th className="text-center">Hifz Allowance</th>
            <th className="text-center">Other Allowance</th>
            <th className="text-center">Conv Allow</th>
            <th className="text-center">GPF Return</th>
            <th className="text-center">GP Fund</th>
            <th className="text-center">Loan Refund</th>
            <th className="text-center">Science</th>
            <th className="text-center">Gross Salary</th>
            <th className="text-center">Insu Dedu</th>
            <th className="text-center">Staff Van Dedu</th>
            <th className="text-center">Child Fee Dedu</th>
            <th className="text-center">Welfare Fund</th>
            <th className="text-center">Other Dedu</th>
            <th className="text-center">Net Salary</th>
          </tr>
        </thead>

        <tbody>
          {reportData.length > 0 &&
            reportData.map((subitem, index) => (
              <tr key={index} id="tbl">
                <td className="text_align_center">{index + 1}</td>
                <td className="text_align_center">
                  {subitem.employee.emp_code}
                </td>
                <td className="text_align_center">
                  {subitem.employee.full_name}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.days}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.employee.pay_scale.payscale}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.basic_pay}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.voucher?.voucher_no}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.increment}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.income_tax}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.eobi}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.eobi_payments}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.ugs}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.additional_allowance}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.extra_period}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.extra_coaching}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.second_shift}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.hod}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.college}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.hostel}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.hifz}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.other_allowance}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.convance}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.gpf_return}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.gp_fund}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.loan_refund}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.science}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.gross_salary}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.insurance}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.van_charge}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.child_fee_deduction}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.welfare_fund}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.other_deduction}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {subitem.net_pay}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default MonthlyPaySheetExport;
