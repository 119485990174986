import { Paper } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";

const EnterTest = () => {
  const pageStyle = `
    @page {
      size: a4 !important;
    }
    `;

  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const alert = useAlert();

  const authtoken = localStorage.getItem("userToken");
  const [allsessions, setallsessions] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedcampus, setselectedcampus] = useState("");
  const [currentsession, setcurrentsession] = useState("");
  const [selectedsection, setselectedsection] = useState("");
  const [allsubjects, setallsubjects] = useState([]);
  const [selectedsubject, setselectedsubject] = useState("");
  const [educationType, setEducationType] = useState(1);

  const [testTypeList, setTestTypeList] = useState([]);
  const [selectedTestType, setSelectedTestType] = useState("");
  const [selectedclass, setselectedclass] = useState("");

  const [allclasses, setallclasses] = useState([]);
  const [allCampusSections, setallCampusSections] = useState([]);
  const [totalMarks, setTotalMarks] = useState(10);
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [updateloading, setupdateloading] = useState(false);
  const [allTestList, setAllTestList] = useState([]);
  const [loading, setloading] = useState(false);
  const [testDetails, setTestDetails] = useState([]);
  const [printEmpty, setPrintEmpty] = useState(true);

  //printing states
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  const [selectedStudents, setSelectedStudents] = useState([]);
  const [mainCheckBoxChecked, setmaincheckboxChecked] = useState(false);
  const searchClicked = useRef(false);
  const firstTimeLoad = useRef(false);
  const [sendLoading, setSendLoading] = useState(false);

  const [sendTestMessageModal, setSendTestMessageModal] = useState(false);
  useEffect(() => {
    if (!firstTimeLoad.current) {
      firstTimeLoad.current = true;
      getAllSubjects();
      getAllTestTypes();
      getAllSessions();
      getSettingData();
    }

    if (searchClicked.current) {
      searchClicked.current = false;
      setAllCheckboxes(true);
    } else {
      checkAllCheckBoxes();
    }
  }, [[], selectedStudents]);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllTestTypes = async () => {
    await fetch(`${baseUrl}/test-type`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTestTypeList(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getAllSubjects = async () => {
    await fetch(`${baseUrl}/subject`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsubjects(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const onCampusChange = async (id) => {
    setselectedcampus(id);
    getCampusClasses(id);
    setallclasses([]);
    setselectedclass("");
    setallCampusSections([]);
    setselectedsection("");
  };

  const getCampusClasses = (id) => {
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setallclasses(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = (id) => {
    setselectedclass(id);
    setallCampusSections([]);
    setselectedsection("");

    fetch(`${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallCampusSections([...data.payload]);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedclass ? "/" + selectedclass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          setallCampusSections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createTestResult = () => {
    if (
      selectedTestType == "" ||
      selectedcampus == "" ||
      selectedsubject == ""
    ) {
      return alert.error("Please select all fileds");
    }

    if (selectedsection == "" && selectedclass == "") {
      return alert.error("Please select class/section");
    }

    if (totalMarks == 0) {
      return alert.error("Please Enter Total Marks");
    }

    if (educationType != "2" && selectedclass == "") {
      return alert.error("Please select class");
    }

    setAllTestList([]);
    setTestDetails([]);
    setloading(true);

    let data = {
      year_id: currentsession,
      campus_id: selectedcampus,
      education_type: educationType,
      student_class_id: selectedclass,
      global_section_id: selectedsection,
      subject_id: selectedsubject,
      test_type_id: selectedTestType,
      full_marks: totalMarks,
      date: currentDate,
    };

    fetch(`${baseUrl}/test`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          const res = data.payload[0].test_results.sort(
            (a, b) => a.student.admission_id - b.student.admission_id
          );
          setAllTestList(res);
          setTestDetails(data.payload[0]);

          alert.success("Test Result Successfully Created");
          setloading(false);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
            setloading(false);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const getTestResult = () => {
    if (
      currentsession == "" ||
      selectedcampus == "" ||
      selectedclass == "" ||
      selectedsection == "" ||
      selectedsubject == "" ||
      selectedTestType == "" ||
      currentDate == ""
    ) {
      return alert.error("Please select all fileds");
    }

    setAllTestList([]);
    setTestDetails([]);
    setloading(true);

    fetch(
      `${baseUrl}/test/${currentsession}/${selectedcampus}/${selectedclass}/${educationType}/${selectedsection}/${selectedsubject}/${selectedTestType}/${currentDate}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          const res = data.payload[0].test_results.sort(
            (a, b) => a.student.admission_id - b.student.admission_id
          );
          setAllTestList(res);
          setTestDetails(data.payload[0]);
          setloading(false);
        } else if (!data.metadata.success) {
          alert.error(data.metadata.message);
          setloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const OnchangefullMark = (e, index) => {
    let arr = [...allTestList];
    arr.map((item) => {
      item.full_marks = e.target.value;
    });
    setAllTestList(arr);
  };

  const onChangeGainMarks = (e, index) => {
    let arr = [...allTestList];
    let newVal = e.target.value;
    let full_marks = totalMarks;

    if (newVal <= full_marks) {
      arr[index].gain_marks = newVal;
      setAllTestList(arr);
    }
  };

  const updateResult = () => {
    setupdateloading(true);

    let student_id = [];
    let test_id = [];
    let gain_marks = [];
    let status = [];

    allTestList.forEach((ele) => {
      student_id.push(ele.student_id);
      test_id.push(ele.test_id);
      gain_marks.push(ele.gain_marks ? ele.gain_marks : 0);
      status.push(ele.status);
    });

    let test_results = { test_results: allTestList };

    fetch(`${baseUrl}/update-test-results`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(test_results),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          alert.success("Test update successfully");
          setupdateloading(false);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg);
            setupdateloading(false);
          });
        }
      })
      .catch((err) => {
        setupdateloading(false);

        console.log(err);
      });
  };

  const sendTestModal = () => {
    if (selectedStudents == "") {
      return alert.error("Please Select Some Students");
    }
    setSendTestMessageModal(true);
  };

  const sendSingleTestsSMS = () => {
    if (selectedStudents == "") {
      return alert.error("Please Select Some Students");
    }

    setSendLoading(true);
    let data = {
      test_id: selectedTestType,
      student_ids: selectedStudents,
    };

    fetch(`${baseUrl}/single-test-send-sms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          alert.success("Test Message Sent Successfully");
          setSendLoading(false);
          setSendTestMessageModal(false);
        } else if (!data.metadata.success) {
          alert.error(data.metadata.message);
          setSendLoading(false);
          setSendTestMessageModal(false);
        }
      })
      .catch((err) => {
        setSendLoading(false);
        setSendTestMessageModal(false);
        console.log(err);
      });
  };

  // Handle Test Checkbox
  const handleMainCheckboxClick = () => {
    if (mainCheckBoxChecked) setAllCheckboxes(false);
    else setAllCheckboxes(true);
  };

  const setAllCheckboxes = (value) => {
    setmaincheckboxChecked(value);
    if (value) {
      setSelectedStudents([]);
    }

    allTestList.map((item, i) => {
      item.checked = value;
      if (!value) {
        RemoveSelectedTest(item);
      } else {
        AddSelectedTest(item);
      }
    });
  };

  const checkAllCheckBoxes = () => {
    if (allTestList.length == selectedStudents.length) {
      setmaincheckboxChecked(true);
    } else if (mainCheckBoxChecked) {
      setmaincheckboxChecked(false);
    }
  };

  const handleChallansCheckBoxClick = (e, index) => {
    allTestList.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;

        if (item.checked) {
          AddSelectedTest(item);
        } else {
          RemoveSelectedTest(item);
        }
      }
    });
  };

  const AddSelectedTest = (item) => {
    setSelectedStudents((prevSelectedTests) => [
      ...prevSelectedTests,
      item.student_id,
    ]);
  };

  const RemoveSelectedTest = (item) => {
    setSelectedStudents((prevSelectedTests) =>
      prevSelectedTests.filter((id) => id !== item.student_id)
    );
  };

  //printing function

  const handleAfterPrint = React.useCallback(() => {}, []);

  const handleBeforePrint = React.useCallback(() => {}, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const onStatusChange = (e, index) => {
    let arr = [...allTestList];
    let newVal = e.target.value;

    arr[index].status = newVal;
    if (newVal != "Present") {
      arr[index].gain_marks = 0;
    }
    setAllTestList(arr);
  };

  const inputRefs = useRef([]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();

      const nextIndex = (index + 1) % inputRefs.current.length;
      const nextInput = inputRefs.current[nextIndex];

      if (nextInput) nextInput.focus();
      nextInput.select();
    }
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "20px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              onChange={(e) => setcurrentsession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session) => (
                <option key={session.id} value={session.id}>
                  {session.year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => onCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Campus</option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Class</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedclass}
              onChange={(e) => onClassChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Class</option>
              {allclasses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option value={1}>Education</option>
              <option value={2}>Hifz</option>
            </select>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3">
            <label>
              <b>Select Section</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedsection}
              onChange={(e) => setselectedsection(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Section</option>
              {allCampusSections.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Subject</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedsubject}
              onChange={(e) => setselectedsubject(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Subject</option>
              {allsubjects.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Test Type</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              onChange={(e) => {
                setSelectedTestType(e.target.value);
              }}
              className="form-select"
              style={{ height: 40 }}
              value={selectedTestType}
              aria-label="Default select example"
            >
              <option value="">Select Test Type</option>
              {testTypeList.map((test, index) => (
                <option key={index} value={test.id}>
                  {test?.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Enter Total Marks</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <input
              type="number"
              className="form-control"
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
              name="total_marks"
              placeholder="Enter Total Marks"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Select Date{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <input
              value={currentDate}
              className="form-control"
              onChange={(e) => setCurrentDate(e.target.value)}
              type="date"
            />
          </div>

          <div className="col-md-4 mt-12 d-flex">
            <div className="ml-5">
              <input type="checkbox" style={{ display: "none" }} />
              <label className="checkbox checkbox-single">
                <input
                  checked={printEmpty}
                  type="checkbox"
                  onChange={(e) => setPrintEmpty(e.target.checked)}
                />
                <span />
                &nbsp;&nbsp;&nbsp;Print Empty
              </label>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-between"
          style={{ marginTop: 20, height: 40 }}
        >
          <div>
            <button
              disabled={loading ? true : false}
              className="btn btn-primary"
              onClick={getTestResult}
            >
              {loading ? "Loading..." : "Get Test Result"}
            </button>

            {allTestList.length > 0 && (
              <ReactToPrint
                pageStyle={pageStyle}
                documentTitle="Test Result"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    className="btn btn-primary w-105px mx-2"
                  >
                    {printloading ? "Printing..." : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            )}

            <button
              className="btn btn-primary w-205px mx-2"
              onClick={sendTestModal}
              disabled={sendLoading ? true : false}
            >
              {sendLoading ? "Sending..." : "Send Test SMS"}
            </button>
          </div>
          <div>
            <button
              disabled={loading ? true : false}
              className="btn btn-primary"
              onClick={createTestResult}
            >
              {loading ? "Loading..." : "Create Test Result"}
            </button>
          </div>
        </div>

        {loading ? (
          <div className="row justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Table responsive style={{ overflow: "scroll" }}>
            <thead>
              {allTestList.length > 0 && (
                <tr>
                  <th style={{ width: "5px" }}>
                    <input
                      onClick={(e) => {
                        handleMainCheckboxClick();
                      }}
                      checked={mainCheckBoxChecked}
                      placeholder="Checkbox"
                      type="checkbox"
                      readOnly
                    />
                  </th>
                  <td className="text-center">Sr.No</td>
                  <td className="text-center">Admission No</td>
                  <td className="text-center">Student Name</td>
                  <td className="text-center">Subject</td>
                  <td className="text-center">Test Type</td>
                  <td className="text-center">Total Marks</td>
                  <td className="text-center">Gain Marks</td>
                  <td className="text-center">Status</td>
                </tr>
              )}
            </thead>
            <tbody>
              {allTestList.length > 0
                ? allTestList.map((item, index) => (
                    <tr key={item.id}>
                      <td style={{ width: "5px" }}>
                        <input
                          type="checkbox"
                          value={item.id}
                          key={item.id}
                          onClick={(e) => {
                            handleChallansCheckBoxClick(e, item.id);
                          }}
                          checked={item.checked ?? false}
                          style={{ border: "10px" }}
                          readOnly
                        />
                      </td>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">
                        {item.student?.admission_id}
                      </td>
                      <td className="text-center">{item.student?.name}</td>
                      <td className="text-center">
                        {testDetails?.subject?.name}
                      </td>
                      <td className="text-center">
                        {testDetails?.test_type?.name}
                      </td>
                      <td className="text-center">
                        <input
                          onChange={(e) => OnchangefullMark(e, index)}
                          value={totalMarks}
                          placeholder={"Total Marks"}
                          disabled
                        />
                      </td>
                      <td className="text-center">
                        <input
                          disabled={item.status != "Present" ? true : false}
                          onChange={(e) => onChangeGainMarks(e, index)}
                          value={item.gain_marks}
                          placeholder={"Gain Marks"}
                          ref={(ref) => (inputRefs.current[index] = ref)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      </td>
                      <td className="text-center">
                        <select
                          style={{ height: 40, width: "120px" }}
                          className="form-select"
                          name="status"
                          value={item.status}
                          onChange={(e) => onStatusChange(e, index)}
                          aria-label="Default select example"
                        >
                          <option value="Present">Present</option>
                          <option value="Absent">Absent</option>
                          <option value="Leave">Leave</option>
                        </select>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        )}

        {allTestList.length > 0 && (
          <div className="d-flex justify-content-end">
            <button
              disabled={updateloading ? true : false}
              className="btn btn-primary mx-2"
              onClick={updateResult}
            >
              {updateloading ? "Updating..." : "Update Test Result"}
            </button>
          </div>
        )}
      </Paper>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
      >
        <div className="p-3" style={{ display: "flex" }}>
          <img style={{ height: 110, width: 100 }} src={icon} />

          <div className="col-md-8">
            <h1>{unitName}</h1>
            <div className="d-flex justify-content-between">
              <div>
                <h3>
                  <b>Campus: {testDetails?.campus?.name}</b>
                </h3>
                <h3>
                  <b>Subject: {testDetails?.subject?.name}</b>
                </h3>
              </div>
              <div>
                <h3>
                  <b>Class: {testDetails?.student_class?.name}</b>
                </h3>
                <h3>
                  <b>Section: {testDetails?.global_section?.name}</b>
                </h3>
              </div>

              <div>
                <p
                  style={{
                    fontSize: "16px",
                    color: "black",
                    margin: "0",
                    textAlign: "center",
                    borderBottom: "1px solid black",
                  }}
                >
                  {printEmpty ? "" : totalMarks}
                </p>
                <div>
                  <p style={{ fontSize: "16px", color: "black" }}>
                    Total Marks
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <h2>{testDetails.test_type?.name} Test</h2>
        </div>

        <Table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th
                style={{
                  fontSize: "16px",
                  padding: "0px",
                  border: "1px solid black",
                }}
                className="text-center"
              >
                <b>Sr.No</b>
              </th>
              <th
                style={{
                  fontSize: "16px",
                  padding: "0px",
                  border: "1px solid black",
                }}
                className="text-center"
              >
                <b>Admission No</b>
              </th>
              <th
                style={{
                  fontSize: "16px",
                  padding: "0px",
                  border: "1px solid black",
                }}
                className="text-center"
              >
                <b>Student Name</b>
              </th>
              <th
                style={{
                  fontSize: "16px",
                  padding: "0px",
                  border: "1px solid black",
                }}
                className="text-center"
              >
                {" "}
                <b>Gain Marks</b>
              </th>

              <th
                style={{
                  fontSize: "16px",
                  border: "1px solid black",
                  padding: "0px",
                }}
                className="text-center"
              >
                {" "}
                <b>Total Marks</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {allTestList.map((item, index) => (
              <tr key={item.id}>
                <td
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    color: "black",
                    border: "1px solid black",
                    padding: "0px",
                  }}
                >
                  {index + 1}
                </td>
                <td
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    color: "black",
                    border: "1px solid black",
                    padding: "0px",
                  }}
                >
                  {item.student?.admission_id}
                </td>
                <td
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    color: "black",
                    border: "1px solid black",
                    padding: "0px",
                  }}
                >
                  {item.student?.name}
                </td>

                <td
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    color: "black",
                    border: "1px solid black",
                    padding: "0px",
                  }}
                >
                  {!printEmpty ? item.gain_marks : ""}
                </td>

                <td
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    color: "black",
                    border: "1px solid black",
                    padding: "0px",
                  }}
                >
                  {!printEmpty ? totalMarks : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Comfirmation SMS Modal Start*/}
      <Modal show={sendTestMessageModal}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Are you sure want to send test result message?
          </Modal.Title>
        </Modal.Header>
        {sendLoading ? (
          <div style={{ padding: "20px" }}>
            {" "}
            <b style={{ color: "green" }}>
              {" "}
              Message Sending Please Wait...{" "}
              <span className="spinner-borderd"></span>
            </b>
          </div>
        ) : (
          <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={sendSingleTestsSMS}
                className="btn button_blue"
                disabled={sendLoading ? true : false}
              >
                {sendLoading ? "Sending..." : "Send"}
              </button>
              <button
                style={{ marginLeft: "10px" }}
                onClick={() => setSendTestMessageModal(false)}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
      {/* ------------------------Comfirmation SMS Modal End----------------------- */}
    </div>
  );
};

export default EnterTest;
