import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

const StudentStrengthExport = ({
  summary,
  summaryData,
  getRetrainTotal,
  getLeftTotal,
  getSummaryStrengthTotal,
  grandSummary,
  grandLeft,
  grandRetrain,
  reportData,
  grandStrength,
  grandFemale,
  grandMale,
  getStrengthSum,
  getfeMaleSum,
  getMaleSum,
}) => {
  const tableRef = useRef(null);

  return (
    <>
      <DownloadTableExcel
        filename="Student Strength"
        sheet="Student Strength"
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary mx-2 mt-9">Export</button>
      </DownloadTableExcel>
      <div id="tableboot mt-5">
        {summary ? (
          <Table ref={tableRef} style={{ display: "none" }}>
            <thead>
              <tr id="tbl">
                <th className="text-center">Class</th>
                <th className="text-center">Section</th>
                <th className="text-center">Program</th>
                <th className="text-center">Retain</th>
                <th className="text-center">Left</th>
                <th className="text-center">Strength</th>
              </tr>
            </thead>
            {summaryData.length > 0
              ? summaryData.map((item) => (
                  <>
                    {/* <div className="mx-4" style={{ marginTop: "40px" }}>
                    <h3 className="text-primary">{item.Campus}</h3>
                  </div> */}
                    <tr id="tbl">
                      <td
                        style={{ fontSize: "1.3rem" }}
                        className="text_align_start text-primary"
                      >
                        {item.Campus}
                      </td>
                      <td style={{ fontWeight: "bold" }} className=""></td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center text-primary"
                      >
                        {/* {getMaleSum(item)} */}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center text-primary"
                      >
                        {/* {getfeMaleSum(item)} */}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center text-primary"
                      >
                        {/* {getStrengthSum(item)} */}
                      </td>
                    </tr>
                    <tbody>
                      {item?.classes?.map((subitem) => (
                        <>
                          {subitem?.sections?.map((session, indu) => (
                            <>
                              {/* <div className="mt-5">
                          <h4 className="">
                            Class:-{subitem.Class?.name}(
                            {session.Section?.name})
                          </h4>
                        </div> */}
                              {session.programs.length > 0
                                ? session.programs.map((last) => (
                                    <tr id="tbl">
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {subitem?.Class?.name}
                                        {/* {session?.report?.male} */}
                                      </td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {session?.Section?.name}
                                        {/* {session?.report?.male} */}
                                      </td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {last?.program}
                                        {/* {session?.report?.male} */}
                                      </td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {last?.report?.retain}
                                      </td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {last?.report?.left_students}
                                      </td>
                                      <td
                                        style={{ color: "black" }}
                                        className="text-center"
                                      >
                                        {last?.report?.strength}
                                      </td>
                                    </tr>
                                  ))
                                : ""}
                            </>
                          ))}
                        </>
                      ))}
                      <tr id="tbl">
                        <td
                          style={{ fontSize: "1.3rem" }}
                          className="text_align_start text-primary"
                        >
                          {/* {item.Campus} */}
                        </td>
                        <td
                          style={{ fontSize: "1.3rem" }}
                          className="text_align_start text-primary"
                        >
                          {/* {item.Campus} */}
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-end text-primary"
                        >
                          Campus Wise Total :
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center text-primary"
                        >
                          {getRetrainTotal(item)}
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center text-primary"
                        >
                          {getLeftTotal(item)}
                        </td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-center text-primary"
                        >
                          {getSummaryStrengthTotal(item)}
                        </td>
                      </tr>
                    </tbody>
                  </>
                ))
              : ""}
            <tr id="tbl">
              <td
                style={{ fontSize: "1.3rem" }}
                className="text_align_start text-primary"
              >
                {/* {item.Campus} */}
              </td>
              <td
                style={{ fontSize: "1.3rem" }}
                className="text_align_start text-primary"
              >
                {/* {item.Campus} */}
              </td>
              <td
                style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                className="text-end text-primary"
              >
                Grand Total :
              </td>
              <td
                style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                className="text-center text-primary"
              >
                {grandRetrain}
                {/* {getRetrainTotal(item)} */}
              </td>
              <td
                style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                className="text-center text-primary"
              >
                {grandLeft}
                {/* {getLeftTotal(item)} */}
              </td>
              <td
                style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                className="text-center text-primary"
              >
                {grandSummary}
                {/* {getSummaryStrengthTotal(item)} */}
              </td>
            </tr>
          </Table>
        ) : (
          <Table ref={tableRef} style={{ display: "none" }}>
            <thead>
              <tr id="tbl">
                <th className="text-center">Class Name</th>
                <th className="text-center">Section Nmae</th>
                <th className="text-center">Male</th>
                <th className="text-center">Female</th>
                <th className="text-center">Strength</th>
              </tr>
            </thead>
            {reportData.length > 0 ? (
              <>
                {reportData.map((item) => (
                  <>
                    {/* <div className="mx-4" style={{ marginTop: "40px" }}>
                    <h3 className="text-primary">{item.Campus}</h3>
                  </div> */}
                    <tr id="tbl">
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text_align_start text-primary"
                      >
                        {item.Campus}
                      </td>
                      <td style={{ fontWeight: "bold" }} className=""></td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center text-primary"
                      >
                        {getMaleSum(item)}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center text-primary"
                      >
                        {getfeMaleSum(item)}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="text-center text-primary"
                      >
                        {getStrengthSum(item)}
                      </td>
                    </tr>
                    <tbody>
                      {item?.classes?.map((subitem) => (
                        <>
                          {subitem?.sections?.map((session, indu) => (
                            <>
                              {/* <div className="mt-5">
                          <h4 className="">
                            Class:-{subitem.Class?.name}(
                            {session.Section?.name})
                          </h4>
                        </div> */}
                              <tr id="tbl">
                                <td
                                  style={{ color: "black" }}
                                  className="text-center"
                                >
                                  {indu == 0 ? subitem?.Class?.name : ""}
                                  {/* {session?.report?.male} */}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text-center"
                                >
                                  {session?.Section?.name}
                                  {/* {session?.report?.male} */}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text-center"
                                >
                                  {session?.report?.male}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text-center"
                                >
                                  {session?.report?.female}
                                </td>
                                <td
                                  style={{ color: "black" }}
                                  className="text-center"
                                >
                                  {session?.report?.strength}
                                </td>
                              </tr>
                            </>
                          ))}
                          <tr id="tbl">
                            <td
                              style={{ color: "black", fontWeight: "bold" }}
                              className="text_align_start"
                            ></td>
                            <td
                              style={{ color: "black", fontWeight: "bold" }}
                              className="text-end"
                            >
                              Class Wise Totals:-
                            </td>
                            <td
                              style={{ color: "black", fontWeight: "bold" }}
                              className="text-center"
                            >
                              {subitem.sections.reduce(
                                (a, c) => a + parseInt(c.report.male),
                                0
                              )}
                            </td>
                            <td
                              style={{ color: "black", fontWeight: "bold" }}
                              className="text-center"
                            >
                              {subitem.sections.reduce(
                                (a, c) => a + parseInt(c.report.female),
                                0
                              )}
                            </td>
                            <td
                              style={{ color: "black", fontWeight: "bold" }}
                              className="text-center"
                            >
                              {subitem.sections.reduce(
                                (a, c) => a + parseInt(c.report.strength),
                                0
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </>
                ))}
                <tr id="tbl">
                  <td
                    style={{ color: "black", fontWeight: "bold" }}
                    className="text_align_start"
                  ></td>
                  <td
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    className="text-end text-primary"
                  >
                    Grand Total:-
                  </td>
                  <td
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    className="text-center text-primary"
                  >
                    {grandMale}
                  </td>
                  <td
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    className="text-center text-primary"
                  >
                    {grandFemale}
                  </td>
                  <td
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                    className="text-center text-primary"
                  >
                    {grandStrength}
                  </td>
                </tr>
              </>
            ) : (
              ""
            )}
          </Table>
        )}
      </div>
    </>
  );
};

export default StudentStrengthExport;
