import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../../services/config";
import { Paper } from "@material-ui/core";
import { Modal, Spinner, Table } from "react-bootstrap";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import TrialExport from "./TrialExport";
import { upperCase } from "lodash";
const pageStyle = `
  @page {
      margin-left: 0.5in;
    size: A4 portrate !important;
}
`;

const FirmWiseReport = () => {
  const [yearId, setYearId] = useState("");
  const [values, setValues] = useState("");
  const [searchLoading, setsearchLoading] = useState(false);
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [yearId1, setYearId1] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [sessionName, setSessionName] = useState("");
  const [sessionName1, setSessionName1] = useState("");
  const [debitSum, setDebitSum] = useState(0);
  const [banks, setallbanks] = useState([]);
  const [creditSum, setCreditSum] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [contraDebit, setContraDebit] = useState(0);
  const [contraCredit, setContraCredit] = useState(0);
  const [date, setDate] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const renderedRows = [];
  var drValue1 = 0;
  var crValue1 = 0;
  var sumcr = "0";
  var sumdr = "0";
  var crcd = "";
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  let authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    sessions();
    getAllBanks();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleInput(event) {
    setValues(event.target.value);
    setSessionName1(event.target.selectedOptions[0].text);
    console.log(values);
  }
  const getAllBanks = async () => {
    // await fetch(`${baseUrl}/bankr`, {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${authtoken}`,
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     let campuses = [];
    //     setallbanks(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        //  if (selectedbankcateogry != "") {
        //    let anserr = data.payload.filter(
        //      (item) => item.id == selectedbankcateogry
        //    );

        //    if (anserr.length != 0) {
        //      setallsubaccounts([...anserr[0].bank_accounts]);
        //    } else {
        //      setallsubaccounts([]);
        //    }
        //  }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
            setYearId1(session.id + 1);
            console.log(session);
            setSessionName(session.year);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetData = () => {
    if (yearId == "") {
      alert.error("Please select the year");
      return;
    }
    setLoading(true);
    setsearchLoading(true);
    setbuttonDisable(true);
    const data = {
      year_id: yearId,
      date: date,
    };
    fetch(`${baseUrl}/firmview?year_id=${yearId}&bank_id=${values}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setsearchLoading(false);
        setbuttonDisable(false);
        setIsVisible(true);
        console.log(data);

        setReportData(data);
        let sum = 0;
        let sum2 = 0;
        data.forEach((element) => {
          if (element.cr === element.dr) {
            return null; // Skip rendering
          }

          sum += parseInt(element.cr);

          sum2 += parseInt(element.dr);
        });
        setCreditSum(sum);
        setDebitSum(sum2);

        console.log("this is data", data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const calckNumberDebit = (data) => {
    const totalDebit = data.reduce((a, c) => a + parseInt(c.debit), 0);
    const totalCredit = data.reduce((a, c) => a + parseInt(c.credit), 0);
    if (totalDebit > totalCredit) {
      return Number(totalDebit) - Number(totalCredit);
    } else {
      return 0;
    }
  };
  const calckNumberCredit = (data) => {
    const totalDebit = data.reduce((a, c) => a + parseInt(c.debit), 0);
    const totalCredit = data.reduce((a, c) => a + parseInt(c.credit), 0);
    if (totalCredit > totalDebit) {
      return Number(totalCredit) - Number(totalDebit);
    } else {
      return 0;
    }
  };

  const mainNumberCalc = (data) => {
    let totalDebit = 0;
    let totalCredit = 0;
    data.map((item) => {
      item.sub_account.map((last) => {
        totalDebit += parseInt(last.debit);
      });
      item.sub_account.map((last) => {
        totalCredit += parseInt(last.credit);
      });
    });
    if (totalDebit > totalCredit) {
      return `Total Lose ${totalDebit - totalCredit}`;
    } else {
      return `Total Profit ${totalCredit - totalDebit}`;
    }
  };
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-3 ">
            <label>
              <b>Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => {
                  const data = sessionData.find((ses) => {
                    return ses.id == e.target.value;
                  });
                  console.log(data);
                  setSessionName(data.year);
                  setYearId(e.target.value);
                }}
              >
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-3 ">
            <label>
              <b>Select Bank</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                class="form-control"
                onChange={(e) => {
                  handleInput(e);
                }}
                name="bank2"
                id="bank2"
                required
              >
                <option value="0">Select...</option>
                {banks.map((campus) => (
                  <option value={campus.id}>{campus.title}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-1 mt-9">
            <button
              className="btn btn-primary"
              disabled={buttonDisable}
              onClick={handleGetData}
            >
              Show
            </button>
          </div>
          <div className="col-md-3 mt-9">
            {searchLoading ? (
              <Spinner animation="border" style={{ color: "blue" }} />
            ) : reportData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Firm Vise Report`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                pageStyle={pageStyle}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>
        <div className="mt-5">
          {isVisible ? (
            <div id="tableboot mt-4">
              <div className="d-flex">
                <div className="">
                  <img
                    style={{ height: 100, width: 110, paddingLeft: "20px" }}
                    src={icon}
                    alt="logo"
                  />
                </div>
                <div className="mx-5" style={{ paddingTop: "10px" }}>
                  <h1>{unitName}</h1>
                  <h3>Firm Wise Report For The Financial Year {sessionName}</h3>
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th className="text-center" style={{ width: "10%" }}>
                      A/C Code
                    </th>
                    <th className="text-center" style={{ width: "60%" }}>
                      Details
                    </th>
                    <th className="text-center" style={{ width: "15%" }}>
                      Debit
                    </th>
                    <th className="text-center" style={{ width: "15%" }}>
                      Credit
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {reportData.map((item) => {
                    //   const ytext = document.getElementById("year_id");
                    // var new_year = ytext.options[ytext.selectedIndex].text;
                    sumcr = sumcr + item.cr;
                    sumdr = sumdr + item.dr;

                    const difference = item.cr - item.dr;

                    //debitSum
                    const drValue = difference > 0 ? difference : 0;
                    const crValue = difference < 0 ? -difference : 0;

                    const rowObject = {
                      sub_account_id: item.sub_account_id,
                      account_chart_id: item.sub_account.account_chart_id,
                      acode: item.sub_account.acode,
                      title: item.sub_account.title,
                      dr: drValue,
                      cr: crValue,
                      rawdr: item.dr,
                      rawcr: item.cr,
                      session: yearId + 1,
                      campus_id: 10,
                      nuration: item.sub_account.remarks,
                    };
                    renderedRows.push(rowObject);

                    return (
                      <tr>
                        <td className="text-center">
                          {item.sub_account.acode}
                        </td>
                        <td className="text-center">
                          {item.sub_account.title}

                          {/* {item.remarks && item.remarks.length > 40
                            ? item.remarks.substring(0, 35)
                            : item.remarks} */}
                        </td>
                        <td className="text-center">
                          {/* {item.account_group} */}
                          {item.dr}
                        </td>
                        <td className="text-center">{item.cr}</td>
                      </tr>
                    );
                  })}
                  <tr id="tbl">
                    <td
                      style={{ color: "black", paddingLeft: "30px" }}
                      className="text-start"
                    ></td>
                    <td
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                      className="text-end"
                    >
                      Sub Total
                    </td>

                    <td
                      style={{
                        color: "black",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      className="text-center"
                    >
                      {debitSum} DR
                    </td>

                    <td
                      style={{
                        color: "black",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                      className="text-center"
                    >
                      {creditSum} CR
                    </td>
                  </tr>
                  {(() => {
                    console.log(sumcr);
                    const difference1 = creditSum - debitSum;

                    drValue1 = difference1 > 0 ? difference1 : 0;
                    crValue1 = difference1 < 0 ? -difference1 : 0;
                    if (crValue1 > drValue1) {
                      crcd = "cr";
                      drValue1 = "";
                      crValue1 = crValue1 + " CR";
                    } else {
                      crcd = "dr";
                      drValue1 = drValue1 + " DR";
                      crValue1 = "";
                    }
                  })()}

                  <tr>
                    <td className="text-center"></td>
                    <td className="text-center"></td>
                    <td
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                      className="text-center text-primary"
                    >
                      Balance
                    </td>

                    <td
                      className="text-center text-primary"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      {crValue1}
                      {drValue1}
                    </td>
                  </tr>

                  {/* <tr id="tbl">
                    <td
                      style={{ color: "black", paddingLeft: "30px" }}
                      className="text-start"
                    ></td>
                    <td
                      style={{
                        fontSize: "1.3rem",
                        paddingLeft: "200px",
                        fontWeight: "bold",
                      }}
                      className="text-start text-primary"
                    >
                      Grand Total :-
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-end text-primary"
                    >
                      {debitSum + drValue1}
                    </td>

                    <td
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                      }}
                      className="text-end text-primary"
                    >
                      {creditSum + crValue1}
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </Paper>

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div id="tableboot mt-4">
          <div className="d-flex">
            <div className="">
              <img
                style={{ height: 100, width: 120, paddingLeft: "20px" }}
                src={icon}
                alt="logo"
              />
            </div>
            <div className="mx-5" style={{ paddingTop: "10px" }}>
              <h1>{unitName}</h1>
              <h3>Firm Wise Report For The Financial Year {sessionName}</h3>
              {/*(() => {
                const ytext = document.getElementById("bank2");
                crcd = ytext.options[ytext.selectedIndex].text;
              })()*/}
              <h3>
                {/*document.getElementById("bank2").options.text*/}
                Firm: {sessionName1}
              </h3>
            </div>
          </div>
          <Table
            style={{ whiteSpace: "normal", padding: "1px", fontSize: "18px" }}
            responsive
          >
            <thead
              style={{ whiteSpace: "normal", padding: "1px", fontSize: "18px" }}
            >
              <tr
                id="tbl"
                style={{
                  whiteSpace: "normal",
                  padding: "1px",
                  fontSize: "18px",
                }}
              >
                <th
                  className="text-center"
                  style={{
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                    width: "10%",
                  }}
                >
                  A/C Code
                </th>
                <th
                  className="text-center"
                  style={{
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                    width: "60%",
                  }}
                >
                  Details
                </th>
                <th
                  className="text-center"
                  style={{
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                    width: "15%",
                  }}
                >
                  Debit
                </th>
                <th
                  className="text-center"
                  style={{
                    whiteSpace: "normal",
                    padding: "1px",
                    fontSize: "18px",
                    width: "15%",
                  }}
                >
                  Credit
                </th>
              </tr>
            </thead>

            <tbody
              style={{ whiteSpace: "normal", padding: "1px", fontSize: "18px" }}
            >
              {reportData.map((item) => {
                //   const ytext = document.getElementById("year_id");
                // var new_year = ytext.options[ytext.selectedIndex].text;
                sumcr = sumcr + item.cr;
                sumdr = sumdr + item.dr;

                const difference = item.cr - item.dr;

                //debitSum
                const drValue = difference > 0 ? difference : 0;
                const crValue = difference < 0 ? -difference : 0;

                const rowObject = {
                  sub_account_id: item.sub_account_id,
                  account_chart_id: item.sub_account.account_chart_id,
                  acode: item.sub_account.acode,
                  title: item.sub_account.title,
                  dr: drValue,
                  cr: crValue,
                  rawdr: item.dr,
                  rawcr: item.cr,
                  session: yearId + 1,
                  campus_id: 10,
                  nuration: item.sub_account.remarks,
                };
                renderedRows.push(rowObject);

                return (
                  <tr
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      fontSize: "18px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <td
                      className="text-center"
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      {item.sub_account.acode}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      {/* {item.remarks} */}
                      {item.sub_account.title}
                      {/* {item.remarks && item.remarks.length > 40
                        ? item.remarks.substring(0, 35)
                        : item.remarks} */}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      {/* {item.account_group} */}
                      {item.dr}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "18px",
                      }}
                    >
                      {item.cr}
                    </td>
                  </tr>
                );
              })}
              <tr id="tbl">
                <td
                  style={{
                    color: "black",
                    paddingLeft: "30px",
                  }}
                  className="text-start"
                ></td>
                <td
                  style={{
                    fontSize: "18px",
                    paddingRight: "100px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                  className="text-end"
                >
                  Sub Total
                </td>

                <td
                  style={{
                    color: "black",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  className="text-center"
                >
                  {debitSum} DR
                </td>

                <td
                  style={{
                    color: "black",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  className="text-center"
                >
                  {creditSum} CR
                </td>
              </tr>
              {(() => {
                console.log(sumcr);
                const difference1 = creditSum - debitSum;

                drValue1 = difference1 > 0 ? difference1 : 0;
                crValue1 = difference1 < 0 ? -difference1 : 0;
                if (crValue1 > drValue1) {
                  crcd = "cr";
                  crValue1 = crValue1 + " CR";
                  drValue1 = "";
                } else {
                  crcd = "dr";
                  drValue1 = drValue1 + " DR";
                  crValue1 = "";
                }
              })()}

              <tr>
                <td className="text-center"></td>
                <td className="text-center"></td>
                <td
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "black",
                    borderBottom: "2px solid black",
                  }}
                  className="text-center text-primary"
                >
                  Balance
                </td>

                <td
                  className="text-center text-primary"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "black",
                    borderBottom: "2px solid black",
                  }}
                >
                  {crValue1}
                  {drValue1}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default FirmWiseReport;
