import React from "react";
import { Paper } from "@material-ui/core";
import { baseUrl } from "../../../../services/config";
import { useEffect } from "react";
import { Spinner, Table } from "react-bootstrap";
import { useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";

const pageStyle = `
  @page {
    size: landscape !important;
}
`;

const GpFund = () => {
  const [loading, setLoading] = useState(false);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [text, setText] = React.useState("old boring text");
  const [campusId, setCampusId] = useState("");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  useEffect(() => {
    // getEobi();
    // getAllCampus();
  }, []);

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  let authtoken = localStorage.getItem("userToken");
  const [reportData, setreportData] = useState([]);
  const getEobi = async () => {
    setLoading(true);
    await fetch(`${baseUrl}/staff-gp-fund-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({ campus_id: campusId }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);

        console.log(data, "======");
        if (data.metadata) {
          if (data.metadata.success) {
            setreportData(data.payload);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <label>
              <b> Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => setCampusId(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  All Campuses
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 mt-9">
            <button className="btn btn-primary" onClick={() => getEobi()}>
              Show
            </button>
            {reportData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Gp Fund Report`}
                pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              className="text-center"
              variant="info"
              animation="border"
            />
          </div>
        ) : (
          <Table responsive>
            <thead>
              <tr className="text-center">
                <th>Code</th>
                <th>Name</th>
                <th>Scale</th>
                <th>Designation</th>
                <th>Account No</th>
                <th>Joining Date</th>
                <th>Qualification</th>
                <th>GPF Total</th>
              </tr>
            </thead>
            {reportData.length > 0
              ? reportData.map((item, index) =>
                  item.employees.length > 0 ? (
                    <React.Fragment key={index}>
                      <tbody>
                        <tr>
                          <td
                            colSpan={8}
                            className="text-center text-primary pt-2"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "500",
                              width: "max-content",
                            }}
                          >
                            {item.Campus}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        {item.employees.length > 0
                          ? item.employees.map((subitem, sub_index) => (
                              <tr key={sub_index}>
                                <td>{subitem.emp_code}</td>
                                <td>{subitem.full_name}</td>
                                <td>{subitem.pay_scale?.payscale}</td>
                                <td>{subitem.designation?.name}</td>
                                <td>{subitem.account_no}</td>
                                <td>{subitem.joining_date}</td>
                                <td>{subitem.qualification}</td>
                                <td>{subitem.g_p_fund?.collected_amount}</td>
                              </tr>
                            ))
                          : null}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="border-bottom-dark border-top-dark font-size-h6 font-weight-bolder text-black text-center text-primary">
                            Grand Total GPF
                          </td>
                          <td className="border-bottom-dark border-top-dark font-size-h6 font-weight-bolder text-black text-center">
                            {item.total_collected_gp_fund}
                          </td>
                        </tr>
                      </tbody>
                    </React.Fragment>
                  ) : null
                )
              : null}
          </Table>
        )}

        {/* ///for print /////// */}

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <table className="tabler">
            <tr>
              <td className="">Code</td>
              <td className="">Name</td>
              <td className="">Scale</td>
              <td className="">Designation</td>
              <td className="">Account No</td>
              <td className="" style={{ width: "160px" }}>
                Joining Date
              </td>
              <td className="">Qualification</td>
              <td className="">Total GPF</td>
            </tr>
            {reportData.length > 0
              ? reportData.map((item, index) =>
                  item.employees.length > 0 ? (
                    <React.Fragment key={index}>
                      <tbody>
                        <tr>
                          <td
                            colSpan={8}
                            className="text-center text-primary pt-2"
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "500",
                              width: "max-content",
                              marginLeft: "10px",
                            }}
                          >
                            {item.Campus}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        {item.employees.length > 0
                          ? item.employees.map((subitem, index) => (
                              <tr key={index}>
                                <td>{subitem.emp_code}</td>
                                <td>{subitem.full_name}</td>
                                <td>{subitem.pay_scale?.payscale}</td>
                                <td>{subitem.designation?.name}</td>
                                <td>{subitem.account_no}</td>
                                <td>{subitem.joining_date}</td>
                                <td>{subitem.qualification}</td>
                                <td>{subitem.g_p_fund?.collected_amount}</td>
                              </tr>
                            ))
                          : null}

                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="font-size-h6 font-weight-bolder text-black text-center text-primary">
                            Grand Total GPF
                          </td>
                          <td className="font-size-h6 font-weight-bolder text-black text-center">
                            {item.total_collected_gp_fund}
                          </td>
                        </tr>
                      </tbody>
                    </React.Fragment>
                  ) : null
                )
              : null}
          </table>
        </div>
      </Paper>
    </>
  );
};

export default GpFund;
