import { Paper } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { baseUrl } from "../../../services/config";
import Select from "react-select";
import { useAlert } from "react-alert";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import PasswordModal from "../../../components/PasswordModal/PasswordModal";
import ExportCsv from "../../../components/ExportCsv/ExportCsv";
import { useDispatch } from "react-redux";
import { upperCase } from "lodash";

const BankPaySheet = () => {
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  const [selected, setSelected] = useState("cheque");
  const [month, setMonth] = useState("");
  const [campusData, setCampusData] = useState([]);
  const [campus, setCampus] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [fullCampus, setFullCampus] = useState({});
  const [bankId, setBankId] = useState("");
  const [cheque, setCheque] = useState("");
  const [loading, setLoading] = useState(false);
  const [csvList, setCsvList] = useState([]);

  const [grandTotal, setgrandTotal] = useState("");
  const pageStyle = `
  @page {
    size: legal portrait !important;
    margin-bottom: 1in;
  }
  `;
  const alert = useAlert();
  const [icon, setIcon] = useState([]);
  // const icon = "";

  // const icon =
  //   "https://darearqam.com/wp-content/uploads/2020/03/daraarqam-logo.jpg";
  let authtoken = localStorage.getItem("userToken");

  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [dateData, setDateData] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [campusCity, setCampusCity] = useState("");
  const dispatch = useDispatch();
  const [directorSign, setDirectorSign] = useState(0);
  const [unitName, setUnitName] = useState("");

  useEffect(() => {
    getAllBanks();
    getSettingData();
  }, []);

  const onRadioButtonClick = (e) => {
    setReportData([]);
    setSelected(e.target.value);
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setDirectorSign(data.payload.settings.director_sign);
        setIcon(data.payload.settings.logo_file);

        // console.log(icon);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        if (data.metadata) {
          if (data.metadata.success) {
            data.payload.forEach((item) => {
              item["label"] = item.title;
              item["options"] = item.bank_accounts;
              item.bank_accounts.forEach((subitem) => {
                subitem.label = subitem.bank_name;
                subitem.value = subitem.id;
              });
            });
            setAllBanks(data.payload);
          }
        }
        console.log(data.payload, "changed data ");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddCampusHandler = () => {
    if (month == "") {
      alert.error("Month Is Required");
      return;
    }

    setLoading(true);
    const data = {
      date: `${month}-01`,
      cheque_no: selected == "cheque" ? cheque : "",
      campus_id: selected == "campus" ? campus : "",
      bank_id: selected == "campus" ? bankId : "",
    };

    fetch(`${baseUrl}/monthly-pay-sheet`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setLoading(false);
          console.log(data);
          let sum = 0;
          data.payload.map((item) => {
            sum += parseInt(item.net_pay);
          });
          setgrandTotal(sum);
          setReportData(data.payload);
          const csvArray = [];
          data.payload.map((item, i) =>
            csvArray.push({
              sr_no: i + 1,
              code: item.emp_code,
              name: item.full_name,
              pay: item.net_pay,
              account: item.account_no,
            })
          );
          setCsvList(csvArray);
        })
      )
      .catch((err) => {
        setLoading(false);
      });
  };

  function formatDate(inputDate) {
    const parts = inputDate.split("-");
    const month = parseInt(parts[1], 10);
    const year = parts[0];

    // Create an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the full month name
    const fullMonth = monthNames[month - 1];

    // Format the result as "Month, Year"
    const formattedDate = `${fullMonth}, ${year}`;

    return formattedDate;
  }

  const changeDate1 = () => {
    var yourDate = new Date(month);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var year = yourDate.getFullYear();
    var monthIndex = yourDate.getMonth();
    var day = yourDate.getDate();

    var formattedDate = months[monthIndex] + " " + day + ", " + year;

    return formattedDate;
  };

  const formatdate2 = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  function inWords(num) {
    let a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    let str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lac "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]])
        : "";
    console.log(str);
    return str;
  }

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  const headers = [
    { label: "SR.NO", key: "sr_no" },
    { label: "Code", key: "code" },
    { label: "Name", key: "name" },
    { label: "Pay", key: "pay" },
    { label: "Account No", key: "account" },
  ];
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {/* <div className="row">
                <div className="col-md-6 text-center">
                  <input
                    checked={selected == "cheque"}
                    onChange={(e) => onRadioButtonClick(e)}
                    defaultChecked
                    type="radio"
                    id="cheque"
                    value="cheque"
                  />
                  <label for="cheque">Cheque Wise</label>
                </div>

                <div className="col-md-6 text-center">
                  <input
                    checked={selected == "campus"}
                    onChange={(e) => onRadioButtonClick(e)}
                    type="radio"
                    id="Campus"
                    value="campus"
                  />
                  <label for="Campus">Campus Wise</label>
                </div>
              </div> */}
        <div className="row">
          {selected == "cheque" ? (
            <div className="col-md-4">
              <label>
                <b>Enter Cheque Number</b>
              </label>
              <input
                onChange={(e) => setCheque(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Enter Cheque Number"
              />
            </div>
          ) : (
            ""
          )}
          {selected == "campus" ? (
            <>
              <div className="col-md-4">
                <label>
                  <b>Select Campus</b>
                </label>
                <div className="d-flex flex-column">
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      let res = campusData.find(
                        (item) => item.id == e.target.value
                      );
                      setFullCampus(res);
                      setCampus(e.target.value);
                    }}
                  >
                    <option selected value="">
                      {" "}
                      Select Campus
                    </option>
                    {campusData &&
                      campusData.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <label>
                  Bank <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  options={allBanks}
                  onChange={(e) => setBankId(e.value)}
                  // formatGroupLabel={formatGroupLabel}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="col-md-4">
            <div>
              <label>
                <b>Select Month</b>
              </label>
            </div>
            <input
              value={month}
              onChange={(e) => {
                setMonth(e.target.value);
              }}
              className="form-control"
              type="month"
            />
          </div>
          <div className="col-md-4 mt-9">
            <button className="btn btn-primary" onClick={AddCampusHandler}>
              Show
            </button>
            {reportData.length > 0 ? (
              <ReactToPrint
                documentTitle={`Pay Salary`}
                pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-success button_blue button_blue "
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : (
              ""
            )}
            <ExportCsv
              csvList={csvList}
              headers={headers}
              left={true}
              name="Bank Pay Sheet"
            />
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <>
            <div id="tableboot">
              <Table responsive className="mt-5">
                <thead>
                  <tr id="tbl">
                    <th className="text-center">SR.NO</th>
                    <th className="text-center">Code</th>
                    <th>Name</th>
                    <th className="text-center">Pay</th>
                    <th className="text-center">Account No</th>
                  </tr>
                </thead>

                <tbody>
                  {!!reportData &&
                    reportData.map((item, index) => (
                      <tr id="tbl">
                        <td style={{ color: "black" }} className="text-center">
                          {index + 1}
                        </td>
                        <td style={{ color: "black" }} className="text-center">
                          {item.emp_code}
                        </td>
                        <td
                          style={{ color: "black" }}
                          className="text_align_start"
                        >
                          {item.full_name}
                        </td>

                        <td style={{ color: "black" }} className="text-center">
                          {item.net_pay}
                        </td>
                        <td style={{ color: "black" }} className="text-center">
                          {item.account_no}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </>
        )}

        {reportData.length > 0 ? (
          <div
            ref={componentRef}
            id="printSection"
            style={{ visibility: "hidden" }}
            className="tablePrint"
          >
            <div className="p-3" style={{ display: "flex" }}>
              <img
                // className='col-md-2'
                style={{ height: 110, width: 120 }}
                src={icon}
              />

              <div className="col-md-8" style={{ paddingTop: "5px" }}>
                <h1>{unitName}</h1>
                <p className="paybodyFont nextmoreFont">
                  <b>
                    PAY SHEET FOR THE MONTH OF {upperCase(formatDate(month))}
                  </b>
                </p>
                <div
                  className="cheqanddated"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="text-end">
                    <b className="paybodyFont nextmoreFont">Cheque No:-</b>
                    &nbsp;&nbsp;&nbsp;
                    <span className="paybodyFont  nextmoreFont">{cheque}</span>
                  </div>

                  <div className="text-end">
                    <b className="paybodyFont nextmoreFont">Dated:-</b>{" "}
                    &nbsp;&nbsp;&nbsp;
                    <span className="paybodyFont nextmoreFont">
                      {formatdate2()}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="p-3" style={{ display: "flex" }}>
              <div className="col-md-8" style={{ paddingTop: "25px" }}>
                <div
                  className="cheqanddated"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                  <div className=" text-end">
                    {selected == "cheque" ? (
                      <>
                        <b className="paybodyFont">Cheque No:-</b>
                        &nbsp;&nbsp;&nbsp;
                        <span className="paybodyFont">{cheque}</span>
                      </>
                    ) : (
                      <>
                        <b className="paybodyFont"></b>&nbsp;&nbsp;&nbsp;
                        <span className="paybodyFont">{fullCampus?.name}</span>
                      </>
                    )}
                  </div>
                  <div className="text-center">
                    <b className="paybodyFont">Dated:-</b> &nbsp;&nbsp;&nbsp;
                    <span className="paybodyFont">{formatdate2()}</span>
                  </div>
                </div>
              </div>
            </div> */}

            <table style={{ width: "100%", marginTop: "10px" }}>
              <thead>
                <tr className="payheadFont">
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                    }}
                  >
                    <b>SR.No.</b>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                    }}
                  >
                    <b>CODE</b>
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      border: "1px solid black",
                      paddingLeft: "5px",
                      color: "black",
                    }}
                  >
                    <b>NAME OF THE EMPLOYEE</b>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                    }}
                  >
                    <b>PAID</b>
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      border: "1px solid black",
                      paddingLeft: "5px",
                      color: "black",
                    }}
                  >
                    <b>ACCOUNT NO.</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                {reportData.map((item, index) => {
                  return (
                    <>
                      <tr key={index} className="paybodyFont">
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                            color: "black",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                            color: "black",
                          }}
                        >
                          {item.emp_code}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            border: "1px solid black",
                            paddingLeft: "5px",
                            color: "black",
                          }}
                        >
                          {item.full_name}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                            color: "black",
                          }}
                        >
                          {item.net_pay}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            border: "1px solid black",
                            paddingLeft: "5px",
                            color: "black",
                          }}
                        >
                          {item.account_no}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <div className="totalOfPaidSalary">
              <div className="d-flex">
                <p>
                  <b>In Words:</b>
                </p>
                <p style={{ paddingLeft: "15px" }}>
                  {" "}
                  {inWords(parseInt(grandTotal))}
                </p>
              </div>
              <div className="d-flex">
                <p>
                  <b>Total:</b>
                </p>
                <p style={{ paddingLeft: "15px" }}> {grandTotal}</p>
              </div>
            </div>
            <div
              className=""
              style={{
                marginTop: "80px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="">
                <p>
                  <b
                    style={{
                      color: "black",
                      fontSize: "18px",
                      paddingRight: "30px",
                      paddingLeft: "30px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Accountant
                  </b>
                </p>
              </div>
              {/* <div className="">
                <p>
                  <b
                    style={{
                      color: "black",
                      fontSize: "18px",
                      paddingRight: "30px",
                      paddingLeft: "30px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Principal
                  </b>
                </p>
              </div> */}
              {/* <div className="">
                {directorSign == 0 ? (
                  <p>
                    <b
                      style={{
                        color: "black",
                        fontSize: "18px",
                        paddingRight: "30px",
                        paddingLeft: "30px",
                        borderTop: "1px solid black",
                      }}
                    >
                      Chief Executive
                    </b>
                  </p>
                ) : (
                  <p>
                    <b
                      style={{
                        color: "black",
                        fontSize: "18px",
                        paddingRight: "30px",
                        paddingLeft: "30px",
                        borderTop: "1px solid black",
                      }}
                    >
                      Director
                    </b>
                  </p>
                )}
              </div> */}
              <div className="" style={{ marginLeft: "50px" }}>
                {directorSign == 0 ? (
                  <p>
                    <b
                      style={{
                        color: "black",
                        fontSize: "18px",
                        paddingRight: "30px",
                        paddingLeft: "30px",
                        borderTop: "1px solid black",
                      }}
                    >
                      Chief Executive / Principal
                    </b>
                  </p>
                ) : (
                  <p>
                    <b
                      style={{
                        color: "black",
                        fontSize: "18px",
                        paddingRight: "30px",
                        paddingLeft: "30px",
                        borderTop: "1px solid black",
                      }}
                    >
                      Director / Principal
                    </b>
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Paper>
      {/* {loginAccount == "Campus" ? <PasswordModal /> : ""} */}
    </>
  );
};

export default BankPaySheet;
