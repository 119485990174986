import React, { useEffect, useState } from "react";
import { PeopleAltOutlined, Payment, Apartment } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
// import AddchartIcon from '@mui/icons-material/Addchart';
import Paper from "@material-ui/core/Paper";
import {
  Table,
  Modal,
  Spinner,
  Alert,
  Form,
  Button,
  ModalFooter,
  Badge,
} from "react-bootstrap";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import MySelect from "./../../CustomChallan/MySelect";
import DateSheetModal from "./DateSheetModal";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Switch } from "@material-ui/core";
import Toggle from "./Toggle";
import PasswordModal from "../../../../components/PasswordModal/PasswordModal";
import { PremissionChecker } from "../../../../services/PremissionChecker";
import { useSelector } from "react-redux";

const ExamList = () => {
  const history = useHistory();
  const alert = useAlert();
  // configgration
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  //arrays
  const [allexamslist, setallexamslist] = useState([]);
  const [examslist, setexamslist] = useState([]);
  const [allsessions, setallsessions] = useState([]);
  const [selectedcampus, setselectedcampus] = useState("");
  const [selectedExam_id, setselectedExam_id] = useState("");
  const [selectedExam_title, setselectedExam_title] = useState("");
  const [status, setStatus] = useState();
  const [dateSheet, setDateSheet] = useState();
  const [allclasses, setallclasses] = useState([]);
  const [optionSelected, setoptionSelected] = useState([]);
  const [selectedclasses, setselectedclasses] = useState([]);
  //objects
  const [selectedobject, setselectedobject] = useState({});
  const [updateModel, setupdateModel] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deletingExam, setDeletingExam] = useState(false);
  //booleans
  const [showdetailmodal, setshowdetailmodal] = useState(false);
  const [declareexammodal, setdeclareexammodal] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [loading, setloading] = useState(false);
  const [loader, setloader] = useState(false);
  const [datasheetmodal, setdatasheetmodal] = useState(false);
  //strings
  const [currentsession, setcurrentsession] = useState("");
  const [selectedclassfordatesheet, setselectedclassfordatesheet] = useState(
    ""
  );
  const [selectedexamfordatesheet, setselectedexamfordatesheet] = useState("");
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedexam, setselectedexam] = useState("");
  const [toggleId, setToggleId] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [removeConfirmModal, setRemoveConfirmModal] = useState(false);

  const [educationType, setEducationType] = useState(1);
  //functions

  useEffect(() => {
    getAllClasses();
    getTypeList();
    getAllSessions();
    // getAllCampus();
  }, []);

  const updateExam = () => {
    if (selectedExam_title.length == 0) {
      alert.error("Please add exam title");
      return;
    } else {
      setUpdateLoading(true);

      const data = {
        exam_id: selectedExam_id,
        exam_name: selectedExam_title,
      };

      fetch(`${baseUrl}/update-exam-name`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setUpdateLoading(false);

          if (data.metadata) {
            if (data.metadata.success) {
              alert.success(data.metadata.message);
              setConfirmModal(false);
              getAllExamsList();
            }
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const deleteExam = () => {
    setDeletingExam(true);

    fetch(`${baseUrl}/delete-exam/${selectedExam_id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            setRemoveConfirmModal(false);
            setDeletingExam(false);
            getAllExamsList();
          }
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  const getAllCampus = async () => {
    console.log("campus geeting");
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const toggleAction = (id) => {
    setToggleId(id);
    setupdateModel(true);
  };
  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
            getAllExamsList(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllClasses = () => {
    fetch(`${baseUrl}/studentclass`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallclasses([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changesession = (id) => {
    setcurrentsession(id);
    // getAllExamsList(id)
  };

  const getTypeList = () => {
    fetch(`${baseUrl}/exam_type`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setloader(false);
        console.log("get exam", data);

        setexamslist([...data.payload]);
        // getassignedExamsList(data.payload)
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const getAllExamsList = () => {
    const data = {
      year_id: currentsession,
      campus_id: selectedcampus,
    };

    // console.log(data)
    setloader(true);
    fetch(`${baseUrl}/exam_for_session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloader(false);
        console.log("get exam", data);

        setallexamslist([...data.payload]);
        // getassignedExamsList(data.payload)
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };

  const getassignedExamsList = (allexamlist) => {
    fetch(`${baseUrl}/exam`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            let examlist = [...allexamlist];

            allexamlist.map((item, index) => {
              let examobjectsfound = data.payload.filter(function(examitem) {
                return examitem.exam_type_id == item.id;
              });
              examlist[index].declaredexams = examobjectsfound;
              // if (examobjectsfound.length !=0 ) {

              // }
            });

            setallexamslist([...examlist]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const openModal = (item) => {
    console.log(item);
    setshowdetailmodal(true);
    setselectedobject(item);
  };

  const onCampusChange = async (id) => {
    setselectedcampus(id);
  };

  const declareExamHandler = () => {
    if (
      selectedexam == "" ||
      currentsession == "" ||
      educationType == "" ||
      selectedclasses.length == 0
    ) {
      setisallfields(true);
      return;
    }
    let data = {
      exam_type_id: selectedexam,
      year_id: currentsession,
      education_type: educationType,
      student_class_ids: selectedclasses,
      campus_id: selectedcampus,
    };
    console.log(data);
    setloading(true);

    fetch(`${baseUrl}/exam`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data", data);
        setloading(false);
        setoptionSelected([]);
        setselectedexam("");
        setselectedclasses("");
        setdeclareexammodal(false);
        getAllExamsList();
        alert.success("Successfully Declared");
        // if (data.metadata) {
        //     if (data.metadata.success) {
        //     } else {
        //         alert.show("Error Occured");
        //     }
        // } else {
        //     alert.show("Error Occured");
        // }
      })
      .catch((err) => {
        setloading(false);
        setoptionSelected([]);
        setselectedexam("");
        setselectedclasses("");
        setdeclareexammodal(false);
        alert.show("Error Occured");
        console.log(err);
      });
  };

  //multiselect configration
  const Options = [];
  allclasses.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });

  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const handleChangeClasses = (selected) => {
    console.log(selected);
    if (optionSelected.length == 30 && selected.length == 30) {
      setoptionSelected([]);
      return;
    }
    setoptionSelected([...selected]);
    let valuearray = [];

    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setselectedclasses([...valuearray]);
  };

  // console.log("quick view", allexamslist)

  return (
    <div className="container">
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          {/* <div class="form-group col-sm-6 flex-column  d-flex">
                        <label >
                            <b>
                                Select Session <span style={{ color: "red" }}>*</span>
                            </b>
                        </label>
                    </div> */}
          <div className="col-md-4">
            <label>
              <b>
                Select Session <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              onChange={(e) => changesession(e.target.value)}
              class="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session) => (
                <option
                  // selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <label>
              <b>
                Select Campus <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => onCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 mt-7 d-flex">
            <button
              style={{
                width: "150px",
                color: "#ffffff",
                backgroundColor: "#121291",
                borderColor: "#121291",
              }}
              onClick={() => {
                setdeclareexammodal(true);
              }}
              className="btn btn-success"
            >
              Declare Exam
            </button>

            <button
              style={{
                color: "#ffffff",
                backgroundColor: "#121291",
                borderColor: "#121291",
              }}
              onClick={getAllExamsList}
              className="btn btn-success mx-2"
            >
              Show
            </button>
          </div>
        </div>
        {loader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-center",
              padding: "10px",
            }}
          >
            {" "}
            <span className="spinner-border"></span>{" "}
          </div>
        ) : (
          <div className="row">
            {allexamslist?.map((item, index) => {
              return (
                <>
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-around">
                      <div>
                        <b>
                          {item.status == 1 ? "Announced" : "Announce Result"}
                        </b>
                        <Switch
                          disabled={
                            !PremissionChecker(user, "exam.announce_result")
                          }
                          size="small"
                          color="primary"
                          checked={item.status == 1 ? true : false}
                          onClick={() => toggleAction(item.id)}
                          onChange={(e) => setStatus(e.target.checked)}
                        />
                      </div>
                      <div>
                        <b>Date Sheet</b>
                        <Switch
                          size="small"
                          color="primary"
                          checked={item.date_sheet_status == 1 ? true : false}
                          onClick={() => toggleAction(item.id)}
                          onChange={(e) => setDateSheet(e.target.checked)}
                        />
                      </div>
                    </div>
                    {/* <div style={{position:"relative",zIndex:"1",left:item.status == 1 ?"53%":"43%",top:"20%"}}>
                                        <b>{item.status == 1 ? "Announced":"Announce Result"}</b>
                                       <Switch
                                        size="small"
                                        color="primary"
                                        checked={item.status == 1 ? true : false}
                                        onClick={() => toggleAction(item.id)}
                                        onChange={(e)=>setStatus(e.target.checked)}
                                      />
                                       </div> */}

                    <div className="col-md-12">
                      <div className="card card-1 m-2">
                        <span
                          onClick={() => openModal(item)}
                          key={item.id}
                          style={{ cursor: "pointer", textAlign: "center" }}
                        >
                          <Payment
                            style={{
                              height: 40,
                              width: 40,
                              color: "#121291",
                            }}
                            //  color="primary"
                            fontSize="large"
                          />
                          <h4>
                            {
                              /* {item.exam_type == undefined
                              ? item.name
                              : item.exam_type.name} */
                              item?.exam_name
                            }
                          </h4>
                        </span>

                        <div style={{ paddingLeft: "200px" }}>
                          <span
                            onClick={() => {
                              setConfirmModal(true);
                              setselectedExam_id(item.id);
                              setselectedExam_title(item?.exam_name);
                            }}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>

                          {PremissionChecker(user, "exam.delete_exam") ? (
                            <span
                              onClick={() => {
                                if (item.status !== 1) {
                                  console.log(item.id);
                                  setRemoveConfirmModal(true);
                                  setselectedExam_id(item.id);
                                  setselectedExam_title(
                                    item.exam_type == undefined
                                      ? item.name
                                      : item.exam_type.name
                                  );
                                }
                              }}
                              style={{ cursor: "pointer", marginLeft: "10px" }}
                              className={"svg-icon svg-icon-md svg-icon-danger"}
                              disabled={item.status === 0 ? false : true}
                            >
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}

        <Modal size="lg" show={declareexammodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Declare Exams
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="form-group col-sm-12 flex-column d-flex">
              <div className="row">
                <div className="col-md-6  flex-column d-flex ">
                  <label>
                    <b>
                      Select Session <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <select
                    onChange={(e) => setcurrentsession(e.target.value)}
                    className="form-select"
                    style={{ height: 40 }}
                    value={currentsession}
                    aria-label="Default select example"
                  >
                    <option value="">Select Session</option>
                    {allsessions.map((session, index) => (
                      <option
                        key={index}
                        //   selected={session.active_academic_year == "1" ? true : false}
                        value={session.id}
                      >
                        {session.year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <label>
                    <b>
                      Select Campus <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    name="campus_id"
                    value={selectedcampus}
                    onChange={(e) => onCampusChange(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option selected value="">
                      {" "}
                      Select Campus
                    </option>
                    {campuses.map((campus, index) => (
                      <option key={index} value={campus.id}>
                        {campus.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* <div className=" col-md-12  flex-column d-flex ">
                                <label>
                                    <b>Select Session <span style={{ color: "red" }}>*</span></b>
                                </label>
                                <select
                                    onChange={(e) => setcurrentsession(e.target.value)}
                                    className="form-select"
                                    style={{ height: 40 }}
                                    value={currentsession}
                                    aria-label="Default select example"
                                >
                                    <option value="">Select Session</option>
                                    {allsessions.map((session, index) => (
                                        <option
                                            key={index}
                                            //   selected={session.active_academic_year == "1" ? true : false}
                                            value={session.id}
                                        >
                                            {session.year}
                                        </option>
                                    ))}
                                </select>

                            </div> */}
              <br />

              <div className="row">
                <div className=" col-md-6  flex-column d-flex ">
                  <label>
                    <b>
                      Select Exam <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <select
                    class="form-select"
                    value={selectedexam}
                    onChange={(e) => {
                      setselectedexam(e.target.value);
                    }}
                    // onBlur={handleBlur}
                    style={{ backgroundColor: "white", height: 40 }}
                    aria-label="Default select example"
                  >
                    <option selected value="">
                      Select Exam Type
                    </option>
                    {examslist.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6">
                  <label>
                    <b>Select Education Type</b>
                  </label>
                  <select
                    className="form-select"
                    name="campus_id"
                    value={educationType}
                    style={{ backgroundColor: "white", height: 40 }}
                    aria-label="Default select example"
                    onChange={(e) => {
                      setEducationType(e.target.value);
                    }}
                  >
                    <option selected value={1}>
                      Education
                    </option>
                    <option value={2}>Hifz</option>
                  </select>
                </div>

                <div className=" col-md-12  flex-column d-flex ">
                  <br />
                  <label>
                    <b>
                      Select Classes <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <MySelect
                    options={Options}
                    isMulti
                    name="selectedOptions"
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={handleChangeClasses}
                    allowSelectAll={true}
                    value={optionSelected}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          {isallfields ? (
            <>
              <Alert
                variant="danger"
                onClose={() => setisallfields(false)}
                dismissible
              >
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>Please Fill All Fields</p>
              </Alert>
            </>
          ) : null}

          <Modal.Footer>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                }}
              >
                {" "}
                <span className="spinner-border"></span>{" "}
              </div>
            ) : (
              <>
                <button
                  onClick={() => {
                    declareExamHandler();
                  }}
                  className="btn btn-success button_blue"
                >
                  Declare
                </button>

                <button
                  onClick={() => {
                    setdeclareexammodal(false);
                  }}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        {selectedobject.student_classes != undefined ? (
          <Modal size="lg" show={showdetailmodal}>
            <Modal.Header className="modal_header_style" closeButton>
              <Modal.Title
                className="Modal_Heading_font"
                id="contained-modal-title-vcenter"
              >
                Exams Detail
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                <div>
                  {
                    <div className=" col-md-12 d-flex flex-column ">
                      <div className="row">
                        <div className=" col-md-6 d-flex ">
                          <div>
                            {" "}
                            <h5 style={{ color: "grey" }}>
                              Session : &ensp;
                            </h5>{" "}
                          </div>
                          <div>
                            <h5
                              style={{
                                color: "grey",
                                textAlign: "start",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              {selectedobject.session?.year}
                            </h5>
                          </div>
                        </div>

                        <div className=" col-md-6 d-flex ">
                          <div>
                            {" "}
                            <h5 style={{ color: "grey" }}>
                              Status : &ensp;
                            </h5>{" "}
                          </div>
                          <div>
                            <h5
                              style={{
                                color: "grey",
                                textAlign: "start",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              {selectedobject.session?.status == "0"
                                ? "Proccedding"
                                : "Announced"}
                            </h5>
                          </div>
                        </div>
                      </div>

                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-center">Sr. No</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Define Date Sheet</th>
                          </tr>
                        </thead>

                        <tbody>
                          {selectedobject.student_classes.map(
                            (examclass, examclassindex) => {
                              return (
                                <tr>
                                  <td className="text-center">
                                    {examclassindex + 1}
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    {examclass.name}
                                  </td>
                                  <td className="text-center">
                                    {PremissionChecker(
                                      user,
                                      "exam.date_sheet"
                                    ) ? (
                                      <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setselectedclassfordatesheet(
                                            examclass.id
                                          );
                                          setselectedexamfordatesheet(
                                            selectedobject.id
                                          );
                                          setdatasheetmodal(true);
                                        }}
                                        className="svg-icon svg-icon-md svg-icon-primary"
                                      >
                                        <SVG
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/Communication/Clipboard-list.svg"
                                          )}
                                        />
                                      </span>
                                    ) : null}
                                  </td>
                                </tr>
                                //  <>
                                //     <div key={examclassindex} className=" col-md-6 d-flex ">
                                //         <div>
                                //             {" "}
                                //             <h5 style={{ color: "grey" }}>{examclassindex} : &ensp;</h5>{" "}
                                //         </div>
                                //         <div>
                                //             <h5
                                //                 value={examclass.id}

                                //                 style={{ color: "grey", textAlign: "start", cursor: "pointer" }}
                                //             >
                                //                 {" "}
                                //                 {examclass.name}
                                //             </h5>
                                //         </div>
                                //         <div>

                                //         </div>
                                //     </div>
                                //     <hr />
                                // </>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                  }
                </div>
              }
            </Modal.Body>

            <Modal.Footer>
              <button
                onClick={() => {
                  setshowdetailmodal(false);
                }}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        ) : null}

        {datasheetmodal ? (
          <DateSheetModal
            campusId={selectedobject.campus.id}
            show={datasheetmodal}
            onHide={setdatasheetmodal}
            selectedclassfordatesheet={selectedclassfordatesheet}
            examid={selectedexamfordatesheet}
            showdetailmodal={setshowdetailmodal}
          />
        ) : null}
        {/* datasheetmodal */}

        {/* Exam Edit Model */}
        <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-3">
              <label style={{ width: "100px" }}>Exam Title</label>
              <input
                onChange={(e) => {
                  setselectedExam_title(e.target.value);
                }}
                defaultValue={selectedExam_title}
                maxLength={100}
                type="text"
                placeholder="Enter Title"
                style={{ width: "400px" }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmModal(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={updateExam}
              disabled={updateLoading}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={removeConfirmModal}
          onHide={() => setRemoveConfirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            This will delete the exam and all related student results
            permanently.
            <br /> Are you sure you want to delete?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setRemoveConfirmModal(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className="btn btn-danger"
              //   onClick={deleteClassExam}
              disabled={deletingExam}
              onClick={deleteExam}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Paper>
      {/* {loginAccount == "Campus" ? <PasswordModal /> : ""} */}
      <Toggle
        title={"Update Status"}
        url={`exam_status_update`}
        description={"Are you sure you want to update the Status?"}
        close={() => setupdateModel(false)}
        show={updateModel}
        reload={getAllExamsList}
        status={status == true ? 1 : 0}
        dateSheetToggle={dateSheet == true ? 1 : 0}
        id={toggleId}
      />
    </div>
  );
};

export default ExamList;
