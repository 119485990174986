import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { baseUrl } from '../../../../services/config';
import { useAlert } from 'react-alert';

const Toggle = ({
    url,
    show,
    close,
    title,
    description,
    status,
    id,
    reload,
    dateSheetToggle
}) => {

    const [loading, setloading] = useState(false)
    const authtoken = localStorage.getItem("userToken");
const alert=useAlert()
    const updateThis = () => {
        const data={
            exam_id:id,
            status:status,
            date_sheet_status:dateSheetToggle
        }
        setloading(true)
        let fetchurl = `${baseUrl}/${url}`
        console.log(fetchurl)

        fetch(fetchurl, {
            method: 'POST',
            headers: {
                "Content-Type":"application/json",
                Authorization: `Bearer ${authtoken}`,
            },
            body:JSON.stringify(data)
        }).then(res => res.json())
            .then((data) => {
                setloading(false)
                if(data.metadata.success){
                    console.log(data)
                close()
                alert.success('Status update successfully')
                reload()
                }else{
                    alert.error('opps.. something went wronge')  
                }
            }).catch(err => {
                console.log(err)
                setloading(false)

            })
    }


    return (
        <Modal
            show={show}
            onHide={close}
            animation={true}
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {description}
            </Modal.Body>
            <Modal.Footer>
               
                            <button
                                onClick={close}
                                className='btn btn-primary'>
                                Cancel
                            </button>
                            <button
                                disabled={loading}
                                onClick={updateThis}
                                className='btn btn-danger'>
                                Change
                            </button>
                  
            </Modal.Footer>

        </Modal>
    )
}

export default Toggle