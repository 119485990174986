import React from "react";
import { Paper } from "@material-ui/core";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { baseUrl } from "../../../services/config";
import { Checkbox, Divider } from "antd";
import { Spinner, Table } from "react-bootstrap";
import StudentStrengthExport from "./StudentStrengthExport";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
const pageStyle = `
  @page {
      margin-left: 0.5in;
    size: A4 portrate !important;
}
`;
const StudentStrength = () => {
  let authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  const onBeforeGetContentResolve = React.useRef(null);
  const [printloading, setprintloading] = React.useState(false);
  const [yearId, setYearId] = useState("");
  const [text, setText] = React.useState("old boring text");
  const [sessionData, setSessionData] = useState([]);
  const [fullSession, setFullSession] = useState({});
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusId, setCampusId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setselected] = useState("Simple");
  const [reportData, setReportData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [summary, setSummary] = useState(false);
  const [grandRetrain, setGrandRetrain] = useState(0);
  const [grandLeft, setGrandLeft] = useState(0);
  const [campusName, setCampusName] = useState("");
  const [grandSummary, setGrandSummary] = useState(0);
  const [grandMale, setGrandMale] = useState(0);
  const [grandFemale, setGrandFemale] = useState(0);
  const [grandStrength, setGrandStrength] = useState(0);
  const [educationType, setEducationType] = useState(0);
  const componentRef = useRef(null);

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    getSettingData();
    sessions();
    // getAllCampus();
  }, []);

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
            setFullSession(session);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRadioChanged = (e) => {
    setselected(e.target.value);
  };
  const showResults = () => {
    setLoading(true);
    const data = {
      year_id: yearId,
      campus_ids: campusId ? [parseInt(campusId)] : [],
      summary: summary ? 1 : 0,
      education_type: educationType,
    };

    fetch(`${baseUrl}/student-strength-report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setLoading(false);
          if (summary) {
            getGrandRetrain(data.payload);
            getGrandleft(data.payload);
            getGrandStrenght(data.payload);
            setSummaryData(data.payload);
          } else {
            let sum = 0;
            let sum2 = 0;
            let sum3 = 0;
            data.payload.forEach((yup) => {
              yup.classes.forEach((element) => {
                element.sections.map((suitem) => {
                  sum += parseInt(suitem?.report?.male);
                });
              });
            });
            data.payload.forEach((yup) => {
              yup.classes.forEach((element) => {
                element.sections.map((suitem) => {
                  sum2 += parseInt(suitem?.report?.female);
                });
              });
            });
            data.payload.forEach((yup) => {
              yup.classes.forEach((element) => {
                element.sections.map((suitem) => {
                  sum3 += parseInt(suitem?.report?.strength);
                });
              });
            });
            setGrandMale(sum);
            setGrandFemale(sum2);
            setGrandStrength(sum3);
            setReportData(data.payload);
          }
          console.log(data);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getMaleSum = (data) => {
    let sum = 0;
    data.classes.forEach((element) => {
      element.sections.map((suitem) => {
        sum += parseInt(suitem?.report?.male);
      });
    });
    return sum;
  };
  const getRetrainTotal = (data) => {
    let sum = 0;
    data.classes.forEach((element) => {
      element.sections.map((suitem) => {
        suitem.programs.map((here) => {
          sum += parseInt(here?.report?.retain);
        });
      });
    });
    return sum;
  };
  const getLeftTotal = (data) => {
    let sum = 0;
    data.classes.forEach((element) => {
      element.sections.map((suitem) => {
        suitem.programs.map((here) => {
          sum += parseInt(here?.report?.left_students);
        });
      });
    });
    return sum;
  };
  const getSummaryStrengthTotal = (data) => {
    let sum = 0;
    data.classes.forEach((element) => {
      element.sections.map((suitem) => {
        suitem.programs.map((here) => {
          sum += parseInt(here?.report?.strength);
        });
      });
    });
    return sum;
  };
  const getGrandRetrain = (data) => {
    let sum = 0;
    data.map((item) => {
      item.classes.forEach((element) => {
        element.sections.map((suitem) => {
          suitem.programs.map((here) => {
            sum += parseInt(here?.report?.retain);
          });
        });
      });
    });
    setGrandRetrain(sum);
  };
  const getGrandleft = (data) => {
    let sum = 0;
    data.map((item) => {
      item.classes.forEach((element) => {
        element.sections.map((suitem) => {
          suitem.programs.map((here) => {
            sum += parseInt(here?.report?.left_students);
          });
        });
      });
    });
    setGrandLeft(sum);
  };
  const getGrandStrenght = (data) => {
    let sum = 0;
    data.map((item) => {
      item.classes.forEach((element) => {
        element.sections.map((suitem) => {
          suitem.programs.map((here) => {
            sum += parseInt(here?.report?.strength);
          });
        });
      });
    });
    setGrandSummary(sum);
  };
  const getfeMaleSum = (data) => {
    let sum = 0;
    data.classes.forEach((element) => {
      element.sections.map((suitem) => {
        sum += parseInt(suitem?.report?.female);
      });
    });
    return sum;
  };
  const getStrengthSum = (data) => {
    let sum = 0;
    data.classes.forEach((element) => {
      element.sections.map((suitem) => {
        sum += parseInt(suitem?.report?.strength);
      });
    });
    return sum;
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      <Paper
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          padding: "15px",
        }}
      >
        {/* <div className="row">
                <div className="col-sm-6 text-center">
                  <input
                    checked={selected == "Simple"}
                    onChange={(e) => onRadioChanged(e)}
                    type="radio"
                    id="Simple"
                    value="Simple"
                  />
                  <label for="Simple">Section Wise</label>
                </div>

                <div className="col-sm-6 text-center">
                  <input
                    type="radio"
                    checked={selected == "Detailed"}
                    onChange={(e) => onRadioChanged(e)}
                    id="Detailed"
                    value="Detailed"
                  />
                  <label for="Detailed">Section Wise Detailed</label>
                </div>

              </div> */}
        <div className="row">
          <div className="col-md-4">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                aria-label="Default select example"
                onChange={(e) => setCampusId(e.target.value)}
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => setEducationType(e.target.value)}
            >
              <option value={0}> All Education Types</option>
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          {/* <div className="col-md-2 mt-12">
            <Checkbox
              checked={summary}
              onChange={(e) => setSummary(e.target.checked)}
            >
              Show Summary
            </Checkbox>
          </div> */}
          <div className="col-md-6">
            <button
              disabled={loading}
              className="btn btn-primary mt-9"
              onClick={showResults}
            >
              Show
            </button>
            {reportData?.length > 0 ? (
              <ReactToPrint
                documentTitle={`Student Strength  Report`}
                onAfterPrint={handleAfterPrint}
                pageStyle={pageStyle}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    className="btn btn-success button_blue mt-9"
                    style={{ marginLeft: "5px" }}
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
            <StudentStrengthExport
              summary={summary}
              summaryData={summaryData}
              getRetrainTotal={getRetrainTotal}
              getLeftTotal={getLeftTotal}
              getSummaryStrengthTotal={getSummaryStrengthTotal}
              grandSummary={grandSummary}
              grandLeft={grandLeft}
              grandRetrain={grandRetrain}
              reportData={reportData}
              grandStrength={grandStrength}
              grandFemale={grandFemale}
              grandMale={grandMale}
              getStrengthSum={getStrengthSum}
              getfeMaleSum={getfeMaleSum}
              getMaleSum={getMaleSum}
            />
          </div>
        </div>
        <Divider />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div id="tableboot mt-5">
            {/* {summary ? (
              <Table responsive>
                <thead>
                  <tr id="tbl">
                    <th className="text-center">Class</th>
                    <th className="text-center">Section</th>
                    <th className="text-center">Program</th>
                    <th className="text-center">Retain</th>
                    <th className="text-center">Left</th>
                    <th className="text-center">Strength</th>
                  </tr>
                </thead>
                {summaryData.length > 0
                  ? summaryData.map((item) => ( */}
            <>
              {/* <div className="mx-4" style={{ marginTop: "40px" }}>
                    <h3 className="text-primary">{item.Campus}</h3>
                  </div> */}
              {/* <tr id="tbl">
                          <td
                            style={{
                              fontSize: "1.3rem",
                            }}
                            className="text_align_start text-primary"
                          >
                            {item.Campus}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                            }}
                            className=""
                          ></td>
                          <td
                            style={{
                              fontWeight: "bold",
                            }}
                            className="text-center text-primary"
                          > */}
              {/* {getMaleSum(item)} */}
              {/* </td>
                          <td
                            style={{
                              fontWeight: "bold",
                            }}
                            className="text-center text-primary"
                          > */}
              {/* {getfeMaleSum(item)} */}
              {/* </td>
                          <td
                            style={{
                              fontWeight: "bold",
                            }}
                            className="text-center text-primary"
                          > */}
              {/* {getStrengthSum(item)} */}
              {/* </td>
                        </tr>
                        <tbody>
                          {item?.classes?.map((subitem) => (
                            <>
                              {subitem?.sections?.map((session, indu) => (
                                <> */}
              {/* <div className="mt-5">
                          <h4 className="">
                            Class:-{subitem.Class?.name}(
                            {session.Section?.name})
                          </h4>
                        </div> */}
              {/* {session.programs.length > 0
                                    ? session.programs.map((last) => (
                                        <tr id="tbl">
                                          <td
                                            style={{
                                              color: "black",
                                            }}
                                            className="text-center"
                                          >
                                            {subitem?.Class?.name} */}
              {/* {session?.report?.male} */}
              {/* </td>
                                          <td
                                            style={{
                                              color: "black",
                                            }}
                                            className="text-center"
                                          >
                                            {session?.Section?.name} */}
              {/* {session?.report?.male} */}
              {/* </td>
                                          <td
                                            style={{
                                              color: "black",
                                            }}
                                            className="text-center"
                                          >
                                            {last?.program} */}
              {/* {session?.report?.male} */}
              {/* </td>
                                          <td
                                            style={{
                                              color: "black",
                                            }}
                                            className="text-center"
                                          >
                                            {last?.report?.retain}
                                          </td>
                                          <td
                                            style={{
                                              color: "black",
                                            }}
                                            className="text-center"
                                          >
                                            {last?.report?.left_students}
                                          </td>
                                          <td
                                            style={{
                                              color: "black",
                                            }}
                                            className="text-center"
                                          >
                                            {last?.report?.strength}
                                          </td>
                                        </tr>
                                      ))
                                    : ""}
                                </>
                              ))} */}
              {/* <tr id="tbl">
                              <td
                                style={{ color: "black",fontWeight:"bold" }}
                                className="text_align_start"
                              >
                              </td>
                              <td
                                style={{ color: "black",fontWeight:"bold" }}
                                className="text-end"
                              >
                                  Class Wise Totals:-
                              </td>
                              <td
                                style={{ color: "black",fontWeight:"bold"  }}
                                className="text-center"
                              >
                                  {subitem.sections.reduce(
                    (a, c) => a + parseInt(c.report.male),
                    0
                  )}
                              </td>
                              <td
                                style={{ color: "black",fontWeight:"bold"  }}
                                className="text-center"
                              >
                                                                      {subitem.sections.reduce(
                    (a, c) => a + parseInt(c.report.female),
                    0
                  )}
                              </td>
                              <td
                                style={{ color: "black",fontWeight:"bold"  }}
                                className="text-center"
                              >
                                                                      {subitem.sections.reduce(
                    (a, c) => a + parseInt(c.report.strength),
                    0
                  )}
                              </td>
                            </tr> */}
            </>
            {/* ))}
                          <tr id="tbl">
                            <td
                              style={{
                                fontSize: "1.3rem",
                              }}
                              className="text_align_start text-primary"
                            > */}
            {/* {item.Campus} */}
            {/* </td>
                            <td
                              style={{
                                fontSize: "1.3rem",
                              }}
                              className="text_align_start text-primary"
                            > */}
            {/* {item.Campus} */}
            {/* </td>
                            <td
                              style={{
                                fontWeight: "bold",
                              }}
                              className="text-end text-primary"
                            >
                              Campus Wise Total :
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                              }}
                              className="text-center text-primary"
                            >
                              {getRetrainTotal(item)}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                              }}
                              className="text-center text-primary"
                            >
                              {getLeftTotal(item)}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                              }}
                              className="text-center text-primary"
                            >
                              {getSummaryStrengthTotal(item)}
                            </td>
                          </tr>
                        </tbody>
                      </>
                    ))
                  : ""}
                <tr id="tbl">
                  <td
                    style={{ fontSize: "1.3rem" }}
                    className="text_align_start text-primary"
                  > */}
            {/* {item.Campus} */}
            {/* </td>
                  <td
                    style={{ fontSize: "1.3rem" }}
                    className="text_align_start text-primary"
                  > */}
            {/* {item.Campus} */}
            {/* </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.4rem",
                    }}
                    className="text-end text-primary"
                  >
                    Grand Total :
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.4rem",
                    }}
                    className="text-center text-primary"
                  >
                    {grandRetrain} */}
            {/* {getRetrainTotal(item)} */}
            {/* </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.4rem",
                    }}
                    className="text-center text-primary"
                  >
                    {grandLeft} */}
            {/* {getLeftTotal(item)} */}
            {/* </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.4rem",
                    }}
                    className="text-center text-primary"
                  >
                    {grandSummary} */}
            {/* {getSummaryStrengthTotal(item)} */}
            {/* </td>
                </tr>
              </Table>
            ) : ( */}
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th className="text-center">Class Name</th>
                  <th className="text-center">Section Name</th>
                  <th className="text-center">Male</th>
                  <th className="text-center">Female</th>
                  <th className="text-center">Strength</th>
                </tr>
              </thead>
              {reportData.length > 0 ? (
                <>
                  {reportData.map((item) => (
                    <>
                      {/* <div className="mx-4" style={{ marginTop: "40px" }}>
                    <h3 className="text-primary">{item.Campus}</h3>
                  </div> */}
                      {getStrengthSum(item) > 0 ? (
                        <tr id="tbl">
                          <td
                            style={{
                              fontSize: "1.3rem",
                            }}
                            className="text_align_start text-primary"
                          >
                            {item.Campus}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                            }}
                            className=""
                          ></td>
                          <td
                            style={{
                              fontWeight: "bold",
                            }}
                            className="text-center text-primary"
                          >
                            {getMaleSum(item)}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                            }}
                            className="text-center text-primary"
                          >
                            {getfeMaleSum(item)}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                            }}
                            className="text-center text-primary"
                          >
                            {getStrengthSum(item)}
                          </td>
                        </tr>
                      ) : null}
                      <tbody>
                        {item?.classes?.map((subitem) => (
                          <>
                            {subitem?.sections?.map((session, indu) => (
                              <>
                                {/* <div className="mt-5">
                          <h4 className="">
                            Class:-{subitem.Class?.name}(
                            {session.Section?.name})
                          </h4>
                        </div> */}
                                <tr id="tbl">
                                  <td
                                    style={{
                                      color: "black",
                                    }}
                                    className="text-center"
                                  >
                                    {indu == 0 ? subitem?.Class?.name : ""}
                                    {/* {session?.report?.male} */}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                    }}
                                    className="text-center"
                                  >
                                    {session?.Section?.name}
                                    {/* {session?.report?.male} */}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                    }}
                                    className="text-center"
                                  >
                                    {session?.report?.male}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                    }}
                                    className="text-center"
                                  >
                                    {session?.report?.female}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                    }}
                                    className="text-center"
                                  >
                                    {session?.report?.strength}
                                  </td>
                                </tr>
                              </>
                            ))}
                            {subitem?.sections.length > 0 ? (
                              <tr id="tbl">
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                  className="text_align_start"
                                ></td>
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                  className="text-end"
                                >
                                  Class Wise Totals:-
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                  className="text-center"
                                >
                                  {subitem.sections.reduce(
                                    (a, c) => a + parseInt(c.report.male),
                                    0
                                  )}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                  className="text-center"
                                >
                                  {subitem.sections.reduce(
                                    (a, c) => a + parseInt(c.report.female),
                                    0
                                  )}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                  className="text-center"
                                >
                                  {subitem.sections.reduce(
                                    (a, c) => a + parseInt(c.report.strength),
                                    0
                                  )}
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ))}
                      </tbody>
                    </>
                  ))}
                  <tr id="tbl">
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                      className="text_align_start"
                    ></td>
                    <td
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                      }}
                      className="text-end text-primary"
                    >
                      Grand Total:-
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                      }}
                      className="text-center text-primary"
                    >
                      {grandMale}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                      }}
                      className="text-center text-primary"
                    >
                      {grandFemale}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                      }}
                      className="text-center text-primary"
                    >
                      {grandStrength}
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
            </Table>
            {/* )} */}
          </div>
        )}
        {/* ///for print */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          <div className="p-3" style={{ display: "flex" }}>
            <img
              // className='col-md-2'
              style={{ height: 110, width: 110 }}
              src={icon}
            />
            <div className="col-md-8">
              <h1>{unitName}</h1>
              <h2>
                STUDENT STRENGTH LIST FOR THE ACADEMIC SESSION{" "}
                {fullSession.year}{" "}
              </h2>
              <h3>
                <b>{campusName}</b>
              </h3>
            </div>
          </div>
          <div>
            {/* print start here */}
            <Table
              responsive
              style={{ fontSize: "20px", whiteSpace: "normal", padding: "1px" }}
            >
              <thead>
                <tr
                  id="tbl"
                  style={{
                    whiteSpace: "normal",
                    padding: "1px",
                  }}
                >
                  <th
                    className="text-center"
                    style={{
                      fontSize: "20px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    Class Name
                  </th>
                  <th
                    className="text-start"
                    style={{
                      fontSize: "20px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    Section Name
                  </th>
                  <th
                    className="text-center"
                    style={{
                      fontSize: "20px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      fontSize: "20px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      fontSize: "20px",
                      whiteSpace: "normal",
                      padding: "1px",
                    }}
                  >
                    Strength
                  </th>
                </tr>
              </thead>
              {reportData.length > 0 ? (
                <>
                  {reportData.map((item) => (
                    <>
                      {/* <div className="mx-4" style={{ marginTop: "40px" }}>
                    <h3 className="text-primary">{item.Campus}</h3>
                  </div> */}
                      {getStrengthSum(item) > 0 ? (
                        <tr
                          id="tbl"
                          style={{
                            whiteSpace: "normal",
                            padding: "1px",
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <td
                            style={{
                              fontSize: "20",

                              whiteSpace: "normal",
                              padding: "1px",
                            }}
                            className="text_align_start text-primary"
                          >
                            <b> {item.Campus}</b>
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                            }}
                            className=""
                          ></td>
                          <td
                            style={{
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                            }}
                            className="text-center text-primary"
                          >
                            {getMaleSum(item)}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                            }}
                            className="text-center text-primary"
                          >
                            {getfeMaleSum(item)}
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              whiteSpace: "normal",
                              padding: "1px",
                            }}
                            className="text-center text-primary"
                          >
                            {getStrengthSum(item)}
                          </td>
                        </tr>
                      ) : null}
                      <tbody
                        style={{
                          whiteSpace: "normal",
                          padding: "1px",
                        }}
                      >
                        {item?.classes?.map((subitem) => (
                          <>
                            {subitem?.sections?.map((session, indu) => (
                              <>
                                {/* <div className="mt-5">
                          <h4 className="">
                            Class:-{subitem.Class?.name}(
                            {session.Section?.name})
                          </h4>
                        </div> */}
                                <tr
                                  id="tbl"
                                  style={{
                                    whiteSpace: "normal",
                                    padding: "1px",
                                  }}
                                >
                                  <td
                                    style={{
                                      color: "black",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                    }}
                                    className="text-center"
                                  >
                                    {indu == 0 ? subitem?.Class?.name : ""}
                                    {/* {session?.report?.male} */}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                    }}
                                    className="text-start"
                                  >
                                    {session?.Section?.name}
                                    {/* {session?.report?.male} */}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                    }}
                                    className="text-center"
                                  >
                                    {session?.report?.male}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                    }}
                                    className="text-center"
                                  >
                                    {session?.report?.female}
                                  </td>
                                  <td
                                    style={{
                                      color: "black",
                                      whiteSpace: "normal",
                                      padding: "1px",
                                    }}
                                    className="text-center"
                                  >
                                    {session?.report?.strength}
                                  </td>
                                </tr>
                              </>
                            ))}
                            {subitem?.sections.length > 0 ? (
                              <tr
                                id="tbl"
                                style={{
                                  whiteSpace: "normal",
                                  padding: "1px",
                                  borderTop: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                  }}
                                  className="text_align_start"
                                ></td>
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                  }}
                                  className="text-end"
                                >
                                  Class Wise Totals:-
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                  }}
                                  className="text-center"
                                >
                                  {subitem.sections.reduce(
                                    (a, c) => a + parseInt(c.report.male),
                                    0
                                  )}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                  }}
                                  className="text-center"
                                >
                                  {subitem.sections.reduce(
                                    (a, c) => a + parseInt(c.report.female),
                                    0
                                  )}
                                </td>
                                <td
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    whiteSpace: "normal",
                                    padding: "1px",
                                  }}
                                  className="text-center"
                                >
                                  {subitem.sections.reduce(
                                    (a, c) => a + parseInt(c.report.strength),
                                    0
                                  )}
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ))}
                      </tbody>
                    </>
                  ))}
                  <tr
                    id="tbl"
                    style={{
                      whiteSpace: "normal",
                      padding: "1px",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="text_align_start"
                    ></td>
                    <td
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="text-end text-primary"
                    >
                      Grand Total:-
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                        whiteSpace: "normal",
                        padding: "1px",
                        // fontSize: "2.0rem",
                      }}
                      className="text-center text-primary"
                    >
                      {grandMale}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                        whiteSpace: "normal",
                        padding: "1px",
                        // fontSize: "2.0rem",
                      }}
                      className="text-center text-primary"
                    >
                      {grandFemale}
                    </td>
                    <td
                      style={{
                        fontWeight: "bold",
                        fontSize: "25px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="text-center text-primary"
                    >
                      {grandStrength}
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
            </Table>
            {/* print end here */}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default StudentStrength;
