import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../services/config";
import { Paper } from "@material-ui/core";
import { Spinner, Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const Support = () => {
  const [supportData, setSupportData] = useState([]);
  const [loading, setLoading] = useState(false);
  let authtoken = localStorage.getItem("userToken");
  const history = useHistory();
  useEffect(() => {
    getAllSupports();
  }, []);
  const getAllSupports = async () => {
    setLoading(true);
    await fetch(`${baseUrl}/supports`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);
        setSupportData(data.payload);
      })
      .catch((err) => {
        setLoading(false);
        console.log("vehicle", err);
      });
  };

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <button
          onClick={() => history.push(`/campusSupport/create`)}
          className="btn btn-primary"
        >
          Add Support
        </button>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div id="tableboot">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>Campus</th>
                  <th>Email</th>
                  <th>Contact</th>
                  {/* <th>Campus</th> */}
                  <th>View PDF</th>
                  <th>Edit</th>
                </tr>
              </thead>

              <tbody>
                {!!supportData &&
                  supportData.map((item, index) => (
                    <tr id="tbl">
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item?.campus?.name}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item?.email}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item?.phone}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        <a
                          title="View PDF"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          href={item.file}
                          target="_blank"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Visible.svg"
                              )}
                            />
                          </span>
                        </a>
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        <a
                          title="Edit"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          onClick={() =>
                            history.push(`/campusSupport/${item.campus.id}`)
                          }
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      </Paper>
    </>
  );
};

export default Support;
